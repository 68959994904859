@charset "UTF-8";
/*!
 * proLibro (http://prolibro.com/)
 * Copyright CogniLore Inc. 2012-2018. All Rights Reserved.  
 * Any reuse or reproduction of the contents, in whole or in part, is expressly prohibited.
 */
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700");
/* Colors */
/* z-index */
/* This loading is for App setup. Trumps all other z-indexes */
/* Body */
/* Colors */
/* Context menu */
/* -------- Bootstrap overloads */
/* Default app branding colours and settings. Will be the same for all libraries unless overridden by a theme or outide the library context. */
/* Accents */
/* Icons */
/* Search meter */
/* Accents cont. */
/* Main navigation */
/* Loading overlay */
:root {
  --loadingModalBgColour: rgba(0, 0, 0, 0.7);
  --loadingModalColour: #fff;
  --loadingModalOpaqueBgColour: #e9ecef;
  --loadingModalOpaqueColour: #6c757d;
  --firstNavbarBg: #1651ab;
  --firstNavbarColour: #e6e6e6;
  --secondNavbarBg: #dee2e6;
  --secondNavbarColour: #e6e6e6;
  --navItemActiveColour: #1c67d8;
  --accentColour: #1c67d8;
  --secondaryAccent: #4183e6;
  --accentInvert: #fff;
  --loadingBarFillPrimaryColour: #2b7fc4;
  --loadingBarBackPrimaryColour: #fff;
  --meterFillPrimaryColour: red;
  --dropdownActiveTextColour: #fff;
  --infoHeaderColour: #1c67d8;
  --infoHeaderInvert: #fff;
  --tooltipPrimaryColour: #1c67d8;
  --tooltipPrimartInvert: #fff;
  --tableOfContentsHighlightColour: #1c67d8;
  --tableOfContentsSecondayHighlightColour: #4183e6;
  --tableOfContentsInvert: #fff;
  --loginLinksColour: #1c67d8;
  --accentTextColour: #1c67d8;
  --selectedTabTextColor: #1c67d8;
  --multiTitleSearchSelectedTitleColour: #103c7e;
}

/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #1c67d8;
  --bs-secondary: #e2e2e2;
  --bs-success: #3eaf64;
  --bs-info: #0dcaf0;
  --bs-warning: rgb(235, 173, 26);
  --bs-danger: #e23d4b;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 28, 103, 216;
  --bs-secondary-rgb: 226, 226, 226;
  --bs-success-rgb: 62, 175, 100;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 235, 173, 26;
  --bs-danger-rgb: 226, 61, 75;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 233, 236, 239;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #e9ecef;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #ced4da;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.2rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #1c67d8;
  --bs-link-hover-color: #1652ad;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0.9rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.45rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.285rem + 0.42vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.6rem;
  }
}

h2, .h2 {
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.4rem;
  }
}

h3, .h3 {
  font-size: calc(1.255rem + 0.06vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.3rem;
  }
}

h4, .h4 {
  font-size: 1.2rem;
}

h5, .h5 {
  font-size: 1.1rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0.9rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -0.9rem;
  margin-bottom: 0.9rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #e9ecef;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.2rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.45rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container, .notification-container .notification-list,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container, .notification-container .notification-list {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container, .notification-container .notification-list {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container, .notification-container .notification-list {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, .notification-container .notification-list {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container, .notification-container .notification-list {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.225rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.225rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.45rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.45rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.35rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.35rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2.7rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2.7rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.225rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.225rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.45rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.45rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.9rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.9rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.35rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.35rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2.7rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2.7rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.225rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.225rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.45rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.45rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.9rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.9rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.35rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.35rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2.7rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2.7rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.225rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.225rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.45rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.45rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.9rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.9rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.35rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.35rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2.7rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2.7rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.225rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.225rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.45rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.45rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.9rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.9rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.35rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.35rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2.7rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2.7rem;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.225rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.225rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.45rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.45rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.9rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.9rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.35rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.35rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2.7rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2.7rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 0.9rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d2e1f7;
  --bs-table-border-color: #bdcbde;
  --bs-table-striped-bg: #c8d6eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdcbde;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2d0e4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #f9f9f9;
  --bs-table-border-color: #e0e0e0;
  --bs-table-striped-bg: #ededed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0e0e0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6e6e6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d8efe0;
  --bs-table-border-color: #c2d7ca;
  --bs-table-striped-bg: #cde3d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d7ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8ddcf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fbefd1;
  --bs-table-border-color: #e2d7bc;
  --bs-table-striped-bg: #eee3c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d7bc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8ddc1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f9d8db;
  --bs-table-border-color: #e0c2c5;
  --bs-table-striped-bg: #edcdd0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0c2c5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6c8cb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: white;
  border-color: #8eb3ec;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.2rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.2rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8eb3ec;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8eb3ec;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.form-check-input:checked {
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #1c67d8;
  border-color: #1c67d8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238eb3ec'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1c67d8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bbd1f3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1c67d8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bbd1f3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #3eaf64;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.225rem 0.45rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(62, 175, 100, 0.9);
  border-radius: 0.2rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3eaf64;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233eaf64' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3eaf64;
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3eaf64;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233eaf64' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #3eaf64;
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3eaf64;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #3eaf64;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3eaf64;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e23d4b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.225rem 0.45rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(226, 61, 75, 0.9);
  border-radius: 0.2rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
form.form-condensed .mb-3 .form-control.is-invalid ~ .invalid-feedback,
form.form-condensed .mb-3 .form-control:invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
form.form-condensed .mb-3 .form-control.is-invalid ~ .invalid-tooltip,
form.form-condensed .mb-3 .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, form.form-condensed .mb-3 .form-control.is-invalid, form.form-condensed .mb-3 .form-control:invalid {
  border-color: #e23d4b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e23d4b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e23d4b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, form.form-condensed .mb-3 .form-control.is-invalid:focus, form.form-condensed .mb-3 .form-control:focus:invalid {
  border-color: #e23d4b;
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, form.form-condensed .mb-3 textarea.form-control.is-invalid, form.form-condensed .mb-3 textarea.form-control:invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid, form.form-condensed .mb-3 .form-select.form-control.is-invalid, form.form-condensed .mb-3 .form-select.form-control:invalid {
  border-color: #e23d4b;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), form.form-condensed .mb-3 .form-select.form-control.is-invalid:not([multiple]):not([size]), form.form-condensed .mb-3 .form-select.form-control:not([multiple]):not([size]):invalid, .form-select.is-invalid:not([multiple])[size="1"], form.form-condensed .mb-3 .form-select.form-control.is-invalid:not([multiple])[size="1"], form.form-condensed .mb-3 .form-select.form-control:not([multiple])[size="1"]:invalid {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e23d4b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e23d4b' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus, form.form-condensed .mb-3 .form-select.form-control.is-invalid:focus, form.form-condensed .mb-3 .form-select.form-control:focus:invalid {
  border-color: #e23d4b;
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid, form.form-condensed .mb-3 .form-control-color.form-control.is-invalid, form.form-condensed .mb-3 .form-control-color.form-control:invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid, form.form-condensed .mb-3 .form-check-input.form-control.is-invalid, form.form-condensed .mb-3 .form-check-input.form-control:invalid {
  border-color: #e23d4b;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked, form.form-condensed .mb-3 .form-check-input.form-control.is-invalid:checked, form.form-condensed .mb-3 .form-check-input.form-control:checked:invalid {
  background-color: #e23d4b;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus, form.form-condensed .mb-3 .form-check-input.form-control.is-invalid:focus, form.form-condensed .mb-3 .form-check-input.form-control:focus:invalid {
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, form.form-condensed .mb-3 .form-check-input.form-control.is-invalid ~ .form-check-label, form.form-condensed .mb-3 .form-check-input.form-control:invalid ~ .form-check-label {
  color: #e23d4b;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, form.form-condensed .mb-3 .input-group > .form-control.is-invalid:not(:focus), form.form-condensed .mb-3 .input-group > .form-control:not(:focus):invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
form.form-condensed .mb-3 .input-group > .form-floating.form-control.is-invalid:not(:focus-within),
form.form-condensed .mb-3 .input-group > .form-floating.form-control:not(:focus-within):invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.2rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1c67d8;
  --bs-btn-border-color: #1c67d8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1858b8;
  --bs-btn-hover-border-color: #1652ad;
  --bs-btn-focus-shadow-rgb: 62, 126, 222;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1652ad;
  --bs-btn-active-border-color: #154da2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1c67d8;
  --bs-btn-disabled-border-color: #1c67d8;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #e2e2e2;
  --bs-btn-border-color: #e2e2e2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #e5e5e5;
  --bs-btn-focus-shadow-rgb: 192, 192, 192;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e8e8e8;
  --bs-btn-active-border-color: #e5e5e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e2e2e2;
  --bs-btn-disabled-border-color: #e2e2e2;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #3eaf64;
  --bs-btn-border-color: #3eaf64;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #5bbb7b;
  --bs-btn-hover-border-color: #51b774;
  --bs-btn-focus-shadow-rgb: 53, 149, 85;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #65bf83;
  --bs-btn-active-border-color: #51b774;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3eaf64;
  --bs-btn-disabled-border-color: #3eaf64;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: rgb(235, 173, 26);
  --bs-btn-border-color: rgb(235, 173, 26);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eeb93c;
  --bs-btn-hover-border-color: #edb531;
  --bs-btn-focus-shadow-rgb: 200, 147, 22;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #efbd48;
  --bs-btn-active-border-color: #edb531;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: rgb(235, 173, 26);
  --bs-btn-disabled-border-color: rgb(235, 173, 26);
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #e23d4b;
  --bs-btn-border-color: #e23d4b;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e65a66;
  --bs-btn-hover-border-color: #e5505d;
  --bs-btn-focus-shadow-rgb: 192, 52, 64;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e8646f;
  --bs-btn-active-border-color: #e5505d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e23d4b;
  --bs-btn-disabled-border-color: #e23d4b;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #1c67d8;
  --bs-btn-border-color: #1c67d8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c67d8;
  --bs-btn-hover-border-color: #1c67d8;
  --bs-btn-focus-shadow-rgb: 28, 103, 216;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1c67d8;
  --bs-btn-active-border-color: #1c67d8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1c67d8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1c67d8;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #e2e2e2;
  --bs-btn-border-color: #e2e2e2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e2e2e2;
  --bs-btn-hover-border-color: #e2e2e2;
  --bs-btn-focus-shadow-rgb: 226, 226, 226;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e2e2e2;
  --bs-btn-active-border-color: #e2e2e2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e2e2e2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e2e2e2;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3eaf64;
  --bs-btn-border-color: #3eaf64;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3eaf64;
  --bs-btn-hover-border-color: #3eaf64;
  --bs-btn-focus-shadow-rgb: 62, 175, 100;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3eaf64;
  --bs-btn-active-border-color: #3eaf64;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3eaf64;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3eaf64;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: rgb(235, 173, 26);
  --bs-btn-border-color: rgb(235, 173, 26);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(235, 173, 26);
  --bs-btn-hover-border-color: rgb(235, 173, 26);
  --bs-btn-focus-shadow-rgb: 235, 173, 26;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(235, 173, 26);
  --bs-btn-active-border-color: rgb(235, 173, 26);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgb(235, 173, 26);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgb(235, 173, 26);
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #e23d4b;
  --bs-btn-border-color: #e23d4b;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e23d4b;
  --bs-btn-hover-border-color: #e23d4b;
  --bs-btn-focus-shadow-rgb: 226, 61, 75;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e23d4b;
  --bs-btn-active-border-color: #e23d4b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e23d4b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e23d4b;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 62, 126, 222;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1038;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.2rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.2rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.45rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1c67d8;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 0.9rem;
  --bs-dropdown-item-padding-y: 0.225rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 0.9rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1c67d8;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.2rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link, .nav-item .action-icon {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link, .nav-item .action-icon {
    transition: none;
  }
}
.nav-link:hover, .nav-item .action-icon:hover, .nav-link:focus, .nav-item .action-icon:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled, .nav-item .disabled.action-icon {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #ced4da;
  --bs-nav-tabs-border-radius: 0.2rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #ced4da;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #e9ecef;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #e9ecef;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link, .nav-tabs .nav-item .action-icon, .nav-item .nav-tabs .action-icon {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-item .action-icon:hover, .nav-item .nav-tabs .action-icon:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-item .action-icon:focus, .nav-item .nav-tabs .action-icon:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-item .disabled.action-icon, .nav-item .nav-tabs .disabled.action-icon, .nav-tabs .nav-link:disabled, .nav-tabs .nav-item .action-icon:disabled, .nav-item .nav-tabs .action-icon:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item .active.action-icon, .nav-item .nav-tabs .active.action-icon,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.show .action-icon {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.2rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #1c67d8;
}
.nav-pills .nav-link, .nav-pills .nav-item .action-icon, .nav-item .nav-pills .action-icon {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled, .nav-pills .nav-item .action-icon:disabled, .nav-item .nav-pills .action-icon:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active, .nav-pills .nav-item .active.action-icon, .nav-item .nav-pills .active.action-icon,
.nav-pills .show > .nav-link,
.nav-pills .nav-item .show > .action-icon,
.nav-item .nav-pills .show > .action-icon {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-item .nav-fill > .action-icon,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link, .nav-item .nav-justified > .action-icon,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link, .nav-fill .nav-item .action-icon,
.nav-justified .nav-item .nav-link,
.nav-justified .nav-item .action-icon {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.45rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.6);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.6%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.2rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar .container, .navbar .notification-container .notification-list, .notification-container .navbar .notification-list,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link, .navbar-nav .nav-item .show > .action-icon, .nav-item .navbar-nav .show > .action-icon,
.navbar-nav .nav-link.active,
.navbar-nav .nav-item .active.action-icon,
.nav-item .navbar-nav .active.action-icon {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-sm .navbar-nav .action-icon {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-md .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-md .navbar-nav .action-icon {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-lg .navbar-nav .action-icon {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link, .navbar-expand-xl .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-xl .navbar-nav .action-icon {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link, .navbar-expand-xxl .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-xxl .navbar-nav .action-icon {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link, .navbar-expand .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand .navbar-nav .action-icon {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 0.5rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.45rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #ced4da;
  --bs-card-border-radius: 0.2rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.2rem - 1px);
  --bs-card-cap-padding-y: 0.25rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 0.9rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active, .card-header-tabs .nav-item .active.action-icon, .nav-item .card-header-tabs .active.action-icon {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #e9ecef;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.2rem;
  --bs-accordion-inner-border-radius: calc(0.2rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23195dc2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8eb3ec;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #195dc2;
  --bs-accordion-active-bg: #e8f0fb;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.2rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #1c67d8;
  --bs-pagination-active-border-color: #1c67d8;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: normal;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.2rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0.9rem;
  --bs-alert-padding-y: 0.9rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0 rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 2.7rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.125rem 0.9rem;
}

.alert-primary {
  --bs-alert-color: #113e82;
  --bs-alert-bg: #d2e1f7;
  --bs-alert-border-color: #bbd1f3;
}
.alert-primary .alert-link {
  color: #0e3268;
}

.alert-secondary {
  --bs-alert-color: #5a5a5a;
  --bs-alert-bg: #f9f9f9;
  --bs-alert-border-color: #f6f6f6;
}
.alert-secondary .alert-link {
  color: #484848;
}

.alert-success {
  --bs-alert-color: #25693c;
  --bs-alert-bg: #d8efe0;
  --bs-alert-border-color: #c5e7d1;
}
.alert-success .alert-link {
  color: #1e5430;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #5e450a;
  --bs-alert-bg: #fbefd1;
  --bs-alert-border-color: #f9e6ba;
}
.alert-warning .alert-link {
  color: #4b3708;
}

.alert-danger {
  --bs-alert-color: #88252d;
  --bs-alert-bg: #f9d8db;
  --bs-alert-border-color: #f6c5c9;
}
.alert-danger .alert-link {
  color: #6d1e24;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.2rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #1c67d8;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.2rem;
  --bs-list-group-item-padding-x: 0.9rem;
  --bs-list-group-item-padding-y: 0.45rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #1c67d8;
  --bs-list-group-active-border-color: #1c67d8;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #113e82;
  background-color: #d2e1f7;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #113e82;
  background-color: #bdcbde;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #113e82;
  border-color: #113e82;
}

.list-group-item-secondary {
  color: #5a5a5a;
  background-color: #f9f9f9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #5a5a5a;
  background-color: #e0e0e0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5a5a5a;
  border-color: #5a5a5a;
}

.list-group-item-success {
  color: #25693c;
  background-color: #d8efe0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #25693c;
  background-color: #c2d7ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #25693c;
  border-color: #25693c;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #5e450a;
  background-color: #fbefd1;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #5e450a;
  background-color: #e2d7bc;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #5e450a;
  border-color: #5e450a;
}

.list-group-item-danger {
  color: #88252d;
  background-color: #f9d8db;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #88252d;
  background-color: #e0c2c5;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #88252d;
  border-color: #88252d;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.2rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.2rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1048;
  --bs-modal-width: 500px;
  --bs-modal-padding: 0.9rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 0.9rem;
  --bs-modal-header-padding-y: 0.9rem;
  --bs-modal-header-padding: 0.9rem 0.9rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1047;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1051;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.45rem;
  --bs-tooltip-padding-y: 0.225rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.2rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^=top] .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1042;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 0.9rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 0.9rem;
  --bs-popover-body-padding-y: 0.9rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^=top] > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^=top] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^=top] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^=top] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^=top] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^=bottom] > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^=bottom] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^=bottom] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^=bottom] > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^=bottom] > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 0.9rem;
  --bs-offcanvas-padding-y: 0.9rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1600px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.3;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(28, 103, 216, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(226, 226, 226, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(62, 175, 100, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(235, 173, 26, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(226, 61, 75, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #1c67d8 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #1652ad !important;
}

.link-secondary {
  color: #e2e2e2 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #e8e8e8 !important;
}

.link-success {
  color: #3eaf64 !important;
}
.link-success:hover, .link-success:focus {
  color: #65bf83 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: rgb(235, 173, 26) !important;
}
.link-warning:hover, .link-warning:focus {
  color: #efbd48 !important;
}

.link-danger {
  color: #e23d4b !important;
}
.link-danger:hover, .link-danger:focus {
  color: #e8646f !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1040;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1039;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1039;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1039;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1039;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1039;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1039;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1039;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1039;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1039;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1039;
  }
}
@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1039;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1039;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .book-tile-wrapper .book-tile .book-tile-actions, .book-tile-wrapper .book-tile .card-body, .loading-modal, .flip, .vertical-navbar, .wrap-grid, .vertical-split-group > div, .vertical-split-group {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow, .context-menu, .details-view .drawer-content {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm, .details-view .drawer-content .details-view-header .cover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border, .details-view .drawer-content {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom, .details-view .drawer-content .details-view-header {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0, .details-view .drawer-content {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary, .vertical-navbar.second-navbar {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill, .book-tile-wrapper .book-tile .book-tile-actions .book-tile-status, .book-tile-wrapper .book-tile .book-tile-info, .bookview-tabs, .bookview > .vertical-split-group, .vertical-split-group > div {
  flex: 1 1 auto !important;
}

.flex-row, .book-tile-wrapper .book-tile .book-tile-actions, .vertical-split-group > div {
  flex-direction: row !important;
}

.flex-column, .book-tile-wrapper .book-tile .card-body, .vertical-navbar {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0, .book-tile-wrapper .book-tile .book-tile-details, .book-tile-wrapper .book-tile .book-tile-actions .nav, .book-tile-wrapper .book-tile .book-tile-actions, .card-img-left .card-img {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap, .wrap-grid {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .loading-modal {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .book-tile-wrapper .book-tile .book-tile-actions, .loading-modal {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.225rem !important;
}

.m-2 {
  margin: 0.45rem !important;
}

.m-3 {
  margin: 0.9rem !important;
}

.m-4 {
  margin: 1.35rem !important;
}

.m-5 {
  margin: 2.7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.225rem !important;
  margin-left: 0.225rem !important;
}

.mx-2 {
  margin-right: 0.45rem !important;
  margin-left: 0.45rem !important;
}

.mx-3 {
  margin-right: 0.9rem !important;
  margin-left: 0.9rem !important;
}

.mx-4 {
  margin-right: 1.35rem !important;
  margin-left: 1.35rem !important;
}

.mx-5 {
  margin-right: 2.7rem !important;
  margin-left: 2.7rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.225rem !important;
  margin-bottom: 0.225rem !important;
}

.my-2 {
  margin-top: 0.45rem !important;
  margin-bottom: 0.45rem !important;
}

.my-3 {
  margin-top: 0.9rem !important;
  margin-bottom: 0.9rem !important;
}

.my-4 {
  margin-top: 1.35rem !important;
  margin-bottom: 1.35rem !important;
}

.my-5 {
  margin-top: 2.7rem !important;
  margin-bottom: 2.7rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.225rem !important;
}

.mt-2 {
  margin-top: 0.45rem !important;
}

.mt-3 {
  margin-top: 0.9rem !important;
}

.mt-4 {
  margin-top: 1.35rem !important;
}

.mt-5 {
  margin-top: 2.7rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.225rem !important;
}

.me-2 {
  margin-right: 0.45rem !important;
}

.me-3 {
  margin-right: 0.9rem !important;
}

.me-4 {
  margin-right: 1.35rem !important;
}

.me-5 {
  margin-right: 2.7rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.225rem !important;
}

.mb-2 {
  margin-bottom: 0.45rem !important;
}

.mb-3 {
  margin-bottom: 0.9rem !important;
}

.mb-4 {
  margin-bottom: 1.35rem !important;
}

.mb-5 {
  margin-bottom: 2.7rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.225rem !important;
}

.ms-2 {
  margin-left: 0.45rem !important;
}

.ms-3 {
  margin-left: 0.9rem !important;
}

.ms-4 {
  margin-left: 1.35rem !important;
}

.ms-5 {
  margin-left: 2.7rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0, .book-tile-wrapper .book-tile .card-body {
  padding: 0 !important;
}

.p-1, .book-tile-wrapper .book-tile .book-tile-actions .book-tile-status {
  padding: 0.225rem !important;
}

.p-2 {
  padding: 0.45rem !important;
}

.p-3, .details-view .drawer-content .details-view-body, .details-view .drawer-content .details-view-header, .book-tile-wrapper .book-tile .book-tile-info, .splash-page-context .splash-page-status {
  padding: 0.9rem !important;
}

.p-4 {
  padding: 1.35rem !important;
}

.p-5 {
  padding: 2.7rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.225rem !important;
  padding-left: 0.225rem !important;
}

.px-2 {
  padding-right: 0.45rem !important;
  padding-left: 0.45rem !important;
}

.px-3 {
  padding-right: 0.9rem !important;
  padding-left: 0.9rem !important;
}

.px-4 {
  padding-right: 1.35rem !important;
  padding-left: 1.35rem !important;
}

.px-5 {
  padding-right: 2.7rem !important;
  padding-left: 2.7rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.225rem !important;
  padding-bottom: 0.225rem !important;
}

.py-2 {
  padding-top: 0.45rem !important;
  padding-bottom: 0.45rem !important;
}

.py-3 {
  padding-top: 0.9rem !important;
  padding-bottom: 0.9rem !important;
}

.py-4 {
  padding-top: 1.35rem !important;
  padding-bottom: 1.35rem !important;
}

.py-5 {
  padding-top: 2.7rem !important;
  padding-bottom: 2.7rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.225rem !important;
}

.pt-2, .context-menu .context-menu-item {
  padding-top: 0.45rem !important;
}

.pt-3 {
  padding-top: 0.9rem !important;
}

.pt-4 {
  padding-top: 1.35rem !important;
}

.pt-5 {
  padding-top: 2.7rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.225rem !important;
}

.pe-2 {
  padding-right: 0.45rem !important;
}

.pe-3 {
  padding-right: 0.9rem !important;
}

.pe-4 {
  padding-right: 1.35rem !important;
}

.pe-5 {
  padding-right: 2.7rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.225rem !important;
}

.pb-2, .context-menu .context-menu-item {
  padding-bottom: 0.45rem !important;
}

.pb-3 {
  padding-bottom: 0.9rem !important;
}

.pb-4 {
  padding-bottom: 1.35rem !important;
}

.pb-5 {
  padding-bottom: 2.7rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.225rem !important;
}

.ps-2 {
  padding-left: 0.45rem !important;
}

.ps-3 {
  padding-left: 0.9rem !important;
}

.ps-4 {
  padding-left: 1.35rem !important;
}

.ps-5 {
  padding-left: 2.7rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.225rem !important;
}

.gap-2 {
  gap: 0.45rem !important;
}

.gap-3 {
  gap: 0.9rem !important;
}

.gap-4 {
  gap: 1.35rem !important;
}

.gap-5 {
  gap: 2.7rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.285rem + 0.42vw) !important;
}

.fs-2 {
  font-size: calc(1.265rem + 0.18vw) !important;
}

.fs-3 {
  font-size: calc(1.255rem + 0.06vw) !important;
}

.fs-4 {
  font-size: 1.2rem !important;
}

.fs-5 {
  font-size: 1.1rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light, .gutter.gutter-horizontal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.225rem !important;
  }
  .m-sm-2 {
    margin: 0.45rem !important;
  }
  .m-sm-3 {
    margin: 0.9rem !important;
  }
  .m-sm-4 {
    margin: 1.35rem !important;
  }
  .m-sm-5 {
    margin: 2.7rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.225rem !important;
    margin-left: 0.225rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.45rem !important;
    margin-left: 0.45rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9rem !important;
    margin-left: 0.9rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.35rem !important;
    margin-left: 1.35rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.225rem !important;
    margin-bottom: 0.225rem !important;
  }
  .my-sm-2 {
    margin-top: 0.45rem !important;
    margin-bottom: 0.45rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9rem !important;
    margin-bottom: 0.9rem !important;
  }
  .my-sm-4 {
    margin-top: 1.35rem !important;
    margin-bottom: 1.35rem !important;
  }
  .my-sm-5 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.225rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.45rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.35rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.7rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.225rem !important;
  }
  .me-sm-2 {
    margin-right: 0.45rem !important;
  }
  .me-sm-3 {
    margin-right: 0.9rem !important;
  }
  .me-sm-4 {
    margin-right: 1.35rem !important;
  }
  .me-sm-5 {
    margin-right: 2.7rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.225rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.45rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.35rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.7rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.225rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.45rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.9rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.35rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.7rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.225rem !important;
  }
  .p-sm-2 {
    padding: 0.45rem !important;
  }
  .p-sm-3 {
    padding: 0.9rem !important;
  }
  .p-sm-4 {
    padding: 1.35rem !important;
  }
  .p-sm-5 {
    padding: 2.7rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.225rem !important;
    padding-left: 0.225rem !important;
  }
  .px-sm-2 {
    padding-right: 0.45rem !important;
    padding-left: 0.45rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9rem !important;
    padding-left: 0.9rem !important;
  }
  .px-sm-4 {
    padding-right: 1.35rem !important;
    padding-left: 1.35rem !important;
  }
  .px-sm-5 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.225rem !important;
    padding-bottom: 0.225rem !important;
  }
  .py-sm-2 {
    padding-top: 0.45rem !important;
    padding-bottom: 0.45rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9rem !important;
    padding-bottom: 0.9rem !important;
  }
  .py-sm-4 {
    padding-top: 1.35rem !important;
    padding-bottom: 1.35rem !important;
  }
  .py-sm-5 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.225rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.45rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.35rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.7rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.225rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.45rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.9rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.35rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.7rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.225rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.45rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.35rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.7rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.225rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.45rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.9rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.35rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.7rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.225rem !important;
  }
  .gap-sm-2 {
    gap: 0.45rem !important;
  }
  .gap-sm-3 {
    gap: 0.9rem !important;
  }
  .gap-sm-4 {
    gap: 1.35rem !important;
  }
  .gap-sm-5 {
    gap: 2.7rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.225rem !important;
  }
  .m-md-2 {
    margin: 0.45rem !important;
  }
  .m-md-3 {
    margin: 0.9rem !important;
  }
  .m-md-4 {
    margin: 1.35rem !important;
  }
  .m-md-5 {
    margin: 2.7rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.225rem !important;
    margin-left: 0.225rem !important;
  }
  .mx-md-2 {
    margin-right: 0.45rem !important;
    margin-left: 0.45rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9rem !important;
    margin-left: 0.9rem !important;
  }
  .mx-md-4 {
    margin-right: 1.35rem !important;
    margin-left: 1.35rem !important;
  }
  .mx-md-5 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.225rem !important;
    margin-bottom: 0.225rem !important;
  }
  .my-md-2 {
    margin-top: 0.45rem !important;
    margin-bottom: 0.45rem !important;
  }
  .my-md-3 {
    margin-top: 0.9rem !important;
    margin-bottom: 0.9rem !important;
  }
  .my-md-4 {
    margin-top: 1.35rem !important;
    margin-bottom: 1.35rem !important;
  }
  .my-md-5 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.225rem !important;
  }
  .mt-md-2 {
    margin-top: 0.45rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9rem !important;
  }
  .mt-md-4 {
    margin-top: 1.35rem !important;
  }
  .mt-md-5 {
    margin-top: 2.7rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.225rem !important;
  }
  .me-md-2 {
    margin-right: 0.45rem !important;
  }
  .me-md-3 {
    margin-right: 0.9rem !important;
  }
  .me-md-4 {
    margin-right: 1.35rem !important;
  }
  .me-md-5 {
    margin-right: 2.7rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.225rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.45rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.35rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.7rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.225rem !important;
  }
  .ms-md-2 {
    margin-left: 0.45rem !important;
  }
  .ms-md-3 {
    margin-left: 0.9rem !important;
  }
  .ms-md-4 {
    margin-left: 1.35rem !important;
  }
  .ms-md-5 {
    margin-left: 2.7rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.225rem !important;
  }
  .p-md-2 {
    padding: 0.45rem !important;
  }
  .p-md-3 {
    padding: 0.9rem !important;
  }
  .p-md-4 {
    padding: 1.35rem !important;
  }
  .p-md-5 {
    padding: 2.7rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.225rem !important;
    padding-left: 0.225rem !important;
  }
  .px-md-2 {
    padding-right: 0.45rem !important;
    padding-left: 0.45rem !important;
  }
  .px-md-3 {
    padding-right: 0.9rem !important;
    padding-left: 0.9rem !important;
  }
  .px-md-4 {
    padding-right: 1.35rem !important;
    padding-left: 1.35rem !important;
  }
  .px-md-5 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.225rem !important;
    padding-bottom: 0.225rem !important;
  }
  .py-md-2 {
    padding-top: 0.45rem !important;
    padding-bottom: 0.45rem !important;
  }
  .py-md-3 {
    padding-top: 0.9rem !important;
    padding-bottom: 0.9rem !important;
  }
  .py-md-4 {
    padding-top: 1.35rem !important;
    padding-bottom: 1.35rem !important;
  }
  .py-md-5 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.225rem !important;
  }
  .pt-md-2 {
    padding-top: 0.45rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9rem !important;
  }
  .pt-md-4 {
    padding-top: 1.35rem !important;
  }
  .pt-md-5 {
    padding-top: 2.7rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.225rem !important;
  }
  .pe-md-2 {
    padding-right: 0.45rem !important;
  }
  .pe-md-3 {
    padding-right: 0.9rem !important;
  }
  .pe-md-4 {
    padding-right: 1.35rem !important;
  }
  .pe-md-5 {
    padding-right: 2.7rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.225rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.45rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.35rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.7rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.225rem !important;
  }
  .ps-md-2 {
    padding-left: 0.45rem !important;
  }
  .ps-md-3 {
    padding-left: 0.9rem !important;
  }
  .ps-md-4 {
    padding-left: 1.35rem !important;
  }
  .ps-md-5 {
    padding-left: 2.7rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.225rem !important;
  }
  .gap-md-2 {
    gap: 0.45rem !important;
  }
  .gap-md-3 {
    gap: 0.9rem !important;
  }
  .gap-md-4 {
    gap: 1.35rem !important;
  }
  .gap-md-5 {
    gap: 2.7rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.225rem !important;
  }
  .m-lg-2 {
    margin: 0.45rem !important;
  }
  .m-lg-3 {
    margin: 0.9rem !important;
  }
  .m-lg-4 {
    margin: 1.35rem !important;
  }
  .m-lg-5 {
    margin: 2.7rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.225rem !important;
    margin-left: 0.225rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.45rem !important;
    margin-left: 0.45rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9rem !important;
    margin-left: 0.9rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.35rem !important;
    margin-left: 1.35rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.225rem !important;
    margin-bottom: 0.225rem !important;
  }
  .my-lg-2 {
    margin-top: 0.45rem !important;
    margin-bottom: 0.45rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9rem !important;
    margin-bottom: 0.9rem !important;
  }
  .my-lg-4 {
    margin-top: 1.35rem !important;
    margin-bottom: 1.35rem !important;
  }
  .my-lg-5 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.225rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.45rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.35rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.7rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.225rem !important;
  }
  .me-lg-2 {
    margin-right: 0.45rem !important;
  }
  .me-lg-3 {
    margin-right: 0.9rem !important;
  }
  .me-lg-4 {
    margin-right: 1.35rem !important;
  }
  .me-lg-5 {
    margin-right: 2.7rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.225rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.45rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.35rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.7rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.225rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.45rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.9rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.35rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.7rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.225rem !important;
  }
  .p-lg-2 {
    padding: 0.45rem !important;
  }
  .p-lg-3 {
    padding: 0.9rem !important;
  }
  .p-lg-4 {
    padding: 1.35rem !important;
  }
  .p-lg-5 {
    padding: 2.7rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.225rem !important;
    padding-left: 0.225rem !important;
  }
  .px-lg-2 {
    padding-right: 0.45rem !important;
    padding-left: 0.45rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9rem !important;
    padding-left: 0.9rem !important;
  }
  .px-lg-4 {
    padding-right: 1.35rem !important;
    padding-left: 1.35rem !important;
  }
  .px-lg-5 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.225rem !important;
    padding-bottom: 0.225rem !important;
  }
  .py-lg-2 {
    padding-top: 0.45rem !important;
    padding-bottom: 0.45rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9rem !important;
    padding-bottom: 0.9rem !important;
  }
  .py-lg-4 {
    padding-top: 1.35rem !important;
    padding-bottom: 1.35rem !important;
  }
  .py-lg-5 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.225rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.45rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.35rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.7rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.225rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.45rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.9rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.35rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.7rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.225rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.45rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.35rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.7rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.225rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.45rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.9rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.35rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.7rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.225rem !important;
  }
  .gap-lg-2 {
    gap: 0.45rem !important;
  }
  .gap-lg-3 {
    gap: 0.9rem !important;
  }
  .gap-lg-4 {
    gap: 1.35rem !important;
  }
  .gap-lg-5 {
    gap: 2.7rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.225rem !important;
  }
  .m-xl-2 {
    margin: 0.45rem !important;
  }
  .m-xl-3 {
    margin: 0.9rem !important;
  }
  .m-xl-4 {
    margin: 1.35rem !important;
  }
  .m-xl-5 {
    margin: 2.7rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.225rem !important;
    margin-left: 0.225rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.45rem !important;
    margin-left: 0.45rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9rem !important;
    margin-left: 0.9rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.35rem !important;
    margin-left: 1.35rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.225rem !important;
    margin-bottom: 0.225rem !important;
  }
  .my-xl-2 {
    margin-top: 0.45rem !important;
    margin-bottom: 0.45rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9rem !important;
    margin-bottom: 0.9rem !important;
  }
  .my-xl-4 {
    margin-top: 1.35rem !important;
    margin-bottom: 1.35rem !important;
  }
  .my-xl-5 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.225rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.45rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.35rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.7rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.225rem !important;
  }
  .me-xl-2 {
    margin-right: 0.45rem !important;
  }
  .me-xl-3 {
    margin-right: 0.9rem !important;
  }
  .me-xl-4 {
    margin-right: 1.35rem !important;
  }
  .me-xl-5 {
    margin-right: 2.7rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.225rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.45rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.35rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.7rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.225rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.45rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.9rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.35rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.7rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.225rem !important;
  }
  .p-xl-2 {
    padding: 0.45rem !important;
  }
  .p-xl-3 {
    padding: 0.9rem !important;
  }
  .p-xl-4 {
    padding: 1.35rem !important;
  }
  .p-xl-5 {
    padding: 2.7rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.225rem !important;
    padding-left: 0.225rem !important;
  }
  .px-xl-2 {
    padding-right: 0.45rem !important;
    padding-left: 0.45rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9rem !important;
    padding-left: 0.9rem !important;
  }
  .px-xl-4 {
    padding-right: 1.35rem !important;
    padding-left: 1.35rem !important;
  }
  .px-xl-5 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.225rem !important;
    padding-bottom: 0.225rem !important;
  }
  .py-xl-2 {
    padding-top: 0.45rem !important;
    padding-bottom: 0.45rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9rem !important;
    padding-bottom: 0.9rem !important;
  }
  .py-xl-4 {
    padding-top: 1.35rem !important;
    padding-bottom: 1.35rem !important;
  }
  .py-xl-5 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.225rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.45rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.35rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.7rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.225rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.45rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.9rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.35rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.7rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.225rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.45rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.35rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.7rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.225rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.45rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.9rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.35rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.7rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.225rem !important;
  }
  .gap-xl-2 {
    gap: 0.45rem !important;
  }
  .gap-xl-3 {
    gap: 0.9rem !important;
  }
  .gap-xl-4 {
    gap: 1.35rem !important;
  }
  .gap-xl-5 {
    gap: 2.7rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.225rem !important;
  }
  .m-xxl-2 {
    margin: 0.45rem !important;
  }
  .m-xxl-3 {
    margin: 0.9rem !important;
  }
  .m-xxl-4 {
    margin: 1.35rem !important;
  }
  .m-xxl-5 {
    margin: 2.7rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.225rem !important;
    margin-left: 0.225rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.45rem !important;
    margin-left: 0.45rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.9rem !important;
    margin-left: 0.9rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.35rem !important;
    margin-left: 1.35rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.225rem !important;
    margin-bottom: 0.225rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.45rem !important;
    margin-bottom: 0.45rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.9rem !important;
    margin-bottom: 0.9rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.35rem !important;
    margin-bottom: 1.35rem !important;
  }
  .my-xxl-5 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.225rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.45rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.9rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.35rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2.7rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.225rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.45rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.9rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.35rem !important;
  }
  .me-xxl-5 {
    margin-right: 2.7rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.225rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.45rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.35rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2.7rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.225rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.45rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.9rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.35rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2.7rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.225rem !important;
  }
  .p-xxl-2 {
    padding: 0.45rem !important;
  }
  .p-xxl-3 {
    padding: 0.9rem !important;
  }
  .p-xxl-4 {
    padding: 1.35rem !important;
  }
  .p-xxl-5 {
    padding: 2.7rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.225rem !important;
    padding-left: 0.225rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.45rem !important;
    padding-left: 0.45rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.9rem !important;
    padding-left: 0.9rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.35rem !important;
    padding-left: 1.35rem !important;
  }
  .px-xxl-5 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.225rem !important;
    padding-bottom: 0.225rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.45rem !important;
    padding-bottom: 0.45rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.9rem !important;
    padding-bottom: 0.9rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.35rem !important;
    padding-bottom: 1.35rem !important;
  }
  .py-xxl-5 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.225rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.45rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.9rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.35rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2.7rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.225rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.45rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.9rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.35rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2.7rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.225rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.45rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.35rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2.7rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.225rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.45rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.9rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.35rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2.7rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.225rem !important;
  }
  .gap-xxl-2 {
    gap: 0.45rem !important;
  }
  .gap-xxl-3 {
    gap: 0.9rem !important;
  }
  .gap-xxl-4 {
    gap: 1.35rem !important;
  }
  .gap-xxl-5 {
    gap: 2.7rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.6rem !important;
  }
  .fs-2 {
    font-size: 1.4rem !important;
  }
  .fs-3 {
    font-size: 1.3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #198754;
  --teal: #20c997;
  --cyan: #0dcaf0;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --black: #000;
  --primary: #1c67d8;
  --secondary: #e2e2e2;
  --success: #3eaf64;
  --info: #0dcaf0;
  --warning: rgb(235, 173, 26);
  --danger: #e23d4b;
  --light: #f8f9fa;
  --dark: #212529;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #e9ecef;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.45rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1c67d8;
  text-decoration: underline;
  background-color: transparent;
}
a:hover {
  color: #1652ad;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
.h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: 0.45rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 1.6rem;
}

h2, .h2 {
  font-size: 1.4rem;
}

h3, .h3 {
  font-size: 1.3rem;
}

h4, .h4 {
  font-size: 1.2rem;
}

h5, .h5 {
  font-size: 1.1rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: #fff3cd;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0.9rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #e9ecef;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.2rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.45rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 0.875em;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .notification-container .notification-list,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .notification-container .notification-list, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .notification-container .notification-list, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .notification-container .notification-list, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .notification-container .notification-list, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container, .notification-container .notification-list, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 0.9rem;
  color: var(--bs-body-color);
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid var(--bs-border-color);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--bs-border-color);
}
.table tbody + tbody {
  border-top: 2px solid var(--bs-border-color);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid var(--bs-border-color);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid var(--bs-border-color);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-hover tbody tr:hover {
  color: var(--bs-body-color);
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bfd4f4;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #89b0eb;
}

.table-hover .table-primary:hover {
  background-color: #a9c5f0;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a9c5f0;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f7f7f7;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f0f0f0;
}

.table-hover .table-secondary:hover {
  background-color: #eaeaea;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #eaeaea;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c9e9d4;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9bd5ae;
}

.table-hover .table-success:hover {
  background-color: #b7e2c6;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b7e2c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bbf0fb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #81e3f7;
}

.table-hover .table-info:hover {
  background-color: #a3ebfa;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a3ebfa;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9e8bf;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5d488;
}

.table-hover .table-warning:hover {
  background-color: #f7e0a8;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f7e0a8;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c9cd;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f09aa1;
}

.table-hover .table-danger:hover {
  background-color: #f4b3b8;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b3b8;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c2c3;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8e90;
}

.table-hover .table-dark:hover {
  background-color: #b4b5b6;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b5b6;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.1);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: var(--bs-border-color);
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #212529;
  background-color: white;
  border-color: #8eb3ec;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}
select.form-control:focus::-ms-value {
  color: #212529;
  background-color: white;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 2;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.25;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #3eaf64;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.225rem 0.45rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(62, 175, 100, 0.9);
  border-radius: 0.2rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3eaf64;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233eaf64' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3eaf64;
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #3eaf64;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233eaf64' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #3eaf64;
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3eaf64;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3eaf64;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #3eaf64;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5bc57f;
  background-color: #5bc57f;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3eaf64;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3eaf64;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #3eaf64;
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e23d4b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.225rem 0.45rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(226, 61, 75, 0.9);
  border-radius: 0.2rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
form.form-condensed .mb-3 .form-control.is-invalid ~ .invalid-feedback,
form.form-condensed .mb-3 .form-control:invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
form.form-condensed .mb-3 .form-control.is-invalid ~ .invalid-tooltip,
form.form-condensed .mb-3 .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, form.form-condensed .mb-3 .form-control.is-invalid, form.form-condensed .mb-3 .form-control:invalid {
  border-color: #e23d4b;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e23d4b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e23d4b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, form.form-condensed .mb-3 .form-control.is-invalid:focus, form.form-condensed .mb-3 .form-control:focus:invalid {
  border-color: #e23d4b;
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid, form.form-condensed .mb-3 select.form-control.is-invalid, form.form-condensed .mb-3 select.form-control:invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, form.form-condensed .mb-3 textarea.form-control.is-invalid, form.form-condensed .mb-3 textarea.form-control:invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid, form.form-condensed .mb-3 .custom-select.form-control.is-invalid, form.form-condensed .mb-3 .custom-select.form-control:invalid {
  border-color: #e23d4b;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e23d4b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e23d4b' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, form.form-condensed .mb-3 .custom-select.form-control.is-invalid:focus, form.form-condensed .mb-3 .custom-select.form-control:focus:invalid {
  border-color: #e23d4b;
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, form.form-condensed .mb-3 .form-check-input.form-control.is-invalid ~ .form-check-label, form.form-condensed .mb-3 .form-check-input.form-control:invalid ~ .form-check-label {
  color: #e23d4b;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, form.form-condensed .mb-3 .form-check-input.form-control.is-invalid ~ .invalid-feedback, form.form-condensed .mb-3 .form-check-input.form-control:invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
form.form-condensed .mb-3 .form-check-input.form-control.is-invalid ~ .invalid-tooltip,
form.form-condensed .mb-3 .form-check-input.form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, form.form-condensed .mb-3 .custom-control-input.form-control.is-invalid ~ .custom-control-label, form.form-condensed .mb-3 .custom-control-input.form-control:invalid ~ .custom-control-label {
  color: #e23d4b;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, form.form-condensed .mb-3 .custom-control-input.form-control.is-invalid ~ .custom-control-label::before, form.form-condensed .mb-3 .custom-control-input.form-control:invalid ~ .custom-control-label::before {
  border-color: #e23d4b;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, form.form-condensed .mb-3 .custom-control-input.form-control:checked:invalid ~ .custom-control-label::before {
  border-color: #e96974;
  background-color: #e96974;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, form.form-condensed .mb-3 .custom-control-input.form-control:focus:invalid ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, form.form-condensed .mb-3 .custom-control-input.form-control:focus:not(:checked):invalid ~ .custom-control-label::before {
  border-color: #e23d4b;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, form.form-condensed .mb-3 .custom-file-input.form-control.is-invalid ~ .custom-file-label, form.form-condensed .mb-3 .custom-file-input.form-control:invalid ~ .custom-file-label {
  border-color: #e23d4b;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, form.form-condensed .mb-3 .custom-file-input.form-control.is-invalid:focus ~ .custom-file-label, form.form-condensed .mb-3 .custom-file-input.form-control:focus:invalid ~ .custom-file-label {
  border-color: #e23d4b;
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1857b6;
  border-color: #1651ab;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1857b6;
  border-color: #1651ab;
  box-shadow: 0 0 0 0.25rem rgba(62, 126, 222, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1651ab;
  border-color: #154ca0;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 126, 222, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #cfcfcf;
  border-color: #c9c9c9;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #cfcfcf;
  border-color: #c9c9c9;
  box-shadow: 0 0 0 0.25rem rgba(197, 198, 198, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #c9c9c9;
  border-color: #c2c2c2;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 198, 198, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #3eaf64;
  border-color: #3eaf64;
}
.btn-success:hover {
  color: #fff;
  background-color: #349354;
  border-color: #31894e;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #349354;
  border-color: #31894e;
  box-shadow: 0 0 0 0.25rem rgba(91, 187, 123, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #3eaf64;
  border-color: #3eaf64;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #31894e;
  border-color: #2d8049;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(91, 187, 123, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #fff;
  background-color: #0babcc;
  border-color: #0aa1c0;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #0babcc;
  border-color: #0aa1c0;
  box-shadow: 0 0 0 0.25rem rgba(49, 210, 242, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0aa1c0;
  border-color: #0a97b4;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 210, 242, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: rgb(235, 173, 26);
  border-color: rgb(235, 173, 26);
}
.btn-warning:hover {
  color: #212529;
  background-color: #cd9512;
  border-color: #c18d11;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #cd9512;
  border-color: #c18d11;
  box-shadow: 0 0 0 0.25rem rgba(205, 153, 28, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: rgb(235, 173, 26);
  border-color: rgb(235, 173, 26);
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c18d11;
  border-color: #b58410;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 153, 28, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e23d4b;
  border-color: #e23d4b;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d82030;
  border-color: #cd1f2e;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d82030;
  border-color: #cd1f2e;
  box-shadow: 0 0 0 0.25rem rgba(230, 90, 102, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e23d4b;
  border-color: #e23d4b;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #cd1f2e;
  border-color: #c21d2b;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 90, 102, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.25rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0a0c0d;
  border-color: #050506;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-outline-primary {
  color: #1c67d8;
  border-color: #1c67d8;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1c67d8;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.5);
}

.btn-outline-secondary {
  color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 226, 226, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #e2e2e2;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 226, 226, 0.5);
}

.btn-outline-success {
  color: #3eaf64;
  border-color: #3eaf64;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #3eaf64;
  border-color: #3eaf64;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #3eaf64;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #3eaf64;
  border-color: #3eaf64;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.5);
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-warning {
  color: rgb(235, 173, 26);
  border-color: rgb(235, 173, 26);
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: rgb(235, 173, 26);
  border-color: rgb(235, 173, 26);
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.25rem rgba(235, 173, 26, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: rgb(235, 173, 26);
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: rgb(235, 173, 26);
  border-color: rgb(235, 173, 26);
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(235, 173, 26, 0.5);
}

.btn-outline-danger {
  color: #e23d4b;
  border-color: #e23d4b;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e23d4b;
  border-color: #e23d4b;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e23d4b;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e23d4b;
  border-color: #e23d4b;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1c67d8;
  text-decoration: underline;
}
.btn-link:hover {
  color: #1652ad;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1038;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 0.2rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.45rem 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-border-color-translucent);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.225rem 0.9rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1c67d8;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 0.9rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.225rem 0.9rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  border-radius: 0.25rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1c67d8;
  background-color: #1c67d8;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8eb3ec;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b2ccf5;
  border-color: #b2ccf5;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #e2e2e2;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1c67d8;
  background-color: #1c67d8;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(28, 103, 216, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(28, 103, 216, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(28, 103, 216, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #e2e2e2;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(28, 103, 216, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  vertical-align: middle;
  background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #8eb3ec;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.custom-select:focus::-ms-value {
  color: #212529;
  background-color: white;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #8eb3ec;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #212529;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.2rem 0.2rem 0;
}

.custom-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1c67d8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b2ccf5;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1c67d8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b2ccf5;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: #1c67d8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b2ccf5;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link, .nav-item .action-icon {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.nav-link:hover, .nav-item .action-icon:hover, .nav-link:focus, .nav-item .action-icon:focus {
  text-decoration: none;
}
.nav-link.disabled, .nav-item .disabled.action-icon {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #ced4da;
}
.nav-tabs .nav-link, .nav-tabs .nav-item .action-icon, .nav-item .nav-tabs .action-icon {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-item .action-icon:hover, .nav-item .nav-tabs .action-icon:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-item .action-icon:focus, .nav-item .nav-tabs .action-icon:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #ced4da;
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-item .disabled.action-icon, .nav-item .nav-tabs .disabled.action-icon {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item .active.action-icon, .nav-item .nav-tabs .active.action-icon,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.show .action-icon {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6 #dee2e6 #e9ecef;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link, .nav-pills .nav-item .action-icon, .nav-item .nav-pills .action-icon {
  background: none;
  border: 0;
  border-radius: 0.2rem;
}
.nav-pills .nav-link.active, .nav-pills .nav-item .active.action-icon, .nav-item .nav-pills .active.action-icon,
.nav-pills .show > .nav-link,
.nav-pills .nav-item .show > .action-icon,
.nav-item .nav-pills .show > .action-icon {
  color: #fff;
  background-color: #1c67d8;
}

.nav-fill > .nav-link, .nav-item .nav-fill > .action-icon,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link, .nav-item .nav-justified > .action-icon,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.45rem 0.9rem;
}
.navbar .container, .navbar .notification-container .notification-list, .notification-container .navbar .notification-list,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0.9rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link, .navbar-nav .nav-item .action-icon, .nav-item .navbar-nav .action-icon {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .notification-container .navbar-expand-sm > .notification-list,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-sm .navbar-nav .action-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container, .notification-container .navbar-expand-sm > .notification-list,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .notification-container .navbar-expand-md > .notification-list,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-md .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-md .navbar-nav .action-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container, .notification-container .navbar-expand-md > .notification-list,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .notification-container .navbar-expand-lg > .notification-list,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-lg .navbar-nav .action-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container, .notification-container .navbar-expand-lg > .notification-list,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .notification-container .navbar-expand-xl > .notification-list,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link, .navbar-expand-xl .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-xl .navbar-nav .action-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container, .notification-container .navbar-expand-xl > .notification-list,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container, .notification-container .navbar-expand-xxl > .notification-list,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link, .navbar-expand-xxl .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand-xxl .navbar-nav .action-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container, .notification-container .navbar-expand-xxl > .notification-list,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container, .notification-container .navbar-expand > .notification-list,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link, .navbar-expand .navbar-nav .nav-item .action-icon, .nav-item .navbar-expand .navbar-nav .action-icon {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container, .notification-container .navbar-expand > .notification-list,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-item .action-icon, .nav-item .navbar-light .navbar-nav .action-icon {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-item .action-icon:hover, .nav-item .navbar-light .navbar-nav .action-icon:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-item .action-icon:focus, .nav-item .navbar-light .navbar-nav .action-icon:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled, .navbar-light .navbar-nav .nav-item .disabled.action-icon, .nav-item .navbar-light .navbar-nav .disabled.action-icon {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-item .show > .action-icon, .nav-item .navbar-light .navbar-nav .show > .action-icon,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-item .active > .action-icon,
.nav-item .navbar-light .navbar-nav .active > .action-icon,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-item .show.action-icon,
.nav-item .navbar-light .navbar-nav .show.action-icon,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-item .active.action-icon,
.nav-item .navbar-light .navbar-nav .active.action-icon {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.6%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-item .action-icon, .nav-item .navbar-dark .navbar-nav .action-icon {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-item .action-icon:hover, .nav-item .navbar-dark .navbar-nav .action-icon:hover, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-item .action-icon:focus, .nav-item .navbar-dark .navbar-nav .action-icon:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled, .navbar-dark .navbar-nav .nav-item .disabled.action-icon, .nav-item .navbar-dark .navbar-nav .disabled.action-icon {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-item .show > .action-icon, .nav-item .navbar-dark .navbar-nav .show > .action-icon,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-item .active > .action-icon,
.nav-item .navbar-dark .navbar-nav .active > .action-icon,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-item .show.action-icon,
.nav-item .navbar-dark .navbar-nav .show.action-icon,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-item .active.action-icon,
.nav-item .navbar-dark .navbar-nav .active.action-icon {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #ced4da;
}
.card-header:first-child {
  border-radius: calc(0.2rem - 1px) calc(0.2rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #ced4da;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.2rem - 1px) calc(0.2rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.9rem;
  border-radius: calc(0.2rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}

.card-deck .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.75rem;
  }
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.2rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.2rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--bs-link-color);
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-link-hover-color);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 2;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1c67d8;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1651ab;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(28, 103, 216, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #e2e2e2;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #c9c9c9;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(226, 226, 226, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #3eaf64;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #31894e;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(62, 175, 100, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #0dcaf0;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #0aa1c0;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: rgb(235, 173, 26);
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #c18d11;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(235, 173, 26, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e23d4b;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #cd1f2e;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(226, 61, 75, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #212529;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #0a0c0d;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.9rem 0.9rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0 rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.3rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9rem 0.9rem;
  color: inherit;
}

.alert-primary {
  color: #f6f9fd;
  background-color: #1a5fc7;
  border-color: #1a5fc7;
}
.alert-primary hr {
  border-top-color: #1754b0;
}
.alert-primary .alert-link {
  color: #ccddf4;
}

.alert-secondary {
  color: #fefefe;
  background-color: #d0d0d0;
  border-color: #d0d0d0;
}
.alert-secondary hr {
  border-top-color: #c3c3c3;
}
.alert-secondary .alert-link {
  color: #e5e5e5;
}

.alert-success {
  color: #f7fcf9;
  background-color: #39a15c;
  border-color: #39a15c;
}
.alert-success hr {
  border-top-color: #328e51;
}
.alert-success .alert-link {
  color: #d2eedd;
}

.alert-info {
  color: #f5fdfe;
  background-color: #0cbadd;
  border-color: #0cbadd;
}
.alert-info hr {
  border-top-color: #0ba6c5;
}
.alert-info .alert-link {
  color: #c7f4f9;
}

.alert-warning {
  color: #fefcf6;
  background-color: #d89f18;
  border-color: #d89f18;
}
.alert-warning hr {
  border-top-color: #c18e15;
}
.alert-warning .alert-link {
  color: #f9edc8;
}

.alert-danger {
  color: #fef7f8;
  background-color: #d03845;
  border-color: #d03845;
}
.alert-danger hr {
  border-top-color: #c12e3a;
}
.alert-danger .alert-link {
  color: #f8cad0;
}

.alert-light {
  color: white;
  background-color: #e4e5e6;
  border-color: #e4e5e6;
}
.alert-light hr {
  border-top-color: #d7d8da;
}
.alert-light .alert-link {
  color: #e6e6e6;
}

.alert-dark {
  color: #f6f6f6;
  background-color: #1e2226;
  border-color: #1e2226;
}
.alert-dark hr {
  border-top-color: #131518;
}
.alert-dark .alert-link {
  color: #dddddd;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.2rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1c67d8;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.2rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.45rem 0.9rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1c67d8;
  border-color: #1c67d8;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.2rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.2rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0f3670;
  background-color: #bfd4f4;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0f3670;
  background-color: #a9c5f0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0f3670;
  border-color: #0f3670;
}

.list-group-item-secondary {
  color: #767676;
  background-color: #f7f7f7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #767676;
  background-color: #eaeaea;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #767676;
  border-color: #767676;
}

.list-group-item-success {
  color: #205b34;
  background-color: #c9e9d4;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #205b34;
  background-color: #b7e2c6;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #205b34;
  border-color: #205b34;
}

.list-group-item-info {
  color: #07697d;
  background-color: #bbf0fb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #07697d;
  background-color: #a3ebfa;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #07697d;
  border-color: #07697d;
}

.list-group-item-warning {
  color: #7a5a0e;
  background-color: #f9e8bf;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7a5a0e;
  background-color: #f7e0a8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7a5a0e;
  border-color: #7a5a0e;
}

.list-group-item-danger {
  color: #762027;
  background-color: #f7c9cd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #762027;
  background-color: #f4b3b8;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #762027;
  border-color: #762027;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #111315;
  background-color: #b4b5b6;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #111315;
  border-color: #111315;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color-translucent);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 0.2rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1048;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 0.5rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1047;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.3;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.9rem 0.9rem;
  border-bottom: 1px solid var(--bs-border-color);
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.modal-header .close {
  padding: 0.9rem 0.9rem;
  margin: -0.9rem -0.9rem -0.9rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.9rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.65rem;
  border-top: 1px solid var(--bs-border-color);
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1051;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top], .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-auto[data-popper-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.225rem 0.45rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.2rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1042;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 0.5rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top], .bs-popover-auto[data-popper-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow, .bs-popover-auto[data-popper-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before, .bs-popover-auto[data-popper-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--bs-border-color-translucent);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after, .bs-popover-auto[data-popper-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--bs-border-color-translucent);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom], .bs-popover-auto[data-popper-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow, .bs-popover-auto[data-popper-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: var(--bs-border-color-translucent);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--bs-border-color-translucent);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.9rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #e3e3e3;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.9rem 0.9rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1c67d8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1651ab !important;
}

.bg-secondary {
  background-color: #e2e2e2 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c9c9c9 !important;
}

.bg-success {
  background-color: #3eaf64 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #31894e !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0aa1c0 !important;
}

.bg-warning {
  background-color: rgb(235, 173, 26) !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c18d11 !important;
}

.bg-danger {
  background-color: #e23d4b !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cd1f2e !important;
}

.bg-light, .gutter.gutter-horizontal {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.gutter.gutter-horizontal:hover, a.bg-light:focus, a.gutter.gutter-horizontal:focus,
button.bg-light:hover,
button.gutter.gutter-horizontal:hover,
button.bg-light:focus,
button.gutter.gutter-horizontal:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0a0c0d !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border, .details-view .drawer-content {
  border: 1px solid #ced4da !important;
}

.border-top {
  border-top: 1px solid #ced4da !important;
}

.border-right, .vertical-navbar.second-navbar, .gutter.gutter-horizontal {
  border-right: 1px solid #ced4da !important;
}

.border-bottom, .details-view .drawer-content .details-view-header {
  border-bottom: 1px solid #ced4da !important;
}

.border-left, .gutter.gutter-horizontal {
  border-left: 1px solid #ced4da !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0, .details-view .drawer-content {
  border-bottom: 0 !important;
}

.border-left-0, .flip.flip-one-column .flip-panel-right {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1c67d8 !important;
}

.border-secondary, .vertical-navbar.second-navbar {
  border-color: #e2e2e2 !important;
}

.border-success {
  border-color: #3eaf64 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: rgb(235, 173, 26) !important;
}

.border-danger {
  border-color: #e23d4b !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.2rem !important;
}

.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

.rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-left {
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .book-tile-wrapper .book-tile .book-tile-actions, .book-tile-wrapper .book-tile .card-body, .loading-modal, .flip, .vertical-navbar, .wrap-grid, .vertical-split-group > div, .vertical-split-group {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row, .book-tile-wrapper .book-tile .book-tile-actions, .vertical-split-group > div {
  flex-direction: row !important;
}

.flex-column, .book-tile-wrapper .book-tile .card-body, .vertical-navbar {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap, .wrap-grid {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill, .book-tile-wrapper .book-tile .book-tile-actions .book-tile-status, .book-tile-wrapper .book-tile .book-tile-info, .bookview-tabs, .bookview > .vertical-split-group, .vertical-split-group > div {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0, .book-tile-wrapper .book-tile .book-tile-details, .book-tile-wrapper .book-tile .book-tile-actions .nav, .book-tile-wrapper .book-tile .book-tile-actions, .card-img-left .card-img {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .loading-modal {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .book-tile-wrapper .book-tile .book-tile-actions, .loading-modal {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1040;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1039;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm, .details-view .drawer-content .details-view-header .cover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow, .context-menu, .details-view .drawer-content {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.225rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.225rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.225rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.225rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.225rem !important;
}

.m-2 {
  margin: 0.45rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.45rem !important;
}

.mr-2, .context-menu .icon,
.mx-2 {
  margin-right: 0.45rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.45rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.45rem !important;
}

.m-3 {
  margin: 0.9rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.9rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.9rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.9rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.9rem !important;
}

.m-4 {
  margin: 1.35rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.35rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.35rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.35rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.35rem !important;
}

.m-5 {
  margin: 2.7rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.7rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.7rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.7rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.7rem !important;
}

.p-0, .book-tile-wrapper .book-tile .card-body {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1, .book-tile-wrapper .book-tile .book-tile-actions .book-tile-status {
  padding: 0.225rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.225rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.225rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.225rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.225rem !important;
}

.p-2 {
  padding: 0.45rem !important;
}

.pt-2, .context-menu .context-menu-item,
.py-2 {
  padding-top: 0.45rem !important;
}

.pr-2, .context-menu .context-menu-item, .book-tile-wrapper .book-tile .book-tile-actions .book-tile-status,
.px-2 {
  padding-right: 0.45rem !important;
}

.pb-2, .context-menu .context-menu-item,
.py-2 {
  padding-bottom: 0.45rem !important;
}

.pl-2, .context-menu .context-menu-item,
.px-2 {
  padding-left: 0.45rem !important;
}

.p-3, .details-view .drawer-content .details-view-body, .details-view .drawer-content .details-view-header, .book-tile-wrapper .book-tile .book-tile-info, .splash-page-context .splash-page-status {
  padding: 0.9rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.9rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.9rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.9rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.9rem !important;
}

.p-4 {
  padding: 1.35rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.35rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.35rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.35rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.35rem !important;
}

.p-5 {
  padding: 2.7rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.7rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.7rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.7rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.7rem !important;
}

.m-n1 {
  margin: -0.225rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.225rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.225rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.225rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.225rem !important;
}

.m-n2 {
  margin: -0.45rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.45rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.45rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.45rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.45rem !important;
}

.m-n3 {
  margin: -0.9rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.9rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.9rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.9rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.9rem !important;
}

.m-n4 {
  margin: -1.35rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.35rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.35rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.35rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.35rem !important;
}

.m-n5 {
  margin: -2.7rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2.7rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2.7rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2.7rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2.7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.225rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.225rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.225rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.225rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.225rem !important;
  }
  .m-sm-2 {
    margin: 0.45rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.45rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.45rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.45rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.45rem !important;
  }
  .m-sm-3 {
    margin: 0.9rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 0.9rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 0.9rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 0.9rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 0.9rem !important;
  }
  .m-sm-4 {
    margin: 1.35rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.35rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.35rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.35rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.35rem !important;
  }
  .m-sm-5 {
    margin: 2.7rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 2.7rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 2.7rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 2.7rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 2.7rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.225rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.225rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.225rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.225rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.225rem !important;
  }
  .p-sm-2 {
    padding: 0.45rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.45rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.45rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.45rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.45rem !important;
  }
  .p-sm-3 {
    padding: 0.9rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 0.9rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 0.9rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 0.9rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 0.9rem !important;
  }
  .p-sm-4 {
    padding: 1.35rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.35rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.35rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.35rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.35rem !important;
  }
  .p-sm-5 {
    padding: 2.7rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 2.7rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 2.7rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 2.7rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 2.7rem !important;
  }
  .m-sm-n1 {
    margin: -0.225rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.225rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.225rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.225rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.225rem !important;
  }
  .m-sm-n2 {
    margin: -0.45rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.45rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.45rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.45rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.45rem !important;
  }
  .m-sm-n3 {
    margin: -0.9rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -0.9rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -0.9rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -0.9rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -0.9rem !important;
  }
  .m-sm-n4 {
    margin: -1.35rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.35rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.35rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.35rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.35rem !important;
  }
  .m-sm-n5 {
    margin: -2.7rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -2.7rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -2.7rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -2.7rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -2.7rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.225rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.225rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.225rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.225rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.225rem !important;
  }
  .m-md-2 {
    margin: 0.45rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.45rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.45rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.45rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.45rem !important;
  }
  .m-md-3 {
    margin: 0.9rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 0.9rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 0.9rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 0.9rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 0.9rem !important;
  }
  .m-md-4 {
    margin: 1.35rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.35rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.35rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.35rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.35rem !important;
  }
  .m-md-5 {
    margin: 2.7rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 2.7rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 2.7rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 2.7rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 2.7rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.225rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.225rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.225rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.225rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.225rem !important;
  }
  .p-md-2 {
    padding: 0.45rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.45rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.45rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.45rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.45rem !important;
  }
  .p-md-3 {
    padding: 0.9rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 0.9rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 0.9rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 0.9rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 0.9rem !important;
  }
  .p-md-4 {
    padding: 1.35rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.35rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.35rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.35rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.35rem !important;
  }
  .p-md-5 {
    padding: 2.7rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 2.7rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 2.7rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 2.7rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 2.7rem !important;
  }
  .m-md-n1 {
    margin: -0.225rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.225rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.225rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.225rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.225rem !important;
  }
  .m-md-n2 {
    margin: -0.45rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.45rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.45rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.45rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.45rem !important;
  }
  .m-md-n3 {
    margin: -0.9rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -0.9rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -0.9rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -0.9rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -0.9rem !important;
  }
  .m-md-n4 {
    margin: -1.35rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.35rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.35rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.35rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.35rem !important;
  }
  .m-md-n5 {
    margin: -2.7rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -2.7rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -2.7rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -2.7rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -2.7rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.225rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.225rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.225rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.225rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.225rem !important;
  }
  .m-lg-2 {
    margin: 0.45rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.45rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.45rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.45rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.45rem !important;
  }
  .m-lg-3 {
    margin: 0.9rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 0.9rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 0.9rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 0.9rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 0.9rem !important;
  }
  .m-lg-4 {
    margin: 1.35rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.35rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.35rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.35rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.35rem !important;
  }
  .m-lg-5 {
    margin: 2.7rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 2.7rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 2.7rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 2.7rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 2.7rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.225rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.225rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.225rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.225rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.225rem !important;
  }
  .p-lg-2 {
    padding: 0.45rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.45rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.45rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.45rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.45rem !important;
  }
  .p-lg-3 {
    padding: 0.9rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 0.9rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 0.9rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 0.9rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 0.9rem !important;
  }
  .p-lg-4 {
    padding: 1.35rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.35rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.35rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.35rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.35rem !important;
  }
  .p-lg-5 {
    padding: 2.7rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 2.7rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 2.7rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 2.7rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 2.7rem !important;
  }
  .m-lg-n1 {
    margin: -0.225rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.225rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.225rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.225rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.225rem !important;
  }
  .m-lg-n2 {
    margin: -0.45rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.45rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.45rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.45rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.45rem !important;
  }
  .m-lg-n3 {
    margin: -0.9rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -0.9rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -0.9rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -0.9rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -0.9rem !important;
  }
  .m-lg-n4 {
    margin: -1.35rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.35rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.35rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.35rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.35rem !important;
  }
  .m-lg-n5 {
    margin: -2.7rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -2.7rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -2.7rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -2.7rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -2.7rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.225rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.225rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.225rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.225rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.225rem !important;
  }
  .m-xl-2 {
    margin: 0.45rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.45rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.45rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.45rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.45rem !important;
  }
  .m-xl-3 {
    margin: 0.9rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 0.9rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 0.9rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 0.9rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 0.9rem !important;
  }
  .m-xl-4 {
    margin: 1.35rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.35rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.35rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.35rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.35rem !important;
  }
  .m-xl-5 {
    margin: 2.7rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 2.7rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 2.7rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 2.7rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 2.7rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.225rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.225rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.225rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.225rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.225rem !important;
  }
  .p-xl-2 {
    padding: 0.45rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.45rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.45rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.45rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.45rem !important;
  }
  .p-xl-3 {
    padding: 0.9rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 0.9rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 0.9rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 0.9rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 0.9rem !important;
  }
  .p-xl-4 {
    padding: 1.35rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.35rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.35rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.35rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.35rem !important;
  }
  .p-xl-5 {
    padding: 2.7rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 2.7rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 2.7rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 2.7rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 2.7rem !important;
  }
  .m-xl-n1 {
    margin: -0.225rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.225rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.225rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.225rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.225rem !important;
  }
  .m-xl-n2 {
    margin: -0.45rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.45rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.45rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.45rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.45rem !important;
  }
  .m-xl-n3 {
    margin: -0.9rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -0.9rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -0.9rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -0.9rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -0.9rem !important;
  }
  .m-xl-n4 {
    margin: -1.35rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.35rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.35rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.35rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.35rem !important;
  }
  .m-xl-n5 {
    margin: -2.7rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -2.7rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -2.7rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -2.7rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -2.7rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.225rem !important;
  }
  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.225rem !important;
  }
  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.225rem !important;
  }
  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.225rem !important;
  }
  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.225rem !important;
  }
  .m-xxl-2 {
    margin: 0.45rem !important;
  }
  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.45rem !important;
  }
  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.45rem !important;
  }
  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.45rem !important;
  }
  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.45rem !important;
  }
  .m-xxl-3 {
    margin: 0.9rem !important;
  }
  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 0.9rem !important;
  }
  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 0.9rem !important;
  }
  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 0.9rem !important;
  }
  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 0.9rem !important;
  }
  .m-xxl-4 {
    margin: 1.35rem !important;
  }
  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.35rem !important;
  }
  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.35rem !important;
  }
  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.35rem !important;
  }
  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.35rem !important;
  }
  .m-xxl-5 {
    margin: 2.7rem !important;
  }
  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 2.7rem !important;
  }
  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 2.7rem !important;
  }
  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 2.7rem !important;
  }
  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 2.7rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.225rem !important;
  }
  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.225rem !important;
  }
  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.225rem !important;
  }
  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.225rem !important;
  }
  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.225rem !important;
  }
  .p-xxl-2 {
    padding: 0.45rem !important;
  }
  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.45rem !important;
  }
  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.45rem !important;
  }
  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.45rem !important;
  }
  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.45rem !important;
  }
  .p-xxl-3 {
    padding: 0.9rem !important;
  }
  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 0.9rem !important;
  }
  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 0.9rem !important;
  }
  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 0.9rem !important;
  }
  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 0.9rem !important;
  }
  .p-xxl-4 {
    padding: 1.35rem !important;
  }
  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.35rem !important;
  }
  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.35rem !important;
  }
  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.35rem !important;
  }
  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.35rem !important;
  }
  .p-xxl-5 {
    padding: 2.7rem !important;
  }
  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 2.7rem !important;
  }
  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 2.7rem !important;
  }
  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 2.7rem !important;
  }
  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 2.7rem !important;
  }
  .m-xxl-n1 {
    margin: -0.225rem !important;
  }
  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.225rem !important;
  }
  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.225rem !important;
  }
  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.225rem !important;
  }
  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.225rem !important;
  }
  .m-xxl-n2 {
    margin: -0.45rem !important;
  }
  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.45rem !important;
  }
  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.45rem !important;
  }
  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.45rem !important;
  }
  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.45rem !important;
  }
  .m-xxl-n3 {
    margin: -0.9rem !important;
  }
  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -0.9rem !important;
  }
  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -0.9rem !important;
  }
  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -0.9rem !important;
  }
  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -0.9rem !important;
  }
  .m-xxl-n4 {
    margin: -1.35rem !important;
  }
  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.35rem !important;
  }
  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.35rem !important;
  }
  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.35rem !important;
  }
  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.35rem !important;
  }
  .m-xxl-n5 {
    margin: -2.7rem !important;
  }
  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -2.7rem !important;
  }
  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -2.7rem !important;
  }
  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -2.7rem !important;
  }
  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -2.7rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1c67d8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #134794 !important;
}

.text-secondary {
  color: #e2e2e2 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #bcbcbc !important;
}

.text-success {
  color: #3eaf64 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #2a7744 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #098da7 !important;
}

.text-warning {
  color: rgb(235, 173, 26) !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #aa7c0f !important;
}

.text-danger {
  color: #e23d4b !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b71b29 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
.h2,
h3,
.h3 {
    orphans: 3;
    widows: 3;
  }
  h2, .h2,
h3,
.h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container, .notification-container .notification-list {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: var(--bs-border-color);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: var(--bs-border-color);
  }
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  user-select: none;
}

input {
  user-select: text !important;
}

body {
  background-color: #e9ecef;
  font-family: "Fira Sans", sans-serif;
}

.bg-void {
  background-color: #6c757d !important;
}

.bg-body {
  background-color: #e9ecef !important;
}

.clickable {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.breakword {
  white-space: break-word;
}

.valign-text-bottom {
  vertical-align: text-bottom;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

iframe {
  border: 0;
  display: block;
  width: 100%;
}

*[tabindex] {
  cursor: pointer;
}

input[tabindex], textarea[tabindex] {
  cursor: text;
}

#root,
#appLayout {
  height: 100%;
  width: 100%;
}
#root .menu-drawer,
#appLayout .menu-drawer {
  width: 350px;
  max-width: 95%;
  z-index: 1050;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
}

.bookview > .vertical-split-group {
  position: relative;
}

.full-height, .bookview-tabs > .tab-content > .tab-pane, .bookview-tabs > .tab-content, .bookview-tabs {
  height: 100%;
}

.full-width {
  width: 100%;
}

.tab-pane {
  position: relative;
}

.initial-app-loading.loading-modal {
  z-index: 10000 !important;
}

.book-tab-close {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
  margin-left: -8px;
}
.book-tab-close:hover svg {
  background-color: rgba(255, 0, 0, 0.5);
  border: 1px solid;
  padding: 1px;
}

.book-tab-name {
  vertical-align: middle;
}

.tab-spinner {
  width: 0.81rem;
  height: 0.81rem;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 13px;
  margin-right: 7px;
  color: var(--accentTextColour);
}

.book-tab-container {
  position: relative;
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.flex-fill.scrollable, .book-tile-wrapper .book-tile .book-tile-actions .scrollable.book-tile-status, .book-tile-wrapper .book-tile .scrollable.book-tile-info, .scrollable.bookview-tabs, .vertical-split-group > div.scrollable, .bookview > .scrollable.vertical-split-group {
  height: 0;
}

.text-ellipsis, .nav-compact-tabs .nav-link, .nav-compact-tabs .nav-item .action-icon, .nav-item .nav-compact-tabs .action-icon {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-reader {
  max-width: 65rem;
  margin: 0 auto;
  background-color: #fff;
  height: 1px;
  min-height: 100%;
}

.content-reader-unbound {
  margin: 0 auto;
  background-color: #fff;
  width: 100%;
  height: 1px;
  min-height: 100%;
}

.content-reader-container {
  width: 100%;
  height: 100%;
}

.breakText {
  display: inline-block !important;
  word-break: break-word !important;
}

.modal {
  overflow-y: hidden !important;
}

.bookview-toggle-placeholder {
  visibility: hidden;
  display: none;
  margin-left: -0.9rem;
}
.bookview-toggle-placeholder .bookview-toggle-container {
  height: 0;
  position: static;
}

.library-nav-bar {
  z-index: 1046;
}

.bookview .collapsible {
  overflow: hidden;
}

.scrolling-reader-container {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
  height: 100%;
  width: 100%;
  position: relative;
}

.bookview .collapsible.collapsed {
  display: none !important;
}
.bookview .collapsible.collapsed ~ .split-panel {
  width: 100% !important;
}
.bookview .collapsible.collapsed ~ .split-panel .bookview-toggle-placeholder {
  display: block;
}
.bookview .collapsible.collapsed ~ .gutter.gutter-horizontal {
  display: none;
}

@media (max-width: 768px) {
  .bookview > .vertical-split-group {
    overflow: hidden;
  }
  .bookview .collapsible {
    transition: transform 0.2s linear, box-shadow 0.2s linear;
    width: 100% !important;
    z-index: 1036;
    bottom: 0;
    top: 0;
    position: absolute;
    transform: translate(0, 0);
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.3);
  }
  .bookview .collapsible ~ .split-panel {
    width: 100% !important;
  }
  .bookview .collapsible.collapsed {
    display: block !important;
    transform: translate(-100%, 0);
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0);
  }
}
.first-navbar {
  transition: background-color 0.2s linear;
}
.first-navbar.has-light-bg .icon svg {
  color: rgba(0, 0, 0, 0.6);
}
.first-navbar.has-light-bg .icon svg rect[stroke], .first-navbar.has-light-bg .icon svg circle[stroke], .first-navbar.has-light-bg .icon svg polyline[stroke], .first-navbar.has-light-bg .icon svg polygon[stroke], .first-navbar.has-light-bg .icon svg line[stroke], .first-navbar.has-light-bg .icon svg ellipse[stroke], .first-navbar.has-light-bg .icon svg path[stroke], .first-navbar.has-light-bg .icon svg g[stroke] {
  stroke: rgba(0, 0, 0, 0.6);
}
.first-navbar.has-light-bg .icon svg rect, .first-navbar.has-light-bg .icon svg circle, .first-navbar.has-light-bg .icon svg polyline, .first-navbar.has-light-bg .icon svg polygon, .first-navbar.has-light-bg .icon svg line, .first-navbar.has-light-bg .icon svg ellipse, .first-navbar.has-light-bg .icon svg path, .first-navbar.has-light-bg .icon svg g {
  fill: rgba(0, 0, 0, 0.6);
}
.first-navbar.has-light-bg .icon svg rect[stroke=none], .first-navbar.has-light-bg .icon svg circle[stroke=none], .first-navbar.has-light-bg .icon svg polyline[stroke=none], .first-navbar.has-light-bg .icon svg polygon[stroke=none], .first-navbar.has-light-bg .icon svg line[stroke=none], .first-navbar.has-light-bg .icon svg ellipse[stroke=none], .first-navbar.has-light-bg .icon svg path[stroke=none], .first-navbar.has-light-bg .icon svg g[stroke=none] {
  stroke: none;
}
.first-navbar.has-light-bg .icon svg rect[fill=none], .first-navbar.has-light-bg .icon svg circle[fill=none], .first-navbar.has-light-bg .icon svg polyline[fill=none], .first-navbar.has-light-bg .icon svg polygon[fill=none], .first-navbar.has-light-bg .icon svg line[fill=none], .first-navbar.has-light-bg .icon svg ellipse[fill=none], .first-navbar.has-light-bg .icon svg path[fill=none], .first-navbar.has-light-bg .icon svg g[fill=none] {
  fill: none;
}
.first-navbar.has-light-bg .icon svg g[fill=none] rect, .first-navbar.has-light-bg .icon svg g[fill=none] circle, .first-navbar.has-light-bg .icon svg g[fill=none] polyline, .first-navbar.has-light-bg .icon svg g[fill=none] polygon, .first-navbar.has-light-bg .icon svg g[fill=none] line, .first-navbar.has-light-bg .icon svg g[fill=none] ellipse, .first-navbar.has-light-bg .icon svg g[fill=none] path {
  fill: none;
}
.first-navbar.has-light-bg .library-selection-icon.active {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075), 0 1px 0 0 rgba(0, 0, 0, 0.5), 0 0 0 4px rgba(33, 37, 41, 0.5);
}
.first-navbar .add-library {
  padding: 5px;
  border: 1px solid white;
}
.first-navbar .add-library svg {
  margin: 5px;
}

.wrap-grid > * {
  width: 100%;
}
@media (min-width: 768px) {
  .wrap-grid > * {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .wrap-grid > * {
    width: 33%;
  }
}
@media (min-width: 1200px) {
  .wrap-grid > * {
    width: 25%;
  }
}

@media (max-width: 1199.98px) {
  .collapsed-navbar {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .btn-fab {
    display: none;
  }
}
.bottomSpacer {
  width: 100%;
  height: 5em;
}

.titleButton {
  margin-left: 70%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.vertical-navbar {
  font-size: 1.4rem;
}
.vertical-navbar.first-navbar {
  background-color: var(--firstNavbarBg);
  color: var(--firstNavbarColour);
  z-index: 1046;
}
.vertical-navbar.first-navbar svg {
  color: var(--firstNavbarColour);
}
.vertical-navbar.first-navbar svg rect[stroke], .vertical-navbar.first-navbar svg circle[stroke], .vertical-navbar.first-navbar svg polyline[stroke], .vertical-navbar.first-navbar svg polygon[stroke], .vertical-navbar.first-navbar svg line[stroke], .vertical-navbar.first-navbar svg ellipse[stroke], .vertical-navbar.first-navbar svg path[stroke], .vertical-navbar.first-navbar svg g[stroke] {
  stroke: var(--firstNavbarColour);
}
.vertical-navbar.first-navbar svg rect, .vertical-navbar.first-navbar svg circle, .vertical-navbar.first-navbar svg polyline, .vertical-navbar.first-navbar svg polygon, .vertical-navbar.first-navbar svg line, .vertical-navbar.first-navbar svg ellipse, .vertical-navbar.first-navbar svg path, .vertical-navbar.first-navbar svg g {
  fill: var(--firstNavbarColour);
}
.vertical-navbar.first-navbar svg rect[stroke=none], .vertical-navbar.first-navbar svg circle[stroke=none], .vertical-navbar.first-navbar svg polyline[stroke=none], .vertical-navbar.first-navbar svg polygon[stroke=none], .vertical-navbar.first-navbar svg line[stroke=none], .vertical-navbar.first-navbar svg ellipse[stroke=none], .vertical-navbar.first-navbar svg path[stroke=none], .vertical-navbar.first-navbar svg g[stroke=none] {
  stroke: none;
}
.vertical-navbar.first-navbar svg rect[fill=none], .vertical-navbar.first-navbar svg circle[fill=none], .vertical-navbar.first-navbar svg polyline[fill=none], .vertical-navbar.first-navbar svg polygon[fill=none], .vertical-navbar.first-navbar svg line[fill=none], .vertical-navbar.first-navbar svg ellipse[fill=none], .vertical-navbar.first-navbar svg path[fill=none], .vertical-navbar.first-navbar svg g[fill=none] {
  fill: none;
}
.vertical-navbar.first-navbar svg g[fill=none] rect, .vertical-navbar.first-navbar svg g[fill=none] circle, .vertical-navbar.first-navbar svg g[fill=none] polyline, .vertical-navbar.first-navbar svg g[fill=none] polygon, .vertical-navbar.first-navbar svg g[fill=none] line, .vertical-navbar.first-navbar svg g[fill=none] ellipse, .vertical-navbar.first-navbar svg g[fill=none] path {
  fill: none;
}
.vertical-navbar.second-navbar {
  background-color: var(--secondNavbarBg);
  color: var(--secondNavbarColour);
  display: table !important;
  height: 100%;
}
@media (max-height: 600px) {
  .vertical-navbar.second-navbar {
    display: inherit;
    position: relative;
  }
}
.vertical-navbar.second-navbar .icon svg {
  color: rgba(0, 0, 0, 0.6);
}
.vertical-navbar.second-navbar .icon svg rect[stroke], .vertical-navbar.second-navbar .icon svg circle[stroke], .vertical-navbar.second-navbar .icon svg polyline[stroke], .vertical-navbar.second-navbar .icon svg polygon[stroke], .vertical-navbar.second-navbar .icon svg line[stroke], .vertical-navbar.second-navbar .icon svg ellipse[stroke], .vertical-navbar.second-navbar .icon svg path[stroke], .vertical-navbar.second-navbar .icon svg g[stroke] {
  stroke: rgba(0, 0, 0, 0.6);
}
.vertical-navbar.second-navbar .icon svg rect, .vertical-navbar.second-navbar .icon svg circle, .vertical-navbar.second-navbar .icon svg polyline, .vertical-navbar.second-navbar .icon svg polygon, .vertical-navbar.second-navbar .icon svg line, .vertical-navbar.second-navbar .icon svg ellipse, .vertical-navbar.second-navbar .icon svg path, .vertical-navbar.second-navbar .icon svg g {
  fill: rgba(0, 0, 0, 0.6);
}
.vertical-navbar.second-navbar .icon svg rect[stroke=none], .vertical-navbar.second-navbar .icon svg circle[stroke=none], .vertical-navbar.second-navbar .icon svg polyline[stroke=none], .vertical-navbar.second-navbar .icon svg polygon[stroke=none], .vertical-navbar.second-navbar .icon svg line[stroke=none], .vertical-navbar.second-navbar .icon svg ellipse[stroke=none], .vertical-navbar.second-navbar .icon svg path[stroke=none], .vertical-navbar.second-navbar .icon svg g[stroke=none] {
  stroke: none;
}
.vertical-navbar.second-navbar .icon svg rect[fill=none], .vertical-navbar.second-navbar .icon svg circle[fill=none], .vertical-navbar.second-navbar .icon svg polyline[fill=none], .vertical-navbar.second-navbar .icon svg polygon[fill=none], .vertical-navbar.second-navbar .icon svg line[fill=none], .vertical-navbar.second-navbar .icon svg ellipse[fill=none], .vertical-navbar.second-navbar .icon svg path[fill=none], .vertical-navbar.second-navbar .icon svg g[fill=none] {
  fill: none;
}
.vertical-navbar.second-navbar .icon svg g[fill=none] rect, .vertical-navbar.second-navbar .icon svg g[fill=none] circle, .vertical-navbar.second-navbar .icon svg g[fill=none] polyline, .vertical-navbar.second-navbar .icon svg g[fill=none] polygon, .vertical-navbar.second-navbar .icon svg g[fill=none] line, .vertical-navbar.second-navbar .icon svg g[fill=none] ellipse, .vertical-navbar.second-navbar .icon svg g[fill=none] path {
  fill: none;
}
.vertical-navbar.second-navbar .innerNav {
  display: table-cell;
  vertical-align: middle;
}
@media (max-height: 600px) {
  .vertical-navbar.second-navbar .innerNav {
    height: calc(100% - 50px);
    width: 100%;
    overflow-y: scroll;
    display: block;
    margin-top: 50px;
  }
}
.vertical-navbar .nav-item {
  margin: 0 auto;
}
.vertical-navbar .nav-item .action-icon {
  padding: 1.08rem;
}
.vertical-navbar .nav-item .action-icon.active {
  position: relative;
}
.vertical-navbar .nav-item .action-icon.active:after {
  width: 0;
  height: 0;
  content: " ";
  border: 3px solid var(--navItemActiveColour);
  background-color: var(--navItemActiveColour);
  position: absolute;
  border-radius: 300px;
  margin-left: 0.4rem;
  top: 50%;
}

.nav-item .action-icon.active {
  color: var(--navItemActiveColour);
}
.nav-item .action-icon.active rect[stroke], .nav-item .action-icon.active circle[stroke], .nav-item .action-icon.active polyline[stroke], .nav-item .action-icon.active polygon[stroke], .nav-item .action-icon.active line[stroke], .nav-item .action-icon.active ellipse[stroke], .nav-item .action-icon.active path[stroke], .nav-item .action-icon.active g[stroke] {
  stroke: var(--navItemActiveColour);
}
.nav-item .action-icon.active rect, .nav-item .action-icon.active circle, .nav-item .action-icon.active polyline, .nav-item .action-icon.active polygon, .nav-item .action-icon.active line, .nav-item .action-icon.active ellipse, .nav-item .action-icon.active path, .nav-item .action-icon.active g {
  fill: var(--navItemActiveColour);
}
.nav-item .action-icon.active rect[stroke=none], .nav-item .action-icon.active circle[stroke=none], .nav-item .action-icon.active polyline[stroke=none], .nav-item .action-icon.active polygon[stroke=none], .nav-item .action-icon.active line[stroke=none], .nav-item .action-icon.active ellipse[stroke=none], .nav-item .action-icon.active path[stroke=none], .nav-item .action-icon.active g[stroke=none] {
  stroke: none;
}
.nav-item .action-icon.active rect[fill=none], .nav-item .action-icon.active circle[fill=none], .nav-item .action-icon.active polyline[fill=none], .nav-item .action-icon.active polygon[fill=none], .nav-item .action-icon.active line[fill=none], .nav-item .action-icon.active ellipse[fill=none], .nav-item .action-icon.active path[fill=none], .nav-item .action-icon.active g[fill=none] {
  fill: none;
}
.nav-item .action-icon.active g[fill=none] rect, .nav-item .action-icon.active g[fill=none] circle, .nav-item .action-icon.active g[fill=none] polyline, .nav-item .action-icon.active g[fill=none] polygon, .nav-item .action-icon.active g[fill=none] line, .nav-item .action-icon.active g[fill=none] ellipse, .nav-item .action-icon.active g[fill=none] path {
  fill: none;
}

.nav-compact-tabs {
  font-size: 0.875em;
}
.nav-compact-tabs .nav-link, .nav-compact-tabs .nav-item .action-icon, .nav-item .nav-compact-tabs .action-icon {
  border-top-width: 0;
  border-radius: 0;
  max-width: 9rem;
  border-width: 0;
  border-right-width: 1px;
  border-right-color: #ced4da;
  color: var(--accentTextColour) !important;
}
.nav-compact-tabs .nav-link.active, .nav-compact-tabs .nav-item .active.action-icon, .nav-item .nav-compact-tabs .active.action-icon {
  color: var(--selectedTabTextColor) !important;
  background-color: transparent;
  border-bottom-color: var(--selectedTabTextColor);
  border-bottom-width: 2px;
}

.nav-pills .nav-link:hover, .nav-pills .nav-item .action-icon:hover, .nav-item .nav-pills .action-icon:hover {
  color: var(--infoHeaderColour);
}
.nav-pills .nav-link:hover .nav-link.active svg path, .nav-pills .nav-item .action-icon:hover .nav-link.active svg path, .nav-item .nav-pills .action-icon:hover .nav-link.active svg path, .nav-pills .nav-link:hover .nav-item .active.action-icon svg path, .nav-item .nav-pills .nav-link:hover .active.action-icon svg path, .nav-pills .nav-item .action-icon:hover .active.action-icon svg path, .nav-item .nav-pills .action-icon:hover .active.action-icon svg path {
  fill: var(--infoHeaderColour);
}
.nav-pills .nav-link.active:hover, .nav-pills .nav-item .active.action-icon:hover, .nav-item .nav-pills .active.action-icon:hover {
  color: var(--infoHeaderInvert);
}
.nav-pills .nav-link.active:hover .nav-link.active svg path, .nav-pills .nav-item .active.action-icon:hover .nav-link.active svg path, .nav-item .nav-pills .active.action-icon:hover .nav-link.active svg path, .nav-pills .nav-link.active:hover .nav-item .active.action-icon svg path, .nav-item .nav-pills .nav-link.active:hover .active.action-icon svg path, .nav-pills .nav-item .active.action-icon:hover .active.action-icon svg path, .nav-item .nav-pills .active.action-icon:hover .active.action-icon svg path {
  fill: var(--infoHeaderInvert);
}
.nav-pills .nav-link.active svg path, .nav-pills .nav-item .active.action-icon svg path, .nav-item .nav-pills .active.action-icon svg path {
  fill: var(--infoHeaderInvert);
}
.nav-pills .nav-link.active span, .nav-pills .nav-item .active.action-icon span, .nav-item .nav-pills .active.action-icon span {
  color: var(--infoHeaderInvert);
}
.nav-pills .nav-link.active, .nav-pills .nav-item .active.action-icon, .nav-item .nav-pills .active.action-icon,
.nav-pills .nav-link.active {
  background-color: var(--infoHeaderColour);
}
.nav-pills .header-span {
  margin-left: 5px;
}
.nav-pills .nav-link:not(.disabled):not(.active), .nav-pills .nav-item .action-icon:not(.disabled):not(.active), .nav-item .nav-pills .action-icon:not(.disabled):not(.active) {
  cursor: pointer;
  color: var(--infoHeaderColour);
}
.nav-pills .nav-link:not(.disabled):not(.active) svg path, .nav-pills .nav-item .action-icon:not(.disabled):not(.active) svg path, .nav-item .nav-pills .action-icon:not(.disabled):not(.active) svg path {
  fill: var(--infoHeaderColour);
}

.tab-content > .tab-pane.d-flex, .book-tile-wrapper .book-tile .tab-content > .tab-pane.book-tile-actions, .book-tile-wrapper .book-tile .tab-content > .tab-pane.card-body, .tab-content > .tab-pane.loading-modal, .tab-content > .tab-pane.flip, .tab-content > .tab-pane.vertical-split-group, .vertical-split-group.tab-content > div.tab-pane, .tab-content > .tab-pane.wrap-grid, .tab-content > .tab-pane.vertical-navbar {
  display: none !important;
}

.tab-content > .tab-pane.d-flex.active, .book-tile-wrapper .book-tile .tab-content > .tab-pane.active.book-tile-actions, .book-tile-wrapper .book-tile .tab-content > .tab-pane.active.card-body, .tab-content > .tab-pane.active.loading-modal, .tab-content > .tab-pane.active.flip, .tab-content > .tab-pane.active.vertical-split-group, .vertical-split-group.tab-content > div.tab-pane.active, .tab-content > .tab-pane.active.wrap-grid, .tab-content > .tab-pane.active.vertical-navbar {
  display: flex !important;
}

.action-icon {
  opacity: 0.9;
}

.action-icon:focus, .action-icon:active {
  opacity: 1;
}

.btn .icon {
  float: left;
  margin-right: 0.9rem;
}

.icon svg {
  width: 17px;
  color: #212529;
}
.icon svg rect[stroke], .icon svg circle[stroke], .icon svg polyline[stroke], .icon svg polygon[stroke], .icon svg line[stroke], .icon svg ellipse[stroke], .icon svg path[stroke], .icon svg g[stroke] {
  stroke: #212529;
}
.icon svg rect, .icon svg circle, .icon svg polyline, .icon svg polygon, .icon svg line, .icon svg ellipse, .icon svg path, .icon svg g {
  fill: #212529;
}
.icon svg rect[stroke=none], .icon svg circle[stroke=none], .icon svg polyline[stroke=none], .icon svg polygon[stroke=none], .icon svg line[stroke=none], .icon svg ellipse[stroke=none], .icon svg path[stroke=none], .icon svg g[stroke=none] {
  stroke: none;
}
.icon svg rect[fill=none], .icon svg circle[fill=none], .icon svg polyline[fill=none], .icon svg polygon[fill=none], .icon svg line[fill=none], .icon svg ellipse[fill=none], .icon svg path[fill=none], .icon svg g[fill=none] {
  fill: none;
}
.icon svg g[fill=none] rect, .icon svg g[fill=none] circle, .icon svg g[fill=none] polyline, .icon svg g[fill=none] polygon, .icon svg g[fill=none] line, .icon svg g[fill=none] ellipse, .icon svg g[fill=none] path {
  fill: none;
}
a .icon svg {
  color: #1c67d8;
}
a .icon svg rect[stroke], a .icon svg circle[stroke], a .icon svg polyline[stroke], a .icon svg polygon[stroke], a .icon svg line[stroke], a .icon svg ellipse[stroke], a .icon svg path[stroke], a .icon svg g[stroke] {
  stroke: #1c67d8;
}
a .icon svg rect, a .icon svg circle, a .icon svg polyline, a .icon svg polygon, a .icon svg line, a .icon svg ellipse, a .icon svg path, a .icon svg g {
  fill: #1c67d8;
}
a .icon svg rect[stroke=none], a .icon svg circle[stroke=none], a .icon svg polyline[stroke=none], a .icon svg polygon[stroke=none], a .icon svg line[stroke=none], a .icon svg ellipse[stroke=none], a .icon svg path[stroke=none], a .icon svg g[stroke=none] {
  stroke: none;
}
a .icon svg rect[fill=none], a .icon svg circle[fill=none], a .icon svg polyline[fill=none], a .icon svg polygon[fill=none], a .icon svg line[fill=none], a .icon svg ellipse[fill=none], a .icon svg path[fill=none], a .icon svg g[fill=none] {
  fill: none;
}
a .icon svg g[fill=none] rect, a .icon svg g[fill=none] circle, a .icon svg g[fill=none] polyline, a .icon svg g[fill=none] polygon, a .icon svg g[fill=none] line, a .icon svg g[fill=none] ellipse, a .icon svg g[fill=none] path {
  fill: none;
}
a:hover .icon svg {
  color: #1652ad;
}
a:hover .icon svg rect[stroke], a:hover .icon svg circle[stroke], a:hover .icon svg polyline[stroke], a:hover .icon svg polygon[stroke], a:hover .icon svg line[stroke], a:hover .icon svg ellipse[stroke], a:hover .icon svg path[stroke], a:hover .icon svg g[stroke] {
  stroke: #1652ad;
}
a:hover .icon svg rect, a:hover .icon svg circle, a:hover .icon svg polyline, a:hover .icon svg polygon, a:hover .icon svg line, a:hover .icon svg ellipse, a:hover .icon svg path, a:hover .icon svg g {
  fill: #1652ad;
}
a:hover .icon svg rect[stroke=none], a:hover .icon svg circle[stroke=none], a:hover .icon svg polyline[stroke=none], a:hover .icon svg polygon[stroke=none], a:hover .icon svg line[stroke=none], a:hover .icon svg ellipse[stroke=none], a:hover .icon svg path[stroke=none], a:hover .icon svg g[stroke=none] {
  stroke: none;
}
a:hover .icon svg rect[fill=none], a:hover .icon svg circle[fill=none], a:hover .icon svg polyline[fill=none], a:hover .icon svg polygon[fill=none], a:hover .icon svg line[fill=none], a:hover .icon svg ellipse[fill=none], a:hover .icon svg path[fill=none], a:hover .icon svg g[fill=none] {
  fill: none;
}
a:hover .icon svg g[fill=none] rect, a:hover .icon svg g[fill=none] circle, a:hover .icon svg g[fill=none] polyline, a:hover .icon svg g[fill=none] polygon, a:hover .icon svg g[fill=none] line, a:hover .icon svg g[fill=none] ellipse, a:hover .icon svg g[fill=none] path {
  fill: none;
}
.nav-link.disabled .icon svg, .nav-item .disabled.action-icon .icon svg {
  color: #6c757d;
}
.nav-link.disabled .icon svg rect[stroke], .nav-item .disabled.action-icon .icon svg rect[stroke], .nav-link.disabled .icon svg circle[stroke], .nav-item .disabled.action-icon .icon svg circle[stroke], .nav-link.disabled .icon svg polyline[stroke], .nav-item .disabled.action-icon .icon svg polyline[stroke], .nav-link.disabled .icon svg polygon[stroke], .nav-item .disabled.action-icon .icon svg polygon[stroke], .nav-link.disabled .icon svg line[stroke], .nav-item .disabled.action-icon .icon svg line[stroke], .nav-link.disabled .icon svg ellipse[stroke], .nav-item .disabled.action-icon .icon svg ellipse[stroke], .nav-link.disabled .icon svg path[stroke], .nav-item .disabled.action-icon .icon svg path[stroke], .nav-link.disabled .icon svg g[stroke], .nav-item .disabled.action-icon .icon svg g[stroke] {
  stroke: #6c757d;
}
.nav-link.disabled .icon svg rect, .nav-item .disabled.action-icon .icon svg rect, .nav-link.disabled .icon svg circle, .nav-item .disabled.action-icon .icon svg circle, .nav-link.disabled .icon svg polyline, .nav-item .disabled.action-icon .icon svg polyline, .nav-link.disabled .icon svg polygon, .nav-item .disabled.action-icon .icon svg polygon, .nav-link.disabled .icon svg line, .nav-item .disabled.action-icon .icon svg line, .nav-link.disabled .icon svg ellipse, .nav-item .disabled.action-icon .icon svg ellipse, .nav-link.disabled .icon svg path, .nav-item .disabled.action-icon .icon svg path, .nav-link.disabled .icon svg g, .nav-item .disabled.action-icon .icon svg g {
  fill: #6c757d;
}
.nav-link.disabled .icon svg rect[stroke=none], .nav-item .disabled.action-icon .icon svg rect[stroke=none], .nav-link.disabled .icon svg circle[stroke=none], .nav-item .disabled.action-icon .icon svg circle[stroke=none], .nav-link.disabled .icon svg polyline[stroke=none], .nav-item .disabled.action-icon .icon svg polyline[stroke=none], .nav-link.disabled .icon svg polygon[stroke=none], .nav-item .disabled.action-icon .icon svg polygon[stroke=none], .nav-link.disabled .icon svg line[stroke=none], .nav-item .disabled.action-icon .icon svg line[stroke=none], .nav-link.disabled .icon svg ellipse[stroke=none], .nav-item .disabled.action-icon .icon svg ellipse[stroke=none], .nav-link.disabled .icon svg path[stroke=none], .nav-item .disabled.action-icon .icon svg path[stroke=none], .nav-link.disabled .icon svg g[stroke=none], .nav-item .disabled.action-icon .icon svg g[stroke=none] {
  stroke: none;
}
.nav-link.disabled .icon svg rect[fill=none], .nav-item .disabled.action-icon .icon svg rect[fill=none], .nav-link.disabled .icon svg circle[fill=none], .nav-item .disabled.action-icon .icon svg circle[fill=none], .nav-link.disabled .icon svg polyline[fill=none], .nav-item .disabled.action-icon .icon svg polyline[fill=none], .nav-link.disabled .icon svg polygon[fill=none], .nav-item .disabled.action-icon .icon svg polygon[fill=none], .nav-link.disabled .icon svg line[fill=none], .nav-item .disabled.action-icon .icon svg line[fill=none], .nav-link.disabled .icon svg ellipse[fill=none], .nav-item .disabled.action-icon .icon svg ellipse[fill=none], .nav-link.disabled .icon svg path[fill=none], .nav-item .disabled.action-icon .icon svg path[fill=none], .nav-link.disabled .icon svg g[fill=none], .nav-item .disabled.action-icon .icon svg g[fill=none] {
  fill: none;
}
.nav-link.disabled .icon svg g[fill=none] rect, .nav-item .disabled.action-icon .icon svg g[fill=none] rect, .nav-link.disabled .icon svg g[fill=none] circle, .nav-item .disabled.action-icon .icon svg g[fill=none] circle, .nav-link.disabled .icon svg g[fill=none] polyline, .nav-item .disabled.action-icon .icon svg g[fill=none] polyline, .nav-link.disabled .icon svg g[fill=none] polygon, .nav-item .disabled.action-icon .icon svg g[fill=none] polygon, .nav-link.disabled .icon svg g[fill=none] line, .nav-item .disabled.action-icon .icon svg g[fill=none] line, .nav-link.disabled .icon svg g[fill=none] ellipse, .nav-item .disabled.action-icon .icon svg g[fill=none] ellipse, .nav-link.disabled .icon svg g[fill=none] path, .nav-item .disabled.action-icon .icon svg g[fill=none] path {
  fill: none;
}
.badge .icon svg {
  width: 12.75px;
}
.badge-primary .icon svg {
  color: #fff;
}
.badge-primary .icon svg rect[stroke], .badge-primary .icon svg circle[stroke], .badge-primary .icon svg polyline[stroke], .badge-primary .icon svg polygon[stroke], .badge-primary .icon svg line[stroke], .badge-primary .icon svg ellipse[stroke], .badge-primary .icon svg path[stroke], .badge-primary .icon svg g[stroke] {
  stroke: #fff;
}
.badge-primary .icon svg rect, .badge-primary .icon svg circle, .badge-primary .icon svg polyline, .badge-primary .icon svg polygon, .badge-primary .icon svg line, .badge-primary .icon svg ellipse, .badge-primary .icon svg path, .badge-primary .icon svg g {
  fill: #fff;
}
.badge-primary .icon svg rect[stroke=none], .badge-primary .icon svg circle[stroke=none], .badge-primary .icon svg polyline[stroke=none], .badge-primary .icon svg polygon[stroke=none], .badge-primary .icon svg line[stroke=none], .badge-primary .icon svg ellipse[stroke=none], .badge-primary .icon svg path[stroke=none], .badge-primary .icon svg g[stroke=none] {
  stroke: none;
}
.badge-primary .icon svg rect[fill=none], .badge-primary .icon svg circle[fill=none], .badge-primary .icon svg polyline[fill=none], .badge-primary .icon svg polygon[fill=none], .badge-primary .icon svg line[fill=none], .badge-primary .icon svg ellipse[fill=none], .badge-primary .icon svg path[fill=none], .badge-primary .icon svg g[fill=none] {
  fill: none;
}
.badge-primary .icon svg g[fill=none] rect, .badge-primary .icon svg g[fill=none] circle, .badge-primary .icon svg g[fill=none] polyline, .badge-primary .icon svg g[fill=none] polygon, .badge-primary .icon svg g[fill=none] line, .badge-primary .icon svg g[fill=none] ellipse, .badge-primary .icon svg g[fill=none] path {
  fill: none;
}
.badge-secondary .icon svg {
  color: #212529;
}
.badge-secondary .icon svg rect[stroke], .badge-secondary .icon svg circle[stroke], .badge-secondary .icon svg polyline[stroke], .badge-secondary .icon svg polygon[stroke], .badge-secondary .icon svg line[stroke], .badge-secondary .icon svg ellipse[stroke], .badge-secondary .icon svg path[stroke], .badge-secondary .icon svg g[stroke] {
  stroke: #212529;
}
.badge-secondary .icon svg rect, .badge-secondary .icon svg circle, .badge-secondary .icon svg polyline, .badge-secondary .icon svg polygon, .badge-secondary .icon svg line, .badge-secondary .icon svg ellipse, .badge-secondary .icon svg path, .badge-secondary .icon svg g {
  fill: #212529;
}
.badge-secondary .icon svg rect[stroke=none], .badge-secondary .icon svg circle[stroke=none], .badge-secondary .icon svg polyline[stroke=none], .badge-secondary .icon svg polygon[stroke=none], .badge-secondary .icon svg line[stroke=none], .badge-secondary .icon svg ellipse[stroke=none], .badge-secondary .icon svg path[stroke=none], .badge-secondary .icon svg g[stroke=none] {
  stroke: none;
}
.badge-secondary .icon svg rect[fill=none], .badge-secondary .icon svg circle[fill=none], .badge-secondary .icon svg polyline[fill=none], .badge-secondary .icon svg polygon[fill=none], .badge-secondary .icon svg line[fill=none], .badge-secondary .icon svg ellipse[fill=none], .badge-secondary .icon svg path[fill=none], .badge-secondary .icon svg g[fill=none] {
  fill: none;
}
.badge-secondary .icon svg g[fill=none] rect, .badge-secondary .icon svg g[fill=none] circle, .badge-secondary .icon svg g[fill=none] polyline, .badge-secondary .icon svg g[fill=none] polygon, .badge-secondary .icon svg g[fill=none] line, .badge-secondary .icon svg g[fill=none] ellipse, .badge-secondary .icon svg g[fill=none] path {
  fill: none;
}
.badge-success .icon svg {
  color: #fff;
}
.badge-success .icon svg rect[stroke], .badge-success .icon svg circle[stroke], .badge-success .icon svg polyline[stroke], .badge-success .icon svg polygon[stroke], .badge-success .icon svg line[stroke], .badge-success .icon svg ellipse[stroke], .badge-success .icon svg path[stroke], .badge-success .icon svg g[stroke] {
  stroke: #fff;
}
.badge-success .icon svg rect, .badge-success .icon svg circle, .badge-success .icon svg polyline, .badge-success .icon svg polygon, .badge-success .icon svg line, .badge-success .icon svg ellipse, .badge-success .icon svg path, .badge-success .icon svg g {
  fill: #fff;
}
.badge-success .icon svg rect[stroke=none], .badge-success .icon svg circle[stroke=none], .badge-success .icon svg polyline[stroke=none], .badge-success .icon svg polygon[stroke=none], .badge-success .icon svg line[stroke=none], .badge-success .icon svg ellipse[stroke=none], .badge-success .icon svg path[stroke=none], .badge-success .icon svg g[stroke=none] {
  stroke: none;
}
.badge-success .icon svg rect[fill=none], .badge-success .icon svg circle[fill=none], .badge-success .icon svg polyline[fill=none], .badge-success .icon svg polygon[fill=none], .badge-success .icon svg line[fill=none], .badge-success .icon svg ellipse[fill=none], .badge-success .icon svg path[fill=none], .badge-success .icon svg g[fill=none] {
  fill: none;
}
.badge-success .icon svg g[fill=none] rect, .badge-success .icon svg g[fill=none] circle, .badge-success .icon svg g[fill=none] polyline, .badge-success .icon svg g[fill=none] polygon, .badge-success .icon svg g[fill=none] line, .badge-success .icon svg g[fill=none] ellipse, .badge-success .icon svg g[fill=none] path {
  fill: none;
}
.badge-info .icon svg {
  color: #fff;
}
.badge-info .icon svg rect[stroke], .badge-info .icon svg circle[stroke], .badge-info .icon svg polyline[stroke], .badge-info .icon svg polygon[stroke], .badge-info .icon svg line[stroke], .badge-info .icon svg ellipse[stroke], .badge-info .icon svg path[stroke], .badge-info .icon svg g[stroke] {
  stroke: #fff;
}
.badge-info .icon svg rect, .badge-info .icon svg circle, .badge-info .icon svg polyline, .badge-info .icon svg polygon, .badge-info .icon svg line, .badge-info .icon svg ellipse, .badge-info .icon svg path, .badge-info .icon svg g {
  fill: #fff;
}
.badge-info .icon svg rect[stroke=none], .badge-info .icon svg circle[stroke=none], .badge-info .icon svg polyline[stroke=none], .badge-info .icon svg polygon[stroke=none], .badge-info .icon svg line[stroke=none], .badge-info .icon svg ellipse[stroke=none], .badge-info .icon svg path[stroke=none], .badge-info .icon svg g[stroke=none] {
  stroke: none;
}
.badge-info .icon svg rect[fill=none], .badge-info .icon svg circle[fill=none], .badge-info .icon svg polyline[fill=none], .badge-info .icon svg polygon[fill=none], .badge-info .icon svg line[fill=none], .badge-info .icon svg ellipse[fill=none], .badge-info .icon svg path[fill=none], .badge-info .icon svg g[fill=none] {
  fill: none;
}
.badge-info .icon svg g[fill=none] rect, .badge-info .icon svg g[fill=none] circle, .badge-info .icon svg g[fill=none] polyline, .badge-info .icon svg g[fill=none] polygon, .badge-info .icon svg g[fill=none] line, .badge-info .icon svg g[fill=none] ellipse, .badge-info .icon svg g[fill=none] path {
  fill: none;
}
.badge-warning .icon svg {
  color: #212529;
}
.badge-warning .icon svg rect[stroke], .badge-warning .icon svg circle[stroke], .badge-warning .icon svg polyline[stroke], .badge-warning .icon svg polygon[stroke], .badge-warning .icon svg line[stroke], .badge-warning .icon svg ellipse[stroke], .badge-warning .icon svg path[stroke], .badge-warning .icon svg g[stroke] {
  stroke: #212529;
}
.badge-warning .icon svg rect, .badge-warning .icon svg circle, .badge-warning .icon svg polyline, .badge-warning .icon svg polygon, .badge-warning .icon svg line, .badge-warning .icon svg ellipse, .badge-warning .icon svg path, .badge-warning .icon svg g {
  fill: #212529;
}
.badge-warning .icon svg rect[stroke=none], .badge-warning .icon svg circle[stroke=none], .badge-warning .icon svg polyline[stroke=none], .badge-warning .icon svg polygon[stroke=none], .badge-warning .icon svg line[stroke=none], .badge-warning .icon svg ellipse[stroke=none], .badge-warning .icon svg path[stroke=none], .badge-warning .icon svg g[stroke=none] {
  stroke: none;
}
.badge-warning .icon svg rect[fill=none], .badge-warning .icon svg circle[fill=none], .badge-warning .icon svg polyline[fill=none], .badge-warning .icon svg polygon[fill=none], .badge-warning .icon svg line[fill=none], .badge-warning .icon svg ellipse[fill=none], .badge-warning .icon svg path[fill=none], .badge-warning .icon svg g[fill=none] {
  fill: none;
}
.badge-warning .icon svg g[fill=none] rect, .badge-warning .icon svg g[fill=none] circle, .badge-warning .icon svg g[fill=none] polyline, .badge-warning .icon svg g[fill=none] polygon, .badge-warning .icon svg g[fill=none] line, .badge-warning .icon svg g[fill=none] ellipse, .badge-warning .icon svg g[fill=none] path {
  fill: none;
}
.badge-danger .icon svg {
  color: #fff;
}
.badge-danger .icon svg rect[stroke], .badge-danger .icon svg circle[stroke], .badge-danger .icon svg polyline[stroke], .badge-danger .icon svg polygon[stroke], .badge-danger .icon svg line[stroke], .badge-danger .icon svg ellipse[stroke], .badge-danger .icon svg path[stroke], .badge-danger .icon svg g[stroke] {
  stroke: #fff;
}
.badge-danger .icon svg rect, .badge-danger .icon svg circle, .badge-danger .icon svg polyline, .badge-danger .icon svg polygon, .badge-danger .icon svg line, .badge-danger .icon svg ellipse, .badge-danger .icon svg path, .badge-danger .icon svg g {
  fill: #fff;
}
.badge-danger .icon svg rect[stroke=none], .badge-danger .icon svg circle[stroke=none], .badge-danger .icon svg polyline[stroke=none], .badge-danger .icon svg polygon[stroke=none], .badge-danger .icon svg line[stroke=none], .badge-danger .icon svg ellipse[stroke=none], .badge-danger .icon svg path[stroke=none], .badge-danger .icon svg g[stroke=none] {
  stroke: none;
}
.badge-danger .icon svg rect[fill=none], .badge-danger .icon svg circle[fill=none], .badge-danger .icon svg polyline[fill=none], .badge-danger .icon svg polygon[fill=none], .badge-danger .icon svg line[fill=none], .badge-danger .icon svg ellipse[fill=none], .badge-danger .icon svg path[fill=none], .badge-danger .icon svg g[fill=none] {
  fill: none;
}
.badge-danger .icon svg g[fill=none] rect, .badge-danger .icon svg g[fill=none] circle, .badge-danger .icon svg g[fill=none] polyline, .badge-danger .icon svg g[fill=none] polygon, .badge-danger .icon svg g[fill=none] line, .badge-danger .icon svg g[fill=none] ellipse, .badge-danger .icon svg g[fill=none] path {
  fill: none;
}
.badge-light .icon svg {
  color: #212529;
}
.badge-light .icon svg rect[stroke], .badge-light .icon svg circle[stroke], .badge-light .icon svg polyline[stroke], .badge-light .icon svg polygon[stroke], .badge-light .icon svg line[stroke], .badge-light .icon svg ellipse[stroke], .badge-light .icon svg path[stroke], .badge-light .icon svg g[stroke] {
  stroke: #212529;
}
.badge-light .icon svg rect, .badge-light .icon svg circle, .badge-light .icon svg polyline, .badge-light .icon svg polygon, .badge-light .icon svg line, .badge-light .icon svg ellipse, .badge-light .icon svg path, .badge-light .icon svg g {
  fill: #212529;
}
.badge-light .icon svg rect[stroke=none], .badge-light .icon svg circle[stroke=none], .badge-light .icon svg polyline[stroke=none], .badge-light .icon svg polygon[stroke=none], .badge-light .icon svg line[stroke=none], .badge-light .icon svg ellipse[stroke=none], .badge-light .icon svg path[stroke=none], .badge-light .icon svg g[stroke=none] {
  stroke: none;
}
.badge-light .icon svg rect[fill=none], .badge-light .icon svg circle[fill=none], .badge-light .icon svg polyline[fill=none], .badge-light .icon svg polygon[fill=none], .badge-light .icon svg line[fill=none], .badge-light .icon svg ellipse[fill=none], .badge-light .icon svg path[fill=none], .badge-light .icon svg g[fill=none] {
  fill: none;
}
.badge-light .icon svg g[fill=none] rect, .badge-light .icon svg g[fill=none] circle, .badge-light .icon svg g[fill=none] polyline, .badge-light .icon svg g[fill=none] polygon, .badge-light .icon svg g[fill=none] line, .badge-light .icon svg g[fill=none] ellipse, .badge-light .icon svg g[fill=none] path {
  fill: none;
}
.badge-dark .icon svg {
  color: #fff;
}
.badge-dark .icon svg rect[stroke], .badge-dark .icon svg circle[stroke], .badge-dark .icon svg polyline[stroke], .badge-dark .icon svg polygon[stroke], .badge-dark .icon svg line[stroke], .badge-dark .icon svg ellipse[stroke], .badge-dark .icon svg path[stroke], .badge-dark .icon svg g[stroke] {
  stroke: #fff;
}
.badge-dark .icon svg rect, .badge-dark .icon svg circle, .badge-dark .icon svg polyline, .badge-dark .icon svg polygon, .badge-dark .icon svg line, .badge-dark .icon svg ellipse, .badge-dark .icon svg path, .badge-dark .icon svg g {
  fill: #fff;
}
.badge-dark .icon svg rect[stroke=none], .badge-dark .icon svg circle[stroke=none], .badge-dark .icon svg polyline[stroke=none], .badge-dark .icon svg polygon[stroke=none], .badge-dark .icon svg line[stroke=none], .badge-dark .icon svg ellipse[stroke=none], .badge-dark .icon svg path[stroke=none], .badge-dark .icon svg g[stroke=none] {
  stroke: none;
}
.badge-dark .icon svg rect[fill=none], .badge-dark .icon svg circle[fill=none], .badge-dark .icon svg polyline[fill=none], .badge-dark .icon svg polygon[fill=none], .badge-dark .icon svg line[fill=none], .badge-dark .icon svg ellipse[fill=none], .badge-dark .icon svg path[fill=none], .badge-dark .icon svg g[fill=none] {
  fill: none;
}
.badge-dark .icon svg g[fill=none] rect, .badge-dark .icon svg g[fill=none] circle, .badge-dark .icon svg g[fill=none] polyline, .badge-dark .icon svg g[fill=none] polygon, .badge-dark .icon svg g[fill=none] line, .badge-dark .icon svg g[fill=none] ellipse, .badge-dark .icon svg g[fill=none] path {
  fill: none;
}
.btn-primary .icon svg {
  color: #fff;
}
.btn-primary .icon svg rect[stroke], .btn-primary .icon svg circle[stroke], .btn-primary .icon svg polyline[stroke], .btn-primary .icon svg polygon[stroke], .btn-primary .icon svg line[stroke], .btn-primary .icon svg ellipse[stroke], .btn-primary .icon svg path[stroke], .btn-primary .icon svg g[stroke] {
  stroke: #fff;
}
.btn-primary .icon svg rect, .btn-primary .icon svg circle, .btn-primary .icon svg polyline, .btn-primary .icon svg polygon, .btn-primary .icon svg line, .btn-primary .icon svg ellipse, .btn-primary .icon svg path, .btn-primary .icon svg g {
  fill: #fff;
}
.btn-primary .icon svg rect[stroke=none], .btn-primary .icon svg circle[stroke=none], .btn-primary .icon svg polyline[stroke=none], .btn-primary .icon svg polygon[stroke=none], .btn-primary .icon svg line[stroke=none], .btn-primary .icon svg ellipse[stroke=none], .btn-primary .icon svg path[stroke=none], .btn-primary .icon svg g[stroke=none] {
  stroke: none;
}
.btn-primary .icon svg rect[fill=none], .btn-primary .icon svg circle[fill=none], .btn-primary .icon svg polyline[fill=none], .btn-primary .icon svg polygon[fill=none], .btn-primary .icon svg line[fill=none], .btn-primary .icon svg ellipse[fill=none], .btn-primary .icon svg path[fill=none], .btn-primary .icon svg g[fill=none] {
  fill: none;
}
.btn-primary .icon svg g[fill=none] rect, .btn-primary .icon svg g[fill=none] circle, .btn-primary .icon svg g[fill=none] polyline, .btn-primary .icon svg g[fill=none] polygon, .btn-primary .icon svg g[fill=none] line, .btn-primary .icon svg g[fill=none] ellipse, .btn-primary .icon svg g[fill=none] path {
  fill: none;
}
.btn-secondary .icon svg {
  color: #212529;
}
.btn-secondary .icon svg rect[stroke], .btn-secondary .icon svg circle[stroke], .btn-secondary .icon svg polyline[stroke], .btn-secondary .icon svg polygon[stroke], .btn-secondary .icon svg line[stroke], .btn-secondary .icon svg ellipse[stroke], .btn-secondary .icon svg path[stroke], .btn-secondary .icon svg g[stroke] {
  stroke: #212529;
}
.btn-secondary .icon svg rect, .btn-secondary .icon svg circle, .btn-secondary .icon svg polyline, .btn-secondary .icon svg polygon, .btn-secondary .icon svg line, .btn-secondary .icon svg ellipse, .btn-secondary .icon svg path, .btn-secondary .icon svg g {
  fill: #212529;
}
.btn-secondary .icon svg rect[stroke=none], .btn-secondary .icon svg circle[stroke=none], .btn-secondary .icon svg polyline[stroke=none], .btn-secondary .icon svg polygon[stroke=none], .btn-secondary .icon svg line[stroke=none], .btn-secondary .icon svg ellipse[stroke=none], .btn-secondary .icon svg path[stroke=none], .btn-secondary .icon svg g[stroke=none] {
  stroke: none;
}
.btn-secondary .icon svg rect[fill=none], .btn-secondary .icon svg circle[fill=none], .btn-secondary .icon svg polyline[fill=none], .btn-secondary .icon svg polygon[fill=none], .btn-secondary .icon svg line[fill=none], .btn-secondary .icon svg ellipse[fill=none], .btn-secondary .icon svg path[fill=none], .btn-secondary .icon svg g[fill=none] {
  fill: none;
}
.btn-secondary .icon svg g[fill=none] rect, .btn-secondary .icon svg g[fill=none] circle, .btn-secondary .icon svg g[fill=none] polyline, .btn-secondary .icon svg g[fill=none] polygon, .btn-secondary .icon svg g[fill=none] line, .btn-secondary .icon svg g[fill=none] ellipse, .btn-secondary .icon svg g[fill=none] path {
  fill: none;
}
.btn-success .icon svg {
  color: #fff;
}
.btn-success .icon svg rect[stroke], .btn-success .icon svg circle[stroke], .btn-success .icon svg polyline[stroke], .btn-success .icon svg polygon[stroke], .btn-success .icon svg line[stroke], .btn-success .icon svg ellipse[stroke], .btn-success .icon svg path[stroke], .btn-success .icon svg g[stroke] {
  stroke: #fff;
}
.btn-success .icon svg rect, .btn-success .icon svg circle, .btn-success .icon svg polyline, .btn-success .icon svg polygon, .btn-success .icon svg line, .btn-success .icon svg ellipse, .btn-success .icon svg path, .btn-success .icon svg g {
  fill: #fff;
}
.btn-success .icon svg rect[stroke=none], .btn-success .icon svg circle[stroke=none], .btn-success .icon svg polyline[stroke=none], .btn-success .icon svg polygon[stroke=none], .btn-success .icon svg line[stroke=none], .btn-success .icon svg ellipse[stroke=none], .btn-success .icon svg path[stroke=none], .btn-success .icon svg g[stroke=none] {
  stroke: none;
}
.btn-success .icon svg rect[fill=none], .btn-success .icon svg circle[fill=none], .btn-success .icon svg polyline[fill=none], .btn-success .icon svg polygon[fill=none], .btn-success .icon svg line[fill=none], .btn-success .icon svg ellipse[fill=none], .btn-success .icon svg path[fill=none], .btn-success .icon svg g[fill=none] {
  fill: none;
}
.btn-success .icon svg g[fill=none] rect, .btn-success .icon svg g[fill=none] circle, .btn-success .icon svg g[fill=none] polyline, .btn-success .icon svg g[fill=none] polygon, .btn-success .icon svg g[fill=none] line, .btn-success .icon svg g[fill=none] ellipse, .btn-success .icon svg g[fill=none] path {
  fill: none;
}
.btn-info .icon svg {
  color: #fff;
}
.btn-info .icon svg rect[stroke], .btn-info .icon svg circle[stroke], .btn-info .icon svg polyline[stroke], .btn-info .icon svg polygon[stroke], .btn-info .icon svg line[stroke], .btn-info .icon svg ellipse[stroke], .btn-info .icon svg path[stroke], .btn-info .icon svg g[stroke] {
  stroke: #fff;
}
.btn-info .icon svg rect, .btn-info .icon svg circle, .btn-info .icon svg polyline, .btn-info .icon svg polygon, .btn-info .icon svg line, .btn-info .icon svg ellipse, .btn-info .icon svg path, .btn-info .icon svg g {
  fill: #fff;
}
.btn-info .icon svg rect[stroke=none], .btn-info .icon svg circle[stroke=none], .btn-info .icon svg polyline[stroke=none], .btn-info .icon svg polygon[stroke=none], .btn-info .icon svg line[stroke=none], .btn-info .icon svg ellipse[stroke=none], .btn-info .icon svg path[stroke=none], .btn-info .icon svg g[stroke=none] {
  stroke: none;
}
.btn-info .icon svg rect[fill=none], .btn-info .icon svg circle[fill=none], .btn-info .icon svg polyline[fill=none], .btn-info .icon svg polygon[fill=none], .btn-info .icon svg line[fill=none], .btn-info .icon svg ellipse[fill=none], .btn-info .icon svg path[fill=none], .btn-info .icon svg g[fill=none] {
  fill: none;
}
.btn-info .icon svg g[fill=none] rect, .btn-info .icon svg g[fill=none] circle, .btn-info .icon svg g[fill=none] polyline, .btn-info .icon svg g[fill=none] polygon, .btn-info .icon svg g[fill=none] line, .btn-info .icon svg g[fill=none] ellipse, .btn-info .icon svg g[fill=none] path {
  fill: none;
}
.btn-warning .icon svg {
  color: #212529;
}
.btn-warning .icon svg rect[stroke], .btn-warning .icon svg circle[stroke], .btn-warning .icon svg polyline[stroke], .btn-warning .icon svg polygon[stroke], .btn-warning .icon svg line[stroke], .btn-warning .icon svg ellipse[stroke], .btn-warning .icon svg path[stroke], .btn-warning .icon svg g[stroke] {
  stroke: #212529;
}
.btn-warning .icon svg rect, .btn-warning .icon svg circle, .btn-warning .icon svg polyline, .btn-warning .icon svg polygon, .btn-warning .icon svg line, .btn-warning .icon svg ellipse, .btn-warning .icon svg path, .btn-warning .icon svg g {
  fill: #212529;
}
.btn-warning .icon svg rect[stroke=none], .btn-warning .icon svg circle[stroke=none], .btn-warning .icon svg polyline[stroke=none], .btn-warning .icon svg polygon[stroke=none], .btn-warning .icon svg line[stroke=none], .btn-warning .icon svg ellipse[stroke=none], .btn-warning .icon svg path[stroke=none], .btn-warning .icon svg g[stroke=none] {
  stroke: none;
}
.btn-warning .icon svg rect[fill=none], .btn-warning .icon svg circle[fill=none], .btn-warning .icon svg polyline[fill=none], .btn-warning .icon svg polygon[fill=none], .btn-warning .icon svg line[fill=none], .btn-warning .icon svg ellipse[fill=none], .btn-warning .icon svg path[fill=none], .btn-warning .icon svg g[fill=none] {
  fill: none;
}
.btn-warning .icon svg g[fill=none] rect, .btn-warning .icon svg g[fill=none] circle, .btn-warning .icon svg g[fill=none] polyline, .btn-warning .icon svg g[fill=none] polygon, .btn-warning .icon svg g[fill=none] line, .btn-warning .icon svg g[fill=none] ellipse, .btn-warning .icon svg g[fill=none] path {
  fill: none;
}
.btn-danger .icon svg {
  color: #fff;
}
.btn-danger .icon svg rect[stroke], .btn-danger .icon svg circle[stroke], .btn-danger .icon svg polyline[stroke], .btn-danger .icon svg polygon[stroke], .btn-danger .icon svg line[stroke], .btn-danger .icon svg ellipse[stroke], .btn-danger .icon svg path[stroke], .btn-danger .icon svg g[stroke] {
  stroke: #fff;
}
.btn-danger .icon svg rect, .btn-danger .icon svg circle, .btn-danger .icon svg polyline, .btn-danger .icon svg polygon, .btn-danger .icon svg line, .btn-danger .icon svg ellipse, .btn-danger .icon svg path, .btn-danger .icon svg g {
  fill: #fff;
}
.btn-danger .icon svg rect[stroke=none], .btn-danger .icon svg circle[stroke=none], .btn-danger .icon svg polyline[stroke=none], .btn-danger .icon svg polygon[stroke=none], .btn-danger .icon svg line[stroke=none], .btn-danger .icon svg ellipse[stroke=none], .btn-danger .icon svg path[stroke=none], .btn-danger .icon svg g[stroke=none] {
  stroke: none;
}
.btn-danger .icon svg rect[fill=none], .btn-danger .icon svg circle[fill=none], .btn-danger .icon svg polyline[fill=none], .btn-danger .icon svg polygon[fill=none], .btn-danger .icon svg line[fill=none], .btn-danger .icon svg ellipse[fill=none], .btn-danger .icon svg path[fill=none], .btn-danger .icon svg g[fill=none] {
  fill: none;
}
.btn-danger .icon svg g[fill=none] rect, .btn-danger .icon svg g[fill=none] circle, .btn-danger .icon svg g[fill=none] polyline, .btn-danger .icon svg g[fill=none] polygon, .btn-danger .icon svg g[fill=none] line, .btn-danger .icon svg g[fill=none] ellipse, .btn-danger .icon svg g[fill=none] path {
  fill: none;
}
.btn-light .icon svg {
  color: #212529;
}
.btn-light .icon svg rect[stroke], .btn-light .icon svg circle[stroke], .btn-light .icon svg polyline[stroke], .btn-light .icon svg polygon[stroke], .btn-light .icon svg line[stroke], .btn-light .icon svg ellipse[stroke], .btn-light .icon svg path[stroke], .btn-light .icon svg g[stroke] {
  stroke: #212529;
}
.btn-light .icon svg rect, .btn-light .icon svg circle, .btn-light .icon svg polyline, .btn-light .icon svg polygon, .btn-light .icon svg line, .btn-light .icon svg ellipse, .btn-light .icon svg path, .btn-light .icon svg g {
  fill: #212529;
}
.btn-light .icon svg rect[stroke=none], .btn-light .icon svg circle[stroke=none], .btn-light .icon svg polyline[stroke=none], .btn-light .icon svg polygon[stroke=none], .btn-light .icon svg line[stroke=none], .btn-light .icon svg ellipse[stroke=none], .btn-light .icon svg path[stroke=none], .btn-light .icon svg g[stroke=none] {
  stroke: none;
}
.btn-light .icon svg rect[fill=none], .btn-light .icon svg circle[fill=none], .btn-light .icon svg polyline[fill=none], .btn-light .icon svg polygon[fill=none], .btn-light .icon svg line[fill=none], .btn-light .icon svg ellipse[fill=none], .btn-light .icon svg path[fill=none], .btn-light .icon svg g[fill=none] {
  fill: none;
}
.btn-light .icon svg g[fill=none] rect, .btn-light .icon svg g[fill=none] circle, .btn-light .icon svg g[fill=none] polyline, .btn-light .icon svg g[fill=none] polygon, .btn-light .icon svg g[fill=none] line, .btn-light .icon svg g[fill=none] ellipse, .btn-light .icon svg g[fill=none] path {
  fill: none;
}
.btn-dark .icon svg {
  color: #fff;
}
.btn-dark .icon svg rect[stroke], .btn-dark .icon svg circle[stroke], .btn-dark .icon svg polyline[stroke], .btn-dark .icon svg polygon[stroke], .btn-dark .icon svg line[stroke], .btn-dark .icon svg ellipse[stroke], .btn-dark .icon svg path[stroke], .btn-dark .icon svg g[stroke] {
  stroke: #fff;
}
.btn-dark .icon svg rect, .btn-dark .icon svg circle, .btn-dark .icon svg polyline, .btn-dark .icon svg polygon, .btn-dark .icon svg line, .btn-dark .icon svg ellipse, .btn-dark .icon svg path, .btn-dark .icon svg g {
  fill: #fff;
}
.btn-dark .icon svg rect[stroke=none], .btn-dark .icon svg circle[stroke=none], .btn-dark .icon svg polyline[stroke=none], .btn-dark .icon svg polygon[stroke=none], .btn-dark .icon svg line[stroke=none], .btn-dark .icon svg ellipse[stroke=none], .btn-dark .icon svg path[stroke=none], .btn-dark .icon svg g[stroke=none] {
  stroke: none;
}
.btn-dark .icon svg rect[fill=none], .btn-dark .icon svg circle[fill=none], .btn-dark .icon svg polyline[fill=none], .btn-dark .icon svg polygon[fill=none], .btn-dark .icon svg line[fill=none], .btn-dark .icon svg ellipse[fill=none], .btn-dark .icon svg path[fill=none], .btn-dark .icon svg g[fill=none] {
  fill: none;
}
.btn-dark .icon svg g[fill=none] rect, .btn-dark .icon svg g[fill=none] circle, .btn-dark .icon svg g[fill=none] polyline, .btn-dark .icon svg g[fill=none] polygon, .btn-dark .icon svg g[fill=none] line, .btn-dark .icon svg g[fill=none] ellipse, .btn-dark .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-primary .icon svg {
  color: #1c67d8;
}
.btn-outline-primary .icon svg rect[stroke], .btn-outline-primary .icon svg circle[stroke], .btn-outline-primary .icon svg polyline[stroke], .btn-outline-primary .icon svg polygon[stroke], .btn-outline-primary .icon svg line[stroke], .btn-outline-primary .icon svg ellipse[stroke], .btn-outline-primary .icon svg path[stroke], .btn-outline-primary .icon svg g[stroke] {
  stroke: #1c67d8;
}
.btn-outline-primary .icon svg rect, .btn-outline-primary .icon svg circle, .btn-outline-primary .icon svg polyline, .btn-outline-primary .icon svg polygon, .btn-outline-primary .icon svg line, .btn-outline-primary .icon svg ellipse, .btn-outline-primary .icon svg path, .btn-outline-primary .icon svg g {
  fill: #1c67d8;
}
.btn-outline-primary .icon svg rect[stroke=none], .btn-outline-primary .icon svg circle[stroke=none], .btn-outline-primary .icon svg polyline[stroke=none], .btn-outline-primary .icon svg polygon[stroke=none], .btn-outline-primary .icon svg line[stroke=none], .btn-outline-primary .icon svg ellipse[stroke=none], .btn-outline-primary .icon svg path[stroke=none], .btn-outline-primary .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-primary .icon svg rect[fill=none], .btn-outline-primary .icon svg circle[fill=none], .btn-outline-primary .icon svg polyline[fill=none], .btn-outline-primary .icon svg polygon[fill=none], .btn-outline-primary .icon svg line[fill=none], .btn-outline-primary .icon svg ellipse[fill=none], .btn-outline-primary .icon svg path[fill=none], .btn-outline-primary .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-primary .icon svg g[fill=none] rect, .btn-outline-primary .icon svg g[fill=none] circle, .btn-outline-primary .icon svg g[fill=none] polyline, .btn-outline-primary .icon svg g[fill=none] polygon, .btn-outline-primary .icon svg g[fill=none] line, .btn-outline-primary .icon svg g[fill=none] ellipse, .btn-outline-primary .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-primary:hover .icon svg {
  color: #fff;
}
.btn-outline-primary:hover .icon svg rect[stroke], .btn-outline-primary:hover .icon svg circle[stroke], .btn-outline-primary:hover .icon svg polyline[stroke], .btn-outline-primary:hover .icon svg polygon[stroke], .btn-outline-primary:hover .icon svg line[stroke], .btn-outline-primary:hover .icon svg ellipse[stroke], .btn-outline-primary:hover .icon svg path[stroke], .btn-outline-primary:hover .icon svg g[stroke] {
  stroke: #fff;
}
.btn-outline-primary:hover .icon svg rect, .btn-outline-primary:hover .icon svg circle, .btn-outline-primary:hover .icon svg polyline, .btn-outline-primary:hover .icon svg polygon, .btn-outline-primary:hover .icon svg line, .btn-outline-primary:hover .icon svg ellipse, .btn-outline-primary:hover .icon svg path, .btn-outline-primary:hover .icon svg g {
  fill: #fff;
}
.btn-outline-primary:hover .icon svg rect[stroke=none], .btn-outline-primary:hover .icon svg circle[stroke=none], .btn-outline-primary:hover .icon svg polyline[stroke=none], .btn-outline-primary:hover .icon svg polygon[stroke=none], .btn-outline-primary:hover .icon svg line[stroke=none], .btn-outline-primary:hover .icon svg ellipse[stroke=none], .btn-outline-primary:hover .icon svg path[stroke=none], .btn-outline-primary:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-primary:hover .icon svg rect[fill=none], .btn-outline-primary:hover .icon svg circle[fill=none], .btn-outline-primary:hover .icon svg polyline[fill=none], .btn-outline-primary:hover .icon svg polygon[fill=none], .btn-outline-primary:hover .icon svg line[fill=none], .btn-outline-primary:hover .icon svg ellipse[fill=none], .btn-outline-primary:hover .icon svg path[fill=none], .btn-outline-primary:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-primary:hover .icon svg g[fill=none] rect, .btn-outline-primary:hover .icon svg g[fill=none] circle, .btn-outline-primary:hover .icon svg g[fill=none] polyline, .btn-outline-primary:hover .icon svg g[fill=none] polygon, .btn-outline-primary:hover .icon svg g[fill=none] line, .btn-outline-primary:hover .icon svg g[fill=none] ellipse, .btn-outline-primary:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-secondary .icon svg {
  color: #e2e2e2;
}
.btn-outline-secondary .icon svg rect[stroke], .btn-outline-secondary .icon svg circle[stroke], .btn-outline-secondary .icon svg polyline[stroke], .btn-outline-secondary .icon svg polygon[stroke], .btn-outline-secondary .icon svg line[stroke], .btn-outline-secondary .icon svg ellipse[stroke], .btn-outline-secondary .icon svg path[stroke], .btn-outline-secondary .icon svg g[stroke] {
  stroke: #e2e2e2;
}
.btn-outline-secondary .icon svg rect, .btn-outline-secondary .icon svg circle, .btn-outline-secondary .icon svg polyline, .btn-outline-secondary .icon svg polygon, .btn-outline-secondary .icon svg line, .btn-outline-secondary .icon svg ellipse, .btn-outline-secondary .icon svg path, .btn-outline-secondary .icon svg g {
  fill: #e2e2e2;
}
.btn-outline-secondary .icon svg rect[stroke=none], .btn-outline-secondary .icon svg circle[stroke=none], .btn-outline-secondary .icon svg polyline[stroke=none], .btn-outline-secondary .icon svg polygon[stroke=none], .btn-outline-secondary .icon svg line[stroke=none], .btn-outline-secondary .icon svg ellipse[stroke=none], .btn-outline-secondary .icon svg path[stroke=none], .btn-outline-secondary .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-secondary .icon svg rect[fill=none], .btn-outline-secondary .icon svg circle[fill=none], .btn-outline-secondary .icon svg polyline[fill=none], .btn-outline-secondary .icon svg polygon[fill=none], .btn-outline-secondary .icon svg line[fill=none], .btn-outline-secondary .icon svg ellipse[fill=none], .btn-outline-secondary .icon svg path[fill=none], .btn-outline-secondary .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-secondary .icon svg g[fill=none] rect, .btn-outline-secondary .icon svg g[fill=none] circle, .btn-outline-secondary .icon svg g[fill=none] polyline, .btn-outline-secondary .icon svg g[fill=none] polygon, .btn-outline-secondary .icon svg g[fill=none] line, .btn-outline-secondary .icon svg g[fill=none] ellipse, .btn-outline-secondary .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-secondary:hover .icon svg {
  color: #212529;
}
.btn-outline-secondary:hover .icon svg rect[stroke], .btn-outline-secondary:hover .icon svg circle[stroke], .btn-outline-secondary:hover .icon svg polyline[stroke], .btn-outline-secondary:hover .icon svg polygon[stroke], .btn-outline-secondary:hover .icon svg line[stroke], .btn-outline-secondary:hover .icon svg ellipse[stroke], .btn-outline-secondary:hover .icon svg path[stroke], .btn-outline-secondary:hover .icon svg g[stroke] {
  stroke: #212529;
}
.btn-outline-secondary:hover .icon svg rect, .btn-outline-secondary:hover .icon svg circle, .btn-outline-secondary:hover .icon svg polyline, .btn-outline-secondary:hover .icon svg polygon, .btn-outline-secondary:hover .icon svg line, .btn-outline-secondary:hover .icon svg ellipse, .btn-outline-secondary:hover .icon svg path, .btn-outline-secondary:hover .icon svg g {
  fill: #212529;
}
.btn-outline-secondary:hover .icon svg rect[stroke=none], .btn-outline-secondary:hover .icon svg circle[stroke=none], .btn-outline-secondary:hover .icon svg polyline[stroke=none], .btn-outline-secondary:hover .icon svg polygon[stroke=none], .btn-outline-secondary:hover .icon svg line[stroke=none], .btn-outline-secondary:hover .icon svg ellipse[stroke=none], .btn-outline-secondary:hover .icon svg path[stroke=none], .btn-outline-secondary:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-secondary:hover .icon svg rect[fill=none], .btn-outline-secondary:hover .icon svg circle[fill=none], .btn-outline-secondary:hover .icon svg polyline[fill=none], .btn-outline-secondary:hover .icon svg polygon[fill=none], .btn-outline-secondary:hover .icon svg line[fill=none], .btn-outline-secondary:hover .icon svg ellipse[fill=none], .btn-outline-secondary:hover .icon svg path[fill=none], .btn-outline-secondary:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-secondary:hover .icon svg g[fill=none] rect, .btn-outline-secondary:hover .icon svg g[fill=none] circle, .btn-outline-secondary:hover .icon svg g[fill=none] polyline, .btn-outline-secondary:hover .icon svg g[fill=none] polygon, .btn-outline-secondary:hover .icon svg g[fill=none] line, .btn-outline-secondary:hover .icon svg g[fill=none] ellipse, .btn-outline-secondary:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-success .icon svg {
  color: #3eaf64;
}
.btn-outline-success .icon svg rect[stroke], .btn-outline-success .icon svg circle[stroke], .btn-outline-success .icon svg polyline[stroke], .btn-outline-success .icon svg polygon[stroke], .btn-outline-success .icon svg line[stroke], .btn-outline-success .icon svg ellipse[stroke], .btn-outline-success .icon svg path[stroke], .btn-outline-success .icon svg g[stroke] {
  stroke: #3eaf64;
}
.btn-outline-success .icon svg rect, .btn-outline-success .icon svg circle, .btn-outline-success .icon svg polyline, .btn-outline-success .icon svg polygon, .btn-outline-success .icon svg line, .btn-outline-success .icon svg ellipse, .btn-outline-success .icon svg path, .btn-outline-success .icon svg g {
  fill: #3eaf64;
}
.btn-outline-success .icon svg rect[stroke=none], .btn-outline-success .icon svg circle[stroke=none], .btn-outline-success .icon svg polyline[stroke=none], .btn-outline-success .icon svg polygon[stroke=none], .btn-outline-success .icon svg line[stroke=none], .btn-outline-success .icon svg ellipse[stroke=none], .btn-outline-success .icon svg path[stroke=none], .btn-outline-success .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-success .icon svg rect[fill=none], .btn-outline-success .icon svg circle[fill=none], .btn-outline-success .icon svg polyline[fill=none], .btn-outline-success .icon svg polygon[fill=none], .btn-outline-success .icon svg line[fill=none], .btn-outline-success .icon svg ellipse[fill=none], .btn-outline-success .icon svg path[fill=none], .btn-outline-success .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-success .icon svg g[fill=none] rect, .btn-outline-success .icon svg g[fill=none] circle, .btn-outline-success .icon svg g[fill=none] polyline, .btn-outline-success .icon svg g[fill=none] polygon, .btn-outline-success .icon svg g[fill=none] line, .btn-outline-success .icon svg g[fill=none] ellipse, .btn-outline-success .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-success:hover .icon svg {
  color: #fff;
}
.btn-outline-success:hover .icon svg rect[stroke], .btn-outline-success:hover .icon svg circle[stroke], .btn-outline-success:hover .icon svg polyline[stroke], .btn-outline-success:hover .icon svg polygon[stroke], .btn-outline-success:hover .icon svg line[stroke], .btn-outline-success:hover .icon svg ellipse[stroke], .btn-outline-success:hover .icon svg path[stroke], .btn-outline-success:hover .icon svg g[stroke] {
  stroke: #fff;
}
.btn-outline-success:hover .icon svg rect, .btn-outline-success:hover .icon svg circle, .btn-outline-success:hover .icon svg polyline, .btn-outline-success:hover .icon svg polygon, .btn-outline-success:hover .icon svg line, .btn-outline-success:hover .icon svg ellipse, .btn-outline-success:hover .icon svg path, .btn-outline-success:hover .icon svg g {
  fill: #fff;
}
.btn-outline-success:hover .icon svg rect[stroke=none], .btn-outline-success:hover .icon svg circle[stroke=none], .btn-outline-success:hover .icon svg polyline[stroke=none], .btn-outline-success:hover .icon svg polygon[stroke=none], .btn-outline-success:hover .icon svg line[stroke=none], .btn-outline-success:hover .icon svg ellipse[stroke=none], .btn-outline-success:hover .icon svg path[stroke=none], .btn-outline-success:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-success:hover .icon svg rect[fill=none], .btn-outline-success:hover .icon svg circle[fill=none], .btn-outline-success:hover .icon svg polyline[fill=none], .btn-outline-success:hover .icon svg polygon[fill=none], .btn-outline-success:hover .icon svg line[fill=none], .btn-outline-success:hover .icon svg ellipse[fill=none], .btn-outline-success:hover .icon svg path[fill=none], .btn-outline-success:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-success:hover .icon svg g[fill=none] rect, .btn-outline-success:hover .icon svg g[fill=none] circle, .btn-outline-success:hover .icon svg g[fill=none] polyline, .btn-outline-success:hover .icon svg g[fill=none] polygon, .btn-outline-success:hover .icon svg g[fill=none] line, .btn-outline-success:hover .icon svg g[fill=none] ellipse, .btn-outline-success:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-info .icon svg {
  color: #0dcaf0;
}
.btn-outline-info .icon svg rect[stroke], .btn-outline-info .icon svg circle[stroke], .btn-outline-info .icon svg polyline[stroke], .btn-outline-info .icon svg polygon[stroke], .btn-outline-info .icon svg line[stroke], .btn-outline-info .icon svg ellipse[stroke], .btn-outline-info .icon svg path[stroke], .btn-outline-info .icon svg g[stroke] {
  stroke: #0dcaf0;
}
.btn-outline-info .icon svg rect, .btn-outline-info .icon svg circle, .btn-outline-info .icon svg polyline, .btn-outline-info .icon svg polygon, .btn-outline-info .icon svg line, .btn-outline-info .icon svg ellipse, .btn-outline-info .icon svg path, .btn-outline-info .icon svg g {
  fill: #0dcaf0;
}
.btn-outline-info .icon svg rect[stroke=none], .btn-outline-info .icon svg circle[stroke=none], .btn-outline-info .icon svg polyline[stroke=none], .btn-outline-info .icon svg polygon[stroke=none], .btn-outline-info .icon svg line[stroke=none], .btn-outline-info .icon svg ellipse[stroke=none], .btn-outline-info .icon svg path[stroke=none], .btn-outline-info .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-info .icon svg rect[fill=none], .btn-outline-info .icon svg circle[fill=none], .btn-outline-info .icon svg polyline[fill=none], .btn-outline-info .icon svg polygon[fill=none], .btn-outline-info .icon svg line[fill=none], .btn-outline-info .icon svg ellipse[fill=none], .btn-outline-info .icon svg path[fill=none], .btn-outline-info .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-info .icon svg g[fill=none] rect, .btn-outline-info .icon svg g[fill=none] circle, .btn-outline-info .icon svg g[fill=none] polyline, .btn-outline-info .icon svg g[fill=none] polygon, .btn-outline-info .icon svg g[fill=none] line, .btn-outline-info .icon svg g[fill=none] ellipse, .btn-outline-info .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-info:hover .icon svg {
  color: #fff;
}
.btn-outline-info:hover .icon svg rect[stroke], .btn-outline-info:hover .icon svg circle[stroke], .btn-outline-info:hover .icon svg polyline[stroke], .btn-outline-info:hover .icon svg polygon[stroke], .btn-outline-info:hover .icon svg line[stroke], .btn-outline-info:hover .icon svg ellipse[stroke], .btn-outline-info:hover .icon svg path[stroke], .btn-outline-info:hover .icon svg g[stroke] {
  stroke: #fff;
}
.btn-outline-info:hover .icon svg rect, .btn-outline-info:hover .icon svg circle, .btn-outline-info:hover .icon svg polyline, .btn-outline-info:hover .icon svg polygon, .btn-outline-info:hover .icon svg line, .btn-outline-info:hover .icon svg ellipse, .btn-outline-info:hover .icon svg path, .btn-outline-info:hover .icon svg g {
  fill: #fff;
}
.btn-outline-info:hover .icon svg rect[stroke=none], .btn-outline-info:hover .icon svg circle[stroke=none], .btn-outline-info:hover .icon svg polyline[stroke=none], .btn-outline-info:hover .icon svg polygon[stroke=none], .btn-outline-info:hover .icon svg line[stroke=none], .btn-outline-info:hover .icon svg ellipse[stroke=none], .btn-outline-info:hover .icon svg path[stroke=none], .btn-outline-info:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-info:hover .icon svg rect[fill=none], .btn-outline-info:hover .icon svg circle[fill=none], .btn-outline-info:hover .icon svg polyline[fill=none], .btn-outline-info:hover .icon svg polygon[fill=none], .btn-outline-info:hover .icon svg line[fill=none], .btn-outline-info:hover .icon svg ellipse[fill=none], .btn-outline-info:hover .icon svg path[fill=none], .btn-outline-info:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-info:hover .icon svg g[fill=none] rect, .btn-outline-info:hover .icon svg g[fill=none] circle, .btn-outline-info:hover .icon svg g[fill=none] polyline, .btn-outline-info:hover .icon svg g[fill=none] polygon, .btn-outline-info:hover .icon svg g[fill=none] line, .btn-outline-info:hover .icon svg g[fill=none] ellipse, .btn-outline-info:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-warning .icon svg {
  color: rgb(235, 173, 26);
}
.btn-outline-warning .icon svg rect[stroke], .btn-outline-warning .icon svg circle[stroke], .btn-outline-warning .icon svg polyline[stroke], .btn-outline-warning .icon svg polygon[stroke], .btn-outline-warning .icon svg line[stroke], .btn-outline-warning .icon svg ellipse[stroke], .btn-outline-warning .icon svg path[stroke], .btn-outline-warning .icon svg g[stroke] {
  stroke: rgb(235, 173, 26);
}
.btn-outline-warning .icon svg rect, .btn-outline-warning .icon svg circle, .btn-outline-warning .icon svg polyline, .btn-outline-warning .icon svg polygon, .btn-outline-warning .icon svg line, .btn-outline-warning .icon svg ellipse, .btn-outline-warning .icon svg path, .btn-outline-warning .icon svg g {
  fill: rgb(235, 173, 26);
}
.btn-outline-warning .icon svg rect[stroke=none], .btn-outline-warning .icon svg circle[stroke=none], .btn-outline-warning .icon svg polyline[stroke=none], .btn-outline-warning .icon svg polygon[stroke=none], .btn-outline-warning .icon svg line[stroke=none], .btn-outline-warning .icon svg ellipse[stroke=none], .btn-outline-warning .icon svg path[stroke=none], .btn-outline-warning .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-warning .icon svg rect[fill=none], .btn-outline-warning .icon svg circle[fill=none], .btn-outline-warning .icon svg polyline[fill=none], .btn-outline-warning .icon svg polygon[fill=none], .btn-outline-warning .icon svg line[fill=none], .btn-outline-warning .icon svg ellipse[fill=none], .btn-outline-warning .icon svg path[fill=none], .btn-outline-warning .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-warning .icon svg g[fill=none] rect, .btn-outline-warning .icon svg g[fill=none] circle, .btn-outline-warning .icon svg g[fill=none] polyline, .btn-outline-warning .icon svg g[fill=none] polygon, .btn-outline-warning .icon svg g[fill=none] line, .btn-outline-warning .icon svg g[fill=none] ellipse, .btn-outline-warning .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-warning:hover .icon svg {
  color: #212529;
}
.btn-outline-warning:hover .icon svg rect[stroke], .btn-outline-warning:hover .icon svg circle[stroke], .btn-outline-warning:hover .icon svg polyline[stroke], .btn-outline-warning:hover .icon svg polygon[stroke], .btn-outline-warning:hover .icon svg line[stroke], .btn-outline-warning:hover .icon svg ellipse[stroke], .btn-outline-warning:hover .icon svg path[stroke], .btn-outline-warning:hover .icon svg g[stroke] {
  stroke: #212529;
}
.btn-outline-warning:hover .icon svg rect, .btn-outline-warning:hover .icon svg circle, .btn-outline-warning:hover .icon svg polyline, .btn-outline-warning:hover .icon svg polygon, .btn-outline-warning:hover .icon svg line, .btn-outline-warning:hover .icon svg ellipse, .btn-outline-warning:hover .icon svg path, .btn-outline-warning:hover .icon svg g {
  fill: #212529;
}
.btn-outline-warning:hover .icon svg rect[stroke=none], .btn-outline-warning:hover .icon svg circle[stroke=none], .btn-outline-warning:hover .icon svg polyline[stroke=none], .btn-outline-warning:hover .icon svg polygon[stroke=none], .btn-outline-warning:hover .icon svg line[stroke=none], .btn-outline-warning:hover .icon svg ellipse[stroke=none], .btn-outline-warning:hover .icon svg path[stroke=none], .btn-outline-warning:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-warning:hover .icon svg rect[fill=none], .btn-outline-warning:hover .icon svg circle[fill=none], .btn-outline-warning:hover .icon svg polyline[fill=none], .btn-outline-warning:hover .icon svg polygon[fill=none], .btn-outline-warning:hover .icon svg line[fill=none], .btn-outline-warning:hover .icon svg ellipse[fill=none], .btn-outline-warning:hover .icon svg path[fill=none], .btn-outline-warning:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-warning:hover .icon svg g[fill=none] rect, .btn-outline-warning:hover .icon svg g[fill=none] circle, .btn-outline-warning:hover .icon svg g[fill=none] polyline, .btn-outline-warning:hover .icon svg g[fill=none] polygon, .btn-outline-warning:hover .icon svg g[fill=none] line, .btn-outline-warning:hover .icon svg g[fill=none] ellipse, .btn-outline-warning:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-danger .icon svg {
  color: #e23d4b;
}
.btn-outline-danger .icon svg rect[stroke], .btn-outline-danger .icon svg circle[stroke], .btn-outline-danger .icon svg polyline[stroke], .btn-outline-danger .icon svg polygon[stroke], .btn-outline-danger .icon svg line[stroke], .btn-outline-danger .icon svg ellipse[stroke], .btn-outline-danger .icon svg path[stroke], .btn-outline-danger .icon svg g[stroke] {
  stroke: #e23d4b;
}
.btn-outline-danger .icon svg rect, .btn-outline-danger .icon svg circle, .btn-outline-danger .icon svg polyline, .btn-outline-danger .icon svg polygon, .btn-outline-danger .icon svg line, .btn-outline-danger .icon svg ellipse, .btn-outline-danger .icon svg path, .btn-outline-danger .icon svg g {
  fill: #e23d4b;
}
.btn-outline-danger .icon svg rect[stroke=none], .btn-outline-danger .icon svg circle[stroke=none], .btn-outline-danger .icon svg polyline[stroke=none], .btn-outline-danger .icon svg polygon[stroke=none], .btn-outline-danger .icon svg line[stroke=none], .btn-outline-danger .icon svg ellipse[stroke=none], .btn-outline-danger .icon svg path[stroke=none], .btn-outline-danger .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-danger .icon svg rect[fill=none], .btn-outline-danger .icon svg circle[fill=none], .btn-outline-danger .icon svg polyline[fill=none], .btn-outline-danger .icon svg polygon[fill=none], .btn-outline-danger .icon svg line[fill=none], .btn-outline-danger .icon svg ellipse[fill=none], .btn-outline-danger .icon svg path[fill=none], .btn-outline-danger .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-danger .icon svg g[fill=none] rect, .btn-outline-danger .icon svg g[fill=none] circle, .btn-outline-danger .icon svg g[fill=none] polyline, .btn-outline-danger .icon svg g[fill=none] polygon, .btn-outline-danger .icon svg g[fill=none] line, .btn-outline-danger .icon svg g[fill=none] ellipse, .btn-outline-danger .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-danger:hover .icon svg {
  color: #fff;
}
.btn-outline-danger:hover .icon svg rect[stroke], .btn-outline-danger:hover .icon svg circle[stroke], .btn-outline-danger:hover .icon svg polyline[stroke], .btn-outline-danger:hover .icon svg polygon[stroke], .btn-outline-danger:hover .icon svg line[stroke], .btn-outline-danger:hover .icon svg ellipse[stroke], .btn-outline-danger:hover .icon svg path[stroke], .btn-outline-danger:hover .icon svg g[stroke] {
  stroke: #fff;
}
.btn-outline-danger:hover .icon svg rect, .btn-outline-danger:hover .icon svg circle, .btn-outline-danger:hover .icon svg polyline, .btn-outline-danger:hover .icon svg polygon, .btn-outline-danger:hover .icon svg line, .btn-outline-danger:hover .icon svg ellipse, .btn-outline-danger:hover .icon svg path, .btn-outline-danger:hover .icon svg g {
  fill: #fff;
}
.btn-outline-danger:hover .icon svg rect[stroke=none], .btn-outline-danger:hover .icon svg circle[stroke=none], .btn-outline-danger:hover .icon svg polyline[stroke=none], .btn-outline-danger:hover .icon svg polygon[stroke=none], .btn-outline-danger:hover .icon svg line[stroke=none], .btn-outline-danger:hover .icon svg ellipse[stroke=none], .btn-outline-danger:hover .icon svg path[stroke=none], .btn-outline-danger:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-danger:hover .icon svg rect[fill=none], .btn-outline-danger:hover .icon svg circle[fill=none], .btn-outline-danger:hover .icon svg polyline[fill=none], .btn-outline-danger:hover .icon svg polygon[fill=none], .btn-outline-danger:hover .icon svg line[fill=none], .btn-outline-danger:hover .icon svg ellipse[fill=none], .btn-outline-danger:hover .icon svg path[fill=none], .btn-outline-danger:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-danger:hover .icon svg g[fill=none] rect, .btn-outline-danger:hover .icon svg g[fill=none] circle, .btn-outline-danger:hover .icon svg g[fill=none] polyline, .btn-outline-danger:hover .icon svg g[fill=none] polygon, .btn-outline-danger:hover .icon svg g[fill=none] line, .btn-outline-danger:hover .icon svg g[fill=none] ellipse, .btn-outline-danger:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-light .icon svg {
  color: #f8f9fa;
}
.btn-outline-light .icon svg rect[stroke], .btn-outline-light .icon svg circle[stroke], .btn-outline-light .icon svg polyline[stroke], .btn-outline-light .icon svg polygon[stroke], .btn-outline-light .icon svg line[stroke], .btn-outline-light .icon svg ellipse[stroke], .btn-outline-light .icon svg path[stroke], .btn-outline-light .icon svg g[stroke] {
  stroke: #f8f9fa;
}
.btn-outline-light .icon svg rect, .btn-outline-light .icon svg circle, .btn-outline-light .icon svg polyline, .btn-outline-light .icon svg polygon, .btn-outline-light .icon svg line, .btn-outline-light .icon svg ellipse, .btn-outline-light .icon svg path, .btn-outline-light .icon svg g {
  fill: #f8f9fa;
}
.btn-outline-light .icon svg rect[stroke=none], .btn-outline-light .icon svg circle[stroke=none], .btn-outline-light .icon svg polyline[stroke=none], .btn-outline-light .icon svg polygon[stroke=none], .btn-outline-light .icon svg line[stroke=none], .btn-outline-light .icon svg ellipse[stroke=none], .btn-outline-light .icon svg path[stroke=none], .btn-outline-light .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-light .icon svg rect[fill=none], .btn-outline-light .icon svg circle[fill=none], .btn-outline-light .icon svg polyline[fill=none], .btn-outline-light .icon svg polygon[fill=none], .btn-outline-light .icon svg line[fill=none], .btn-outline-light .icon svg ellipse[fill=none], .btn-outline-light .icon svg path[fill=none], .btn-outline-light .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-light .icon svg g[fill=none] rect, .btn-outline-light .icon svg g[fill=none] circle, .btn-outline-light .icon svg g[fill=none] polyline, .btn-outline-light .icon svg g[fill=none] polygon, .btn-outline-light .icon svg g[fill=none] line, .btn-outline-light .icon svg g[fill=none] ellipse, .btn-outline-light .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-light:hover .icon svg {
  color: #212529;
}
.btn-outline-light:hover .icon svg rect[stroke], .btn-outline-light:hover .icon svg circle[stroke], .btn-outline-light:hover .icon svg polyline[stroke], .btn-outline-light:hover .icon svg polygon[stroke], .btn-outline-light:hover .icon svg line[stroke], .btn-outline-light:hover .icon svg ellipse[stroke], .btn-outline-light:hover .icon svg path[stroke], .btn-outline-light:hover .icon svg g[stroke] {
  stroke: #212529;
}
.btn-outline-light:hover .icon svg rect, .btn-outline-light:hover .icon svg circle, .btn-outline-light:hover .icon svg polyline, .btn-outline-light:hover .icon svg polygon, .btn-outline-light:hover .icon svg line, .btn-outline-light:hover .icon svg ellipse, .btn-outline-light:hover .icon svg path, .btn-outline-light:hover .icon svg g {
  fill: #212529;
}
.btn-outline-light:hover .icon svg rect[stroke=none], .btn-outline-light:hover .icon svg circle[stroke=none], .btn-outline-light:hover .icon svg polyline[stroke=none], .btn-outline-light:hover .icon svg polygon[stroke=none], .btn-outline-light:hover .icon svg line[stroke=none], .btn-outline-light:hover .icon svg ellipse[stroke=none], .btn-outline-light:hover .icon svg path[stroke=none], .btn-outline-light:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-light:hover .icon svg rect[fill=none], .btn-outline-light:hover .icon svg circle[fill=none], .btn-outline-light:hover .icon svg polyline[fill=none], .btn-outline-light:hover .icon svg polygon[fill=none], .btn-outline-light:hover .icon svg line[fill=none], .btn-outline-light:hover .icon svg ellipse[fill=none], .btn-outline-light:hover .icon svg path[fill=none], .btn-outline-light:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-light:hover .icon svg g[fill=none] rect, .btn-outline-light:hover .icon svg g[fill=none] circle, .btn-outline-light:hover .icon svg g[fill=none] polyline, .btn-outline-light:hover .icon svg g[fill=none] polygon, .btn-outline-light:hover .icon svg g[fill=none] line, .btn-outline-light:hover .icon svg g[fill=none] ellipse, .btn-outline-light:hover .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-dark .icon svg {
  color: #212529;
}
.btn-outline-dark .icon svg rect[stroke], .btn-outline-dark .icon svg circle[stroke], .btn-outline-dark .icon svg polyline[stroke], .btn-outline-dark .icon svg polygon[stroke], .btn-outline-dark .icon svg line[stroke], .btn-outline-dark .icon svg ellipse[stroke], .btn-outline-dark .icon svg path[stroke], .btn-outline-dark .icon svg g[stroke] {
  stroke: #212529;
}
.btn-outline-dark .icon svg rect, .btn-outline-dark .icon svg circle, .btn-outline-dark .icon svg polyline, .btn-outline-dark .icon svg polygon, .btn-outline-dark .icon svg line, .btn-outline-dark .icon svg ellipse, .btn-outline-dark .icon svg path, .btn-outline-dark .icon svg g {
  fill: #212529;
}
.btn-outline-dark .icon svg rect[stroke=none], .btn-outline-dark .icon svg circle[stroke=none], .btn-outline-dark .icon svg polyline[stroke=none], .btn-outline-dark .icon svg polygon[stroke=none], .btn-outline-dark .icon svg line[stroke=none], .btn-outline-dark .icon svg ellipse[stroke=none], .btn-outline-dark .icon svg path[stroke=none], .btn-outline-dark .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-dark .icon svg rect[fill=none], .btn-outline-dark .icon svg circle[fill=none], .btn-outline-dark .icon svg polyline[fill=none], .btn-outline-dark .icon svg polygon[fill=none], .btn-outline-dark .icon svg line[fill=none], .btn-outline-dark .icon svg ellipse[fill=none], .btn-outline-dark .icon svg path[fill=none], .btn-outline-dark .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-dark .icon svg g[fill=none] rect, .btn-outline-dark .icon svg g[fill=none] circle, .btn-outline-dark .icon svg g[fill=none] polyline, .btn-outline-dark .icon svg g[fill=none] polygon, .btn-outline-dark .icon svg g[fill=none] line, .btn-outline-dark .icon svg g[fill=none] ellipse, .btn-outline-dark .icon svg g[fill=none] path {
  fill: none;
}
.btn-outline-dark:hover .icon svg {
  color: #fff;
}
.btn-outline-dark:hover .icon svg rect[stroke], .btn-outline-dark:hover .icon svg circle[stroke], .btn-outline-dark:hover .icon svg polyline[stroke], .btn-outline-dark:hover .icon svg polygon[stroke], .btn-outline-dark:hover .icon svg line[stroke], .btn-outline-dark:hover .icon svg ellipse[stroke], .btn-outline-dark:hover .icon svg path[stroke], .btn-outline-dark:hover .icon svg g[stroke] {
  stroke: #fff;
}
.btn-outline-dark:hover .icon svg rect, .btn-outline-dark:hover .icon svg circle, .btn-outline-dark:hover .icon svg polyline, .btn-outline-dark:hover .icon svg polygon, .btn-outline-dark:hover .icon svg line, .btn-outline-dark:hover .icon svg ellipse, .btn-outline-dark:hover .icon svg path, .btn-outline-dark:hover .icon svg g {
  fill: #fff;
}
.btn-outline-dark:hover .icon svg rect[stroke=none], .btn-outline-dark:hover .icon svg circle[stroke=none], .btn-outline-dark:hover .icon svg polyline[stroke=none], .btn-outline-dark:hover .icon svg polygon[stroke=none], .btn-outline-dark:hover .icon svg line[stroke=none], .btn-outline-dark:hover .icon svg ellipse[stroke=none], .btn-outline-dark:hover .icon svg path[stroke=none], .btn-outline-dark:hover .icon svg g[stroke=none] {
  stroke: none;
}
.btn-outline-dark:hover .icon svg rect[fill=none], .btn-outline-dark:hover .icon svg circle[fill=none], .btn-outline-dark:hover .icon svg polyline[fill=none], .btn-outline-dark:hover .icon svg polygon[fill=none], .btn-outline-dark:hover .icon svg line[fill=none], .btn-outline-dark:hover .icon svg ellipse[fill=none], .btn-outline-dark:hover .icon svg path[fill=none], .btn-outline-dark:hover .icon svg g[fill=none] {
  fill: none;
}
.btn-outline-dark:hover .icon svg g[fill=none] rect, .btn-outline-dark:hover .icon svg g[fill=none] circle, .btn-outline-dark:hover .icon svg g[fill=none] polyline, .btn-outline-dark:hover .icon svg g[fill=none] polygon, .btn-outline-dark:hover .icon svg g[fill=none] line, .btn-outline-dark:hover .icon svg g[fill=none] ellipse, .btn-outline-dark:hover .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-primary svg, .text-primary .icon svg {
  color: #1c67d8;
}
.icon.text-primary svg rect[stroke], .icon.text-primary svg circle[stroke], .icon.text-primary svg polyline[stroke], .icon.text-primary svg polygon[stroke], .icon.text-primary svg line[stroke], .icon.text-primary svg ellipse[stroke], .icon.text-primary svg path[stroke], .icon.text-primary svg g[stroke], .text-primary .icon svg rect[stroke], .text-primary .icon svg circle[stroke], .text-primary .icon svg polyline[stroke], .text-primary .icon svg polygon[stroke], .text-primary .icon svg line[stroke], .text-primary .icon svg ellipse[stroke], .text-primary .icon svg path[stroke], .text-primary .icon svg g[stroke] {
  stroke: #1c67d8;
}
.icon.text-primary svg rect, .icon.text-primary svg circle, .icon.text-primary svg polyline, .icon.text-primary svg polygon, .icon.text-primary svg line, .icon.text-primary svg ellipse, .icon.text-primary svg path, .icon.text-primary svg g, .text-primary .icon svg rect, .text-primary .icon svg circle, .text-primary .icon svg polyline, .text-primary .icon svg polygon, .text-primary .icon svg line, .text-primary .icon svg ellipse, .text-primary .icon svg path, .text-primary .icon svg g {
  fill: #1c67d8;
}
.icon.text-primary svg rect[stroke=none], .icon.text-primary svg circle[stroke=none], .icon.text-primary svg polyline[stroke=none], .icon.text-primary svg polygon[stroke=none], .icon.text-primary svg line[stroke=none], .icon.text-primary svg ellipse[stroke=none], .icon.text-primary svg path[stroke=none], .icon.text-primary svg g[stroke=none], .text-primary .icon svg rect[stroke=none], .text-primary .icon svg circle[stroke=none], .text-primary .icon svg polyline[stroke=none], .text-primary .icon svg polygon[stroke=none], .text-primary .icon svg line[stroke=none], .text-primary .icon svg ellipse[stroke=none], .text-primary .icon svg path[stroke=none], .text-primary .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-primary svg rect[fill=none], .icon.text-primary svg circle[fill=none], .icon.text-primary svg polyline[fill=none], .icon.text-primary svg polygon[fill=none], .icon.text-primary svg line[fill=none], .icon.text-primary svg ellipse[fill=none], .icon.text-primary svg path[fill=none], .icon.text-primary svg g[fill=none], .text-primary .icon svg rect[fill=none], .text-primary .icon svg circle[fill=none], .text-primary .icon svg polyline[fill=none], .text-primary .icon svg polygon[fill=none], .text-primary .icon svg line[fill=none], .text-primary .icon svg ellipse[fill=none], .text-primary .icon svg path[fill=none], .text-primary .icon svg g[fill=none] {
  fill: none;
}
.icon.text-primary svg g[fill=none] rect, .icon.text-primary svg g[fill=none] circle, .icon.text-primary svg g[fill=none] polyline, .icon.text-primary svg g[fill=none] polygon, .icon.text-primary svg g[fill=none] line, .icon.text-primary svg g[fill=none] ellipse, .icon.text-primary svg g[fill=none] path, .text-primary .icon svg g[fill=none] rect, .text-primary .icon svg g[fill=none] circle, .text-primary .icon svg g[fill=none] polyline, .text-primary .icon svg g[fill=none] polygon, .text-primary .icon svg g[fill=none] line, .text-primary .icon svg g[fill=none] ellipse, .text-primary .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-secondary svg, .text-secondary .icon svg {
  color: #e2e2e2;
}
.icon.text-secondary svg rect[stroke], .icon.text-secondary svg circle[stroke], .icon.text-secondary svg polyline[stroke], .icon.text-secondary svg polygon[stroke], .icon.text-secondary svg line[stroke], .icon.text-secondary svg ellipse[stroke], .icon.text-secondary svg path[stroke], .icon.text-secondary svg g[stroke], .text-secondary .icon svg rect[stroke], .text-secondary .icon svg circle[stroke], .text-secondary .icon svg polyline[stroke], .text-secondary .icon svg polygon[stroke], .text-secondary .icon svg line[stroke], .text-secondary .icon svg ellipse[stroke], .text-secondary .icon svg path[stroke], .text-secondary .icon svg g[stroke] {
  stroke: #e2e2e2;
}
.icon.text-secondary svg rect, .icon.text-secondary svg circle, .icon.text-secondary svg polyline, .icon.text-secondary svg polygon, .icon.text-secondary svg line, .icon.text-secondary svg ellipse, .icon.text-secondary svg path, .icon.text-secondary svg g, .text-secondary .icon svg rect, .text-secondary .icon svg circle, .text-secondary .icon svg polyline, .text-secondary .icon svg polygon, .text-secondary .icon svg line, .text-secondary .icon svg ellipse, .text-secondary .icon svg path, .text-secondary .icon svg g {
  fill: #e2e2e2;
}
.icon.text-secondary svg rect[stroke=none], .icon.text-secondary svg circle[stroke=none], .icon.text-secondary svg polyline[stroke=none], .icon.text-secondary svg polygon[stroke=none], .icon.text-secondary svg line[stroke=none], .icon.text-secondary svg ellipse[stroke=none], .icon.text-secondary svg path[stroke=none], .icon.text-secondary svg g[stroke=none], .text-secondary .icon svg rect[stroke=none], .text-secondary .icon svg circle[stroke=none], .text-secondary .icon svg polyline[stroke=none], .text-secondary .icon svg polygon[stroke=none], .text-secondary .icon svg line[stroke=none], .text-secondary .icon svg ellipse[stroke=none], .text-secondary .icon svg path[stroke=none], .text-secondary .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-secondary svg rect[fill=none], .icon.text-secondary svg circle[fill=none], .icon.text-secondary svg polyline[fill=none], .icon.text-secondary svg polygon[fill=none], .icon.text-secondary svg line[fill=none], .icon.text-secondary svg ellipse[fill=none], .icon.text-secondary svg path[fill=none], .icon.text-secondary svg g[fill=none], .text-secondary .icon svg rect[fill=none], .text-secondary .icon svg circle[fill=none], .text-secondary .icon svg polyline[fill=none], .text-secondary .icon svg polygon[fill=none], .text-secondary .icon svg line[fill=none], .text-secondary .icon svg ellipse[fill=none], .text-secondary .icon svg path[fill=none], .text-secondary .icon svg g[fill=none] {
  fill: none;
}
.icon.text-secondary svg g[fill=none] rect, .icon.text-secondary svg g[fill=none] circle, .icon.text-secondary svg g[fill=none] polyline, .icon.text-secondary svg g[fill=none] polygon, .icon.text-secondary svg g[fill=none] line, .icon.text-secondary svg g[fill=none] ellipse, .icon.text-secondary svg g[fill=none] path, .text-secondary .icon svg g[fill=none] rect, .text-secondary .icon svg g[fill=none] circle, .text-secondary .icon svg g[fill=none] polyline, .text-secondary .icon svg g[fill=none] polygon, .text-secondary .icon svg g[fill=none] line, .text-secondary .icon svg g[fill=none] ellipse, .text-secondary .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-success svg, .text-success .icon svg {
  color: #3eaf64;
}
.icon.text-success svg rect[stroke], .icon.text-success svg circle[stroke], .icon.text-success svg polyline[stroke], .icon.text-success svg polygon[stroke], .icon.text-success svg line[stroke], .icon.text-success svg ellipse[stroke], .icon.text-success svg path[stroke], .icon.text-success svg g[stroke], .text-success .icon svg rect[stroke], .text-success .icon svg circle[stroke], .text-success .icon svg polyline[stroke], .text-success .icon svg polygon[stroke], .text-success .icon svg line[stroke], .text-success .icon svg ellipse[stroke], .text-success .icon svg path[stroke], .text-success .icon svg g[stroke] {
  stroke: #3eaf64;
}
.icon.text-success svg rect, .icon.text-success svg circle, .icon.text-success svg polyline, .icon.text-success svg polygon, .icon.text-success svg line, .icon.text-success svg ellipse, .icon.text-success svg path, .icon.text-success svg g, .text-success .icon svg rect, .text-success .icon svg circle, .text-success .icon svg polyline, .text-success .icon svg polygon, .text-success .icon svg line, .text-success .icon svg ellipse, .text-success .icon svg path, .text-success .icon svg g {
  fill: #3eaf64;
}
.icon.text-success svg rect[stroke=none], .icon.text-success svg circle[stroke=none], .icon.text-success svg polyline[stroke=none], .icon.text-success svg polygon[stroke=none], .icon.text-success svg line[stroke=none], .icon.text-success svg ellipse[stroke=none], .icon.text-success svg path[stroke=none], .icon.text-success svg g[stroke=none], .text-success .icon svg rect[stroke=none], .text-success .icon svg circle[stroke=none], .text-success .icon svg polyline[stroke=none], .text-success .icon svg polygon[stroke=none], .text-success .icon svg line[stroke=none], .text-success .icon svg ellipse[stroke=none], .text-success .icon svg path[stroke=none], .text-success .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-success svg rect[fill=none], .icon.text-success svg circle[fill=none], .icon.text-success svg polyline[fill=none], .icon.text-success svg polygon[fill=none], .icon.text-success svg line[fill=none], .icon.text-success svg ellipse[fill=none], .icon.text-success svg path[fill=none], .icon.text-success svg g[fill=none], .text-success .icon svg rect[fill=none], .text-success .icon svg circle[fill=none], .text-success .icon svg polyline[fill=none], .text-success .icon svg polygon[fill=none], .text-success .icon svg line[fill=none], .text-success .icon svg ellipse[fill=none], .text-success .icon svg path[fill=none], .text-success .icon svg g[fill=none] {
  fill: none;
}
.icon.text-success svg g[fill=none] rect, .icon.text-success svg g[fill=none] circle, .icon.text-success svg g[fill=none] polyline, .icon.text-success svg g[fill=none] polygon, .icon.text-success svg g[fill=none] line, .icon.text-success svg g[fill=none] ellipse, .icon.text-success svg g[fill=none] path, .text-success .icon svg g[fill=none] rect, .text-success .icon svg g[fill=none] circle, .text-success .icon svg g[fill=none] polyline, .text-success .icon svg g[fill=none] polygon, .text-success .icon svg g[fill=none] line, .text-success .icon svg g[fill=none] ellipse, .text-success .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-info svg, .text-info .icon svg {
  color: #0dcaf0;
}
.icon.text-info svg rect[stroke], .icon.text-info svg circle[stroke], .icon.text-info svg polyline[stroke], .icon.text-info svg polygon[stroke], .icon.text-info svg line[stroke], .icon.text-info svg ellipse[stroke], .icon.text-info svg path[stroke], .icon.text-info svg g[stroke], .text-info .icon svg rect[stroke], .text-info .icon svg circle[stroke], .text-info .icon svg polyline[stroke], .text-info .icon svg polygon[stroke], .text-info .icon svg line[stroke], .text-info .icon svg ellipse[stroke], .text-info .icon svg path[stroke], .text-info .icon svg g[stroke] {
  stroke: #0dcaf0;
}
.icon.text-info svg rect, .icon.text-info svg circle, .icon.text-info svg polyline, .icon.text-info svg polygon, .icon.text-info svg line, .icon.text-info svg ellipse, .icon.text-info svg path, .icon.text-info svg g, .text-info .icon svg rect, .text-info .icon svg circle, .text-info .icon svg polyline, .text-info .icon svg polygon, .text-info .icon svg line, .text-info .icon svg ellipse, .text-info .icon svg path, .text-info .icon svg g {
  fill: #0dcaf0;
}
.icon.text-info svg rect[stroke=none], .icon.text-info svg circle[stroke=none], .icon.text-info svg polyline[stroke=none], .icon.text-info svg polygon[stroke=none], .icon.text-info svg line[stroke=none], .icon.text-info svg ellipse[stroke=none], .icon.text-info svg path[stroke=none], .icon.text-info svg g[stroke=none], .text-info .icon svg rect[stroke=none], .text-info .icon svg circle[stroke=none], .text-info .icon svg polyline[stroke=none], .text-info .icon svg polygon[stroke=none], .text-info .icon svg line[stroke=none], .text-info .icon svg ellipse[stroke=none], .text-info .icon svg path[stroke=none], .text-info .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-info svg rect[fill=none], .icon.text-info svg circle[fill=none], .icon.text-info svg polyline[fill=none], .icon.text-info svg polygon[fill=none], .icon.text-info svg line[fill=none], .icon.text-info svg ellipse[fill=none], .icon.text-info svg path[fill=none], .icon.text-info svg g[fill=none], .text-info .icon svg rect[fill=none], .text-info .icon svg circle[fill=none], .text-info .icon svg polyline[fill=none], .text-info .icon svg polygon[fill=none], .text-info .icon svg line[fill=none], .text-info .icon svg ellipse[fill=none], .text-info .icon svg path[fill=none], .text-info .icon svg g[fill=none] {
  fill: none;
}
.icon.text-info svg g[fill=none] rect, .icon.text-info svg g[fill=none] circle, .icon.text-info svg g[fill=none] polyline, .icon.text-info svg g[fill=none] polygon, .icon.text-info svg g[fill=none] line, .icon.text-info svg g[fill=none] ellipse, .icon.text-info svg g[fill=none] path, .text-info .icon svg g[fill=none] rect, .text-info .icon svg g[fill=none] circle, .text-info .icon svg g[fill=none] polyline, .text-info .icon svg g[fill=none] polygon, .text-info .icon svg g[fill=none] line, .text-info .icon svg g[fill=none] ellipse, .text-info .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-warning svg, .text-warning .icon svg {
  color: rgb(235, 173, 26);
}
.icon.text-warning svg rect[stroke], .icon.text-warning svg circle[stroke], .icon.text-warning svg polyline[stroke], .icon.text-warning svg polygon[stroke], .icon.text-warning svg line[stroke], .icon.text-warning svg ellipse[stroke], .icon.text-warning svg path[stroke], .icon.text-warning svg g[stroke], .text-warning .icon svg rect[stroke], .text-warning .icon svg circle[stroke], .text-warning .icon svg polyline[stroke], .text-warning .icon svg polygon[stroke], .text-warning .icon svg line[stroke], .text-warning .icon svg ellipse[stroke], .text-warning .icon svg path[stroke], .text-warning .icon svg g[stroke] {
  stroke: rgb(235, 173, 26);
}
.icon.text-warning svg rect, .icon.text-warning svg circle, .icon.text-warning svg polyline, .icon.text-warning svg polygon, .icon.text-warning svg line, .icon.text-warning svg ellipse, .icon.text-warning svg path, .icon.text-warning svg g, .text-warning .icon svg rect, .text-warning .icon svg circle, .text-warning .icon svg polyline, .text-warning .icon svg polygon, .text-warning .icon svg line, .text-warning .icon svg ellipse, .text-warning .icon svg path, .text-warning .icon svg g {
  fill: rgb(235, 173, 26);
}
.icon.text-warning svg rect[stroke=none], .icon.text-warning svg circle[stroke=none], .icon.text-warning svg polyline[stroke=none], .icon.text-warning svg polygon[stroke=none], .icon.text-warning svg line[stroke=none], .icon.text-warning svg ellipse[stroke=none], .icon.text-warning svg path[stroke=none], .icon.text-warning svg g[stroke=none], .text-warning .icon svg rect[stroke=none], .text-warning .icon svg circle[stroke=none], .text-warning .icon svg polyline[stroke=none], .text-warning .icon svg polygon[stroke=none], .text-warning .icon svg line[stroke=none], .text-warning .icon svg ellipse[stroke=none], .text-warning .icon svg path[stroke=none], .text-warning .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-warning svg rect[fill=none], .icon.text-warning svg circle[fill=none], .icon.text-warning svg polyline[fill=none], .icon.text-warning svg polygon[fill=none], .icon.text-warning svg line[fill=none], .icon.text-warning svg ellipse[fill=none], .icon.text-warning svg path[fill=none], .icon.text-warning svg g[fill=none], .text-warning .icon svg rect[fill=none], .text-warning .icon svg circle[fill=none], .text-warning .icon svg polyline[fill=none], .text-warning .icon svg polygon[fill=none], .text-warning .icon svg line[fill=none], .text-warning .icon svg ellipse[fill=none], .text-warning .icon svg path[fill=none], .text-warning .icon svg g[fill=none] {
  fill: none;
}
.icon.text-warning svg g[fill=none] rect, .icon.text-warning svg g[fill=none] circle, .icon.text-warning svg g[fill=none] polyline, .icon.text-warning svg g[fill=none] polygon, .icon.text-warning svg g[fill=none] line, .icon.text-warning svg g[fill=none] ellipse, .icon.text-warning svg g[fill=none] path, .text-warning .icon svg g[fill=none] rect, .text-warning .icon svg g[fill=none] circle, .text-warning .icon svg g[fill=none] polyline, .text-warning .icon svg g[fill=none] polygon, .text-warning .icon svg g[fill=none] line, .text-warning .icon svg g[fill=none] ellipse, .text-warning .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-danger svg, .text-danger .icon svg {
  color: #e23d4b;
}
.icon.text-danger svg rect[stroke], .icon.text-danger svg circle[stroke], .icon.text-danger svg polyline[stroke], .icon.text-danger svg polygon[stroke], .icon.text-danger svg line[stroke], .icon.text-danger svg ellipse[stroke], .icon.text-danger svg path[stroke], .icon.text-danger svg g[stroke], .text-danger .icon svg rect[stroke], .text-danger .icon svg circle[stroke], .text-danger .icon svg polyline[stroke], .text-danger .icon svg polygon[stroke], .text-danger .icon svg line[stroke], .text-danger .icon svg ellipse[stroke], .text-danger .icon svg path[stroke], .text-danger .icon svg g[stroke] {
  stroke: #e23d4b;
}
.icon.text-danger svg rect, .icon.text-danger svg circle, .icon.text-danger svg polyline, .icon.text-danger svg polygon, .icon.text-danger svg line, .icon.text-danger svg ellipse, .icon.text-danger svg path, .icon.text-danger svg g, .text-danger .icon svg rect, .text-danger .icon svg circle, .text-danger .icon svg polyline, .text-danger .icon svg polygon, .text-danger .icon svg line, .text-danger .icon svg ellipse, .text-danger .icon svg path, .text-danger .icon svg g {
  fill: #e23d4b;
}
.icon.text-danger svg rect[stroke=none], .icon.text-danger svg circle[stroke=none], .icon.text-danger svg polyline[stroke=none], .icon.text-danger svg polygon[stroke=none], .icon.text-danger svg line[stroke=none], .icon.text-danger svg ellipse[stroke=none], .icon.text-danger svg path[stroke=none], .icon.text-danger svg g[stroke=none], .text-danger .icon svg rect[stroke=none], .text-danger .icon svg circle[stroke=none], .text-danger .icon svg polyline[stroke=none], .text-danger .icon svg polygon[stroke=none], .text-danger .icon svg line[stroke=none], .text-danger .icon svg ellipse[stroke=none], .text-danger .icon svg path[stroke=none], .text-danger .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-danger svg rect[fill=none], .icon.text-danger svg circle[fill=none], .icon.text-danger svg polyline[fill=none], .icon.text-danger svg polygon[fill=none], .icon.text-danger svg line[fill=none], .icon.text-danger svg ellipse[fill=none], .icon.text-danger svg path[fill=none], .icon.text-danger svg g[fill=none], .text-danger .icon svg rect[fill=none], .text-danger .icon svg circle[fill=none], .text-danger .icon svg polyline[fill=none], .text-danger .icon svg polygon[fill=none], .text-danger .icon svg line[fill=none], .text-danger .icon svg ellipse[fill=none], .text-danger .icon svg path[fill=none], .text-danger .icon svg g[fill=none] {
  fill: none;
}
.icon.text-danger svg g[fill=none] rect, .icon.text-danger svg g[fill=none] circle, .icon.text-danger svg g[fill=none] polyline, .icon.text-danger svg g[fill=none] polygon, .icon.text-danger svg g[fill=none] line, .icon.text-danger svg g[fill=none] ellipse, .icon.text-danger svg g[fill=none] path, .text-danger .icon svg g[fill=none] rect, .text-danger .icon svg g[fill=none] circle, .text-danger .icon svg g[fill=none] polyline, .text-danger .icon svg g[fill=none] polygon, .text-danger .icon svg g[fill=none] line, .text-danger .icon svg g[fill=none] ellipse, .text-danger .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-light svg, .text-light .icon svg {
  color: #f8f9fa;
}
.icon.text-light svg rect[stroke], .icon.text-light svg circle[stroke], .icon.text-light svg polyline[stroke], .icon.text-light svg polygon[stroke], .icon.text-light svg line[stroke], .icon.text-light svg ellipse[stroke], .icon.text-light svg path[stroke], .icon.text-light svg g[stroke], .text-light .icon svg rect[stroke], .text-light .icon svg circle[stroke], .text-light .icon svg polyline[stroke], .text-light .icon svg polygon[stroke], .text-light .icon svg line[stroke], .text-light .icon svg ellipse[stroke], .text-light .icon svg path[stroke], .text-light .icon svg g[stroke] {
  stroke: #f8f9fa;
}
.icon.text-light svg rect, .icon.text-light svg circle, .icon.text-light svg polyline, .icon.text-light svg polygon, .icon.text-light svg line, .icon.text-light svg ellipse, .icon.text-light svg path, .icon.text-light svg g, .text-light .icon svg rect, .text-light .icon svg circle, .text-light .icon svg polyline, .text-light .icon svg polygon, .text-light .icon svg line, .text-light .icon svg ellipse, .text-light .icon svg path, .text-light .icon svg g {
  fill: #f8f9fa;
}
.icon.text-light svg rect[stroke=none], .icon.text-light svg circle[stroke=none], .icon.text-light svg polyline[stroke=none], .icon.text-light svg polygon[stroke=none], .icon.text-light svg line[stroke=none], .icon.text-light svg ellipse[stroke=none], .icon.text-light svg path[stroke=none], .icon.text-light svg g[stroke=none], .text-light .icon svg rect[stroke=none], .text-light .icon svg circle[stroke=none], .text-light .icon svg polyline[stroke=none], .text-light .icon svg polygon[stroke=none], .text-light .icon svg line[stroke=none], .text-light .icon svg ellipse[stroke=none], .text-light .icon svg path[stroke=none], .text-light .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-light svg rect[fill=none], .icon.text-light svg circle[fill=none], .icon.text-light svg polyline[fill=none], .icon.text-light svg polygon[fill=none], .icon.text-light svg line[fill=none], .icon.text-light svg ellipse[fill=none], .icon.text-light svg path[fill=none], .icon.text-light svg g[fill=none], .text-light .icon svg rect[fill=none], .text-light .icon svg circle[fill=none], .text-light .icon svg polyline[fill=none], .text-light .icon svg polygon[fill=none], .text-light .icon svg line[fill=none], .text-light .icon svg ellipse[fill=none], .text-light .icon svg path[fill=none], .text-light .icon svg g[fill=none] {
  fill: none;
}
.icon.text-light svg g[fill=none] rect, .icon.text-light svg g[fill=none] circle, .icon.text-light svg g[fill=none] polyline, .icon.text-light svg g[fill=none] polygon, .icon.text-light svg g[fill=none] line, .icon.text-light svg g[fill=none] ellipse, .icon.text-light svg g[fill=none] path, .text-light .icon svg g[fill=none] rect, .text-light .icon svg g[fill=none] circle, .text-light .icon svg g[fill=none] polyline, .text-light .icon svg g[fill=none] polygon, .text-light .icon svg g[fill=none] line, .text-light .icon svg g[fill=none] ellipse, .text-light .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-dark svg, .text-dark .icon svg {
  color: #212529;
}
.icon.text-dark svg rect[stroke], .icon.text-dark svg circle[stroke], .icon.text-dark svg polyline[stroke], .icon.text-dark svg polygon[stroke], .icon.text-dark svg line[stroke], .icon.text-dark svg ellipse[stroke], .icon.text-dark svg path[stroke], .icon.text-dark svg g[stroke], .text-dark .icon svg rect[stroke], .text-dark .icon svg circle[stroke], .text-dark .icon svg polyline[stroke], .text-dark .icon svg polygon[stroke], .text-dark .icon svg line[stroke], .text-dark .icon svg ellipse[stroke], .text-dark .icon svg path[stroke], .text-dark .icon svg g[stroke] {
  stroke: #212529;
}
.icon.text-dark svg rect, .icon.text-dark svg circle, .icon.text-dark svg polyline, .icon.text-dark svg polygon, .icon.text-dark svg line, .icon.text-dark svg ellipse, .icon.text-dark svg path, .icon.text-dark svg g, .text-dark .icon svg rect, .text-dark .icon svg circle, .text-dark .icon svg polyline, .text-dark .icon svg polygon, .text-dark .icon svg line, .text-dark .icon svg ellipse, .text-dark .icon svg path, .text-dark .icon svg g {
  fill: #212529;
}
.icon.text-dark svg rect[stroke=none], .icon.text-dark svg circle[stroke=none], .icon.text-dark svg polyline[stroke=none], .icon.text-dark svg polygon[stroke=none], .icon.text-dark svg line[stroke=none], .icon.text-dark svg ellipse[stroke=none], .icon.text-dark svg path[stroke=none], .icon.text-dark svg g[stroke=none], .text-dark .icon svg rect[stroke=none], .text-dark .icon svg circle[stroke=none], .text-dark .icon svg polyline[stroke=none], .text-dark .icon svg polygon[stroke=none], .text-dark .icon svg line[stroke=none], .text-dark .icon svg ellipse[stroke=none], .text-dark .icon svg path[stroke=none], .text-dark .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-dark svg rect[fill=none], .icon.text-dark svg circle[fill=none], .icon.text-dark svg polyline[fill=none], .icon.text-dark svg polygon[fill=none], .icon.text-dark svg line[fill=none], .icon.text-dark svg ellipse[fill=none], .icon.text-dark svg path[fill=none], .icon.text-dark svg g[fill=none], .text-dark .icon svg rect[fill=none], .text-dark .icon svg circle[fill=none], .text-dark .icon svg polyline[fill=none], .text-dark .icon svg polygon[fill=none], .text-dark .icon svg line[fill=none], .text-dark .icon svg ellipse[fill=none], .text-dark .icon svg path[fill=none], .text-dark .icon svg g[fill=none] {
  fill: none;
}
.icon.text-dark svg g[fill=none] rect, .icon.text-dark svg g[fill=none] circle, .icon.text-dark svg g[fill=none] polyline, .icon.text-dark svg g[fill=none] polygon, .icon.text-dark svg g[fill=none] line, .icon.text-dark svg g[fill=none] ellipse, .icon.text-dark svg g[fill=none] path, .text-dark .icon svg g[fill=none] rect, .text-dark .icon svg g[fill=none] circle, .text-dark .icon svg g[fill=none] polyline, .text-dark .icon svg g[fill=none] polygon, .text-dark .icon svg g[fill=none] line, .text-dark .icon svg g[fill=none] ellipse, .text-dark .icon svg g[fill=none] path {
  fill: none;
}

.icon.text-muted svg, .text-muted .icon svg {
  color: #6c757d;
}
.icon.text-muted svg rect[stroke], .icon.text-muted svg circle[stroke], .icon.text-muted svg polyline[stroke], .icon.text-muted svg polygon[stroke], .icon.text-muted svg line[stroke], .icon.text-muted svg ellipse[stroke], .icon.text-muted svg path[stroke], .icon.text-muted svg g[stroke], .text-muted .icon svg rect[stroke], .text-muted .icon svg circle[stroke], .text-muted .icon svg polyline[stroke], .text-muted .icon svg polygon[stroke], .text-muted .icon svg line[stroke], .text-muted .icon svg ellipse[stroke], .text-muted .icon svg path[stroke], .text-muted .icon svg g[stroke] {
  stroke: #6c757d;
}
.icon.text-muted svg rect, .icon.text-muted svg circle, .icon.text-muted svg polyline, .icon.text-muted svg polygon, .icon.text-muted svg line, .icon.text-muted svg ellipse, .icon.text-muted svg path, .icon.text-muted svg g, .text-muted .icon svg rect, .text-muted .icon svg circle, .text-muted .icon svg polyline, .text-muted .icon svg polygon, .text-muted .icon svg line, .text-muted .icon svg ellipse, .text-muted .icon svg path, .text-muted .icon svg g {
  fill: #6c757d;
}
.icon.text-muted svg rect[stroke=none], .icon.text-muted svg circle[stroke=none], .icon.text-muted svg polyline[stroke=none], .icon.text-muted svg polygon[stroke=none], .icon.text-muted svg line[stroke=none], .icon.text-muted svg ellipse[stroke=none], .icon.text-muted svg path[stroke=none], .icon.text-muted svg g[stroke=none], .text-muted .icon svg rect[stroke=none], .text-muted .icon svg circle[stroke=none], .text-muted .icon svg polyline[stroke=none], .text-muted .icon svg polygon[stroke=none], .text-muted .icon svg line[stroke=none], .text-muted .icon svg ellipse[stroke=none], .text-muted .icon svg path[stroke=none], .text-muted .icon svg g[stroke=none] {
  stroke: none;
}
.icon.text-muted svg rect[fill=none], .icon.text-muted svg circle[fill=none], .icon.text-muted svg polyline[fill=none], .icon.text-muted svg polygon[fill=none], .icon.text-muted svg line[fill=none], .icon.text-muted svg ellipse[fill=none], .icon.text-muted svg path[fill=none], .icon.text-muted svg g[fill=none], .text-muted .icon svg rect[fill=none], .text-muted .icon svg circle[fill=none], .text-muted .icon svg polyline[fill=none], .text-muted .icon svg polygon[fill=none], .text-muted .icon svg line[fill=none], .text-muted .icon svg ellipse[fill=none], .text-muted .icon svg path[fill=none], .text-muted .icon svg g[fill=none] {
  fill: none;
}
.icon.text-muted svg g[fill=none] rect, .icon.text-muted svg g[fill=none] circle, .icon.text-muted svg g[fill=none] polyline, .icon.text-muted svg g[fill=none] polygon, .icon.text-muted svg g[fill=none] line, .icon.text-muted svg g[fill=none] ellipse, .icon.text-muted svg g[fill=none] path, .text-muted .icon svg g[fill=none] rect, .text-muted .icon svg g[fill=none] circle, .text-muted .icon svg g[fill=none] polyline, .text-muted .icon svg g[fill=none] polygon, .text-muted .icon svg g[fill=none] line, .text-muted .icon svg g[fill=none] ellipse, .text-muted .icon svg g[fill=none] path {
  fill: none;
}

.action-icon {
  cursor: pointer;
}

.action-icon.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

input,
textarea {
  -webkit-user-select: text;
}

.bookview-tabs {
  background-color: #e9ecef;
}

.bookview-toggle-container {
  position: absolute;
  padding: 0.2rem 0.15rem;
  top: 0;
  left: 0;
  z-index: 1044;
}

.bookview {
  position: relative;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px var(--accentColour);
  }
  40% {
    box-shadow: 0 0 20px var(--accentColour);
  }
  60% {
    box-shadow: 0 0 20px var(--accentColour);
  }
  100% {
    box-shadow: 0 0 -10px var(--accentColour);
  }
}
.glow {
  animation: glowing 3000ms infinite;
  border-radius: 10px;
}

.btn-round {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  padding: 0.9rem;
  text-align: center;
  vertical-align: middle;
  box-sizing: content-box;
}

.btn-fab {
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.9rem;
  z-index: 1049;
}
.btn-fab svg {
  height: 100%;
  width: 100%;
}

.navbar-light .icon {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-light .icon rect[stroke], .navbar-light .icon circle[stroke], .navbar-light .icon polyline[stroke], .navbar-light .icon polygon[stroke], .navbar-light .icon line[stroke], .navbar-light .icon ellipse[stroke], .navbar-light .icon path[stroke], .navbar-light .icon g[stroke] {
  stroke: rgba(0, 0, 0, 0.6);
}
.navbar-light .icon rect, .navbar-light .icon circle, .navbar-light .icon polyline, .navbar-light .icon polygon, .navbar-light .icon line, .navbar-light .icon ellipse, .navbar-light .icon path, .navbar-light .icon g {
  fill: rgba(0, 0, 0, 0.6);
}
.navbar-light .icon rect[stroke=none], .navbar-light .icon circle[stroke=none], .navbar-light .icon polyline[stroke=none], .navbar-light .icon polygon[stroke=none], .navbar-light .icon line[stroke=none], .navbar-light .icon ellipse[stroke=none], .navbar-light .icon path[stroke=none], .navbar-light .icon g[stroke=none] {
  stroke: none;
}
.navbar-light .icon rect[fill=none], .navbar-light .icon circle[fill=none], .navbar-light .icon polyline[fill=none], .navbar-light .icon polygon[fill=none], .navbar-light .icon line[fill=none], .navbar-light .icon ellipse[fill=none], .navbar-light .icon path[fill=none], .navbar-light .icon g[fill=none] {
  fill: none;
}
.navbar-light .icon g[fill=none] rect, .navbar-light .icon g[fill=none] circle, .navbar-light .icon g[fill=none] polyline, .navbar-light .icon g[fill=none] polygon, .navbar-light .icon g[fill=none] line, .navbar-light .icon g[fill=none] ellipse, .navbar-light .icon g[fill=none] path {
  fill: none;
}
.navbar-light .navbar-nav .nav-link .icon svg, .navbar-light .navbar-nav .nav-item .action-icon .icon svg, .nav-item .navbar-light .navbar-nav .action-icon .icon svg {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-light .navbar-nav .nav-link .icon svg rect[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg rect[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg rect[stroke], .navbar-light .navbar-nav .nav-link .icon svg circle[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg circle[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg circle[stroke], .navbar-light .navbar-nav .nav-link .icon svg polyline[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg polyline[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg polyline[stroke], .navbar-light .navbar-nav .nav-link .icon svg polygon[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg polygon[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg polygon[stroke], .navbar-light .navbar-nav .nav-link .icon svg line[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg line[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg line[stroke], .navbar-light .navbar-nav .nav-link .icon svg ellipse[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg ellipse[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg ellipse[stroke], .navbar-light .navbar-nav .nav-link .icon svg path[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg path[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg path[stroke], .navbar-light .navbar-nav .nav-link .icon svg g[stroke], .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[stroke], .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[stroke] {
  stroke: rgba(0, 0, 0, 0.6);
}
.navbar-light .navbar-nav .nav-link .icon svg rect, .navbar-light .navbar-nav .nav-item .action-icon .icon svg rect, .nav-item .navbar-light .navbar-nav .action-icon .icon svg rect, .navbar-light .navbar-nav .nav-link .icon svg circle, .navbar-light .navbar-nav .nav-item .action-icon .icon svg circle, .nav-item .navbar-light .navbar-nav .action-icon .icon svg circle, .navbar-light .navbar-nav .nav-link .icon svg polyline, .navbar-light .navbar-nav .nav-item .action-icon .icon svg polyline, .nav-item .navbar-light .navbar-nav .action-icon .icon svg polyline, .navbar-light .navbar-nav .nav-link .icon svg polygon, .navbar-light .navbar-nav .nav-item .action-icon .icon svg polygon, .nav-item .navbar-light .navbar-nav .action-icon .icon svg polygon, .navbar-light .navbar-nav .nav-link .icon svg line, .navbar-light .navbar-nav .nav-item .action-icon .icon svg line, .nav-item .navbar-light .navbar-nav .action-icon .icon svg line, .navbar-light .navbar-nav .nav-link .icon svg ellipse, .navbar-light .navbar-nav .nav-item .action-icon .icon svg ellipse, .nav-item .navbar-light .navbar-nav .action-icon .icon svg ellipse, .navbar-light .navbar-nav .nav-link .icon svg path, .navbar-light .navbar-nav .nav-item .action-icon .icon svg path, .nav-item .navbar-light .navbar-nav .action-icon .icon svg path, .navbar-light .navbar-nav .nav-link .icon svg g, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g {
  fill: rgba(0, 0, 0, 0.6);
}
.navbar-light .navbar-nav .nav-link .icon svg rect[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg rect[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg rect[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg circle[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg circle[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg circle[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg polyline[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg polyline[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg polyline[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg polygon[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg polygon[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg polygon[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg line[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg line[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg line[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg ellipse[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg ellipse[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg ellipse[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg path[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg path[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg path[stroke=none], .navbar-light .navbar-nav .nav-link .icon svg g[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[stroke=none] {
  stroke: none;
}
.navbar-light .navbar-nav .nav-link .icon svg rect[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg rect[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg rect[fill=none], .navbar-light .navbar-nav .nav-link .icon svg circle[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg circle[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg circle[fill=none], .navbar-light .navbar-nav .nav-link .icon svg polyline[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg polyline[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg polyline[fill=none], .navbar-light .navbar-nav .nav-link .icon svg polygon[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg polygon[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg polygon[fill=none], .navbar-light .navbar-nav .nav-link .icon svg line[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg line[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg line[fill=none], .navbar-light .navbar-nav .nav-link .icon svg ellipse[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg ellipse[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg ellipse[fill=none], .navbar-light .navbar-nav .nav-link .icon svg path[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg path[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg path[fill=none], .navbar-light .navbar-nav .nav-link .icon svg g[fill=none], .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none], .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] {
  fill: none;
}
.navbar-light .navbar-nav .nav-link .icon svg g[fill=none] rect, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] rect, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] rect, .navbar-light .navbar-nav .nav-link .icon svg g[fill=none] circle, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] circle, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] circle, .navbar-light .navbar-nav .nav-link .icon svg g[fill=none] polyline, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] polyline, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] polyline, .navbar-light .navbar-nav .nav-link .icon svg g[fill=none] polygon, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] polygon, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] polygon, .navbar-light .navbar-nav .nav-link .icon svg g[fill=none] line, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] line, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] line, .navbar-light .navbar-nav .nav-link .icon svg g[fill=none] ellipse, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] ellipse, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] ellipse, .navbar-light .navbar-nav .nav-link .icon svg g[fill=none] path, .navbar-light .navbar-nav .nav-item .action-icon .icon svg g[fill=none] path, .nav-item .navbar-light .navbar-nav .action-icon .icon svg g[fill=none] path {
  fill: none;
}
.navbar-light .navbar-nav .nav-link:hover .icon svg, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link:hover .icon svg rect[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg rect[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg rect[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg circle[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg circle[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg circle[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg polyline[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polyline[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polyline[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg polygon[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polygon[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polygon[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg line[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg line[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg line[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg ellipse[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg ellipse[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg ellipse[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg path[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg path[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg path[stroke], .navbar-light .navbar-nav .nav-link:hover .icon svg g[stroke], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[stroke], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[stroke] {
  stroke: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link:hover .icon svg rect, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg rect, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg rect, .navbar-light .navbar-nav .nav-link:hover .icon svg circle, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg circle, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg circle, .navbar-light .navbar-nav .nav-link:hover .icon svg polyline, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polyline, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polyline, .navbar-light .navbar-nav .nav-link:hover .icon svg polygon, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polygon, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polygon, .navbar-light .navbar-nav .nav-link:hover .icon svg line, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg line, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg line, .navbar-light .navbar-nav .nav-link:hover .icon svg ellipse, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg ellipse, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg ellipse, .navbar-light .navbar-nav .nav-link:hover .icon svg path, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg path, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg path, .navbar-light .navbar-nav .nav-link:hover .icon svg g, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g {
  fill: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link:hover .icon svg rect[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg rect[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg rect[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg circle[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg circle[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg circle[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg polyline[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polyline[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polyline[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg polygon[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polygon[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polygon[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg line[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg line[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg line[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg ellipse[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg ellipse[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg ellipse[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg path[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg path[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg path[stroke=none], .navbar-light .navbar-nav .nav-link:hover .icon svg g[stroke=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[stroke=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[stroke=none] {
  stroke: none;
}
.navbar-light .navbar-nav .nav-link:hover .icon svg rect[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg rect[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg rect[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg circle[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg circle[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg circle[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg polyline[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polyline[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polyline[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg polygon[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg polygon[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg polygon[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg line[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg line[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg line[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg ellipse[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg ellipse[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg ellipse[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg path[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg path[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg path[fill=none], .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none], .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none], .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] {
  fill: none;
}
.navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] rect, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] rect, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] rect, .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] circle, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] circle, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] circle, .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] polyline, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] polyline, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] polyline, .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] polygon, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] polygon, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] polygon, .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] line, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] line, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] line, .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] ellipse, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] ellipse, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] ellipse, .navbar-light .navbar-nav .nav-link:hover .icon svg g[fill=none] path, .navbar-light .navbar-nav .nav-item .action-icon:hover .icon svg g[fill=none] path, .nav-item .navbar-light .navbar-nav .action-icon:hover .icon svg g[fill=none] path {
  fill: none;
}

.dropdown-item.active .icon,
.dropdown-item:active .icon {
  color: var(--accentInvert);
}
.dropdown-item.active .icon rect[stroke], .dropdown-item.active .icon circle[stroke], .dropdown-item.active .icon polyline[stroke], .dropdown-item.active .icon polygon[stroke], .dropdown-item.active .icon line[stroke], .dropdown-item.active .icon ellipse[stroke], .dropdown-item.active .icon path[stroke], .dropdown-item.active .icon g[stroke],
.dropdown-item:active .icon rect[stroke],
.dropdown-item:active .icon circle[stroke],
.dropdown-item:active .icon polyline[stroke],
.dropdown-item:active .icon polygon[stroke],
.dropdown-item:active .icon line[stroke],
.dropdown-item:active .icon ellipse[stroke],
.dropdown-item:active .icon path[stroke],
.dropdown-item:active .icon g[stroke] {
  stroke: var(--accentInvert);
}
.dropdown-item.active .icon rect, .dropdown-item.active .icon circle, .dropdown-item.active .icon polyline, .dropdown-item.active .icon polygon, .dropdown-item.active .icon line, .dropdown-item.active .icon ellipse, .dropdown-item.active .icon path, .dropdown-item.active .icon g,
.dropdown-item:active .icon rect,
.dropdown-item:active .icon circle,
.dropdown-item:active .icon polyline,
.dropdown-item:active .icon polygon,
.dropdown-item:active .icon line,
.dropdown-item:active .icon ellipse,
.dropdown-item:active .icon path,
.dropdown-item:active .icon g {
  fill: var(--accentInvert);
}
.dropdown-item.active .icon rect[stroke=none], .dropdown-item.active .icon circle[stroke=none], .dropdown-item.active .icon polyline[stroke=none], .dropdown-item.active .icon polygon[stroke=none], .dropdown-item.active .icon line[stroke=none], .dropdown-item.active .icon ellipse[stroke=none], .dropdown-item.active .icon path[stroke=none], .dropdown-item.active .icon g[stroke=none],
.dropdown-item:active .icon rect[stroke=none],
.dropdown-item:active .icon circle[stroke=none],
.dropdown-item:active .icon polyline[stroke=none],
.dropdown-item:active .icon polygon[stroke=none],
.dropdown-item:active .icon line[stroke=none],
.dropdown-item:active .icon ellipse[stroke=none],
.dropdown-item:active .icon path[stroke=none],
.dropdown-item:active .icon g[stroke=none] {
  stroke: none;
}
.dropdown-item.active .icon rect[fill=none], .dropdown-item.active .icon circle[fill=none], .dropdown-item.active .icon polyline[fill=none], .dropdown-item.active .icon polygon[fill=none], .dropdown-item.active .icon line[fill=none], .dropdown-item.active .icon ellipse[fill=none], .dropdown-item.active .icon path[fill=none], .dropdown-item.active .icon g[fill=none],
.dropdown-item:active .icon rect[fill=none],
.dropdown-item:active .icon circle[fill=none],
.dropdown-item:active .icon polyline[fill=none],
.dropdown-item:active .icon polygon[fill=none],
.dropdown-item:active .icon line[fill=none],
.dropdown-item:active .icon ellipse[fill=none],
.dropdown-item:active .icon path[fill=none],
.dropdown-item:active .icon g[fill=none] {
  fill: none;
}
.dropdown-item.active .icon g[fill=none] rect, .dropdown-item.active .icon g[fill=none] circle, .dropdown-item.active .icon g[fill=none] polyline, .dropdown-item.active .icon g[fill=none] polygon, .dropdown-item.active .icon g[fill=none] line, .dropdown-item.active .icon g[fill=none] ellipse, .dropdown-item.active .icon g[fill=none] path,
.dropdown-item:active .icon g[fill=none] rect,
.dropdown-item:active .icon g[fill=none] circle,
.dropdown-item:active .icon g[fill=none] polyline,
.dropdown-item:active .icon g[fill=none] polygon,
.dropdown-item:active .icon g[fill=none] line,
.dropdown-item:active .icon g[fill=none] ellipse,
.dropdown-item:active .icon g[fill=none] path {
  fill: none;
}

.flip {
  position: relative;
  overflow: hidden;
}
.flip .flip-panel {
  flex: 1;
}
.flip .flip-back {
  visibility: hidden;
}
.flip.flip-one-column .flip-panel-right {
  display: block;
  position: absolute;
  transform: translate(100%, 0);
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  transition: transform 0.2s linear;
}
.flip.flip-one-column .flip-back {
  visibility: visible;
}
.flip.flip-one-column.flip-open .flip-panel-right {
  transform: translate(0, 0);
}

.form-text-value {
  padding: 0.375rem 0.75rem;
  color: #212529;
}

form.form-condensed {
  height: 100%;
  display: flex;
  flex-flow: column;
}
form.form-condensed label {
  font-size: 80%;
  text-transform: uppercase;
  color: #383f45;
  margin-bottom: 0.18rem;
}
form.form-condensed .mb-3 {
  border-radius: 0.2rem;
  background-color: white;
  border: #ced4da solid 1px;
  padding: 0.375rem 0.75rem;
  padding-bottom: 0;
  margin-left: 10px;
}
form.form-condensed .mb-3.form-group-grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}
form.form-condensed .mb-3.form-group-grow iframe {
  flex-grow: 1;
  height: auto;
  min-height: 0;
}
form.form-condensed .mb-3 .form-control {
  padding: 0;
  border: 0;
  border-bottom: 3px solid #ced4da;
  border-radius: 0;
  margin-bottom: -1px;
  background-color: transparent;
}
form.form-condensed .mb-3 .form-control:focus {
  box-shadow: none;
  border-bottom-color: var(--accentColour);
}
form.form-condensed .mb-3 .form-control.is-invalid:focus, form.form-condensed .mb-3 .form-control:invalid:focus {
  box-shadow: none;
}
form.form-condensed .mb-3 .form-control.is-invalid:focus + .invalid-tooltip, form.form-condensed .mb-3 .form-control:invalid:focus + .invalid-tooltip {
  opacity: 1;
}
form.form-condensed .mb-3 .invalid-tooltip {
  right: 0;
  margin-right: 0.75rem;
  opacity: 0;
}
form.form-condensed .mb-3:hover .invalid-tooltip, form.form-condensed .mb-3:focus .invalid-tooltip {
  opacity: 1;
}

.invalid-text {
  color: #e23d4b;
}

.notification-container {
  position: fixed;
  top: 6rem;
  left: 0;
  right: 0;
  z-index: 1050;
  padding: 0.9rem;
  user-select: none;
  pointer-events: none;
}
.notification-container .notification-list {
  margin: 0 auto;
}
.notification-container .notification-list > * {
  user-select: auto;
  pointer-events: auto;
}

.custom-checkbox.switch input:checked + .custom-checkbox-state {
  background-color: var(--accentColour) !important;
  box-shadow: 0 0 0 2px var(--accentColour) !important;
  border-color: var(--accentColour) !important;
}

.btn-outline-primary {
  color: var(--accentColour) !important;
  border-color: var(--accentColour) !important;
}
.btn-outline-primary svg {
  color: var(--accentColour) !important;
  border-color: var(--accentColour) !important;
  fill: var(--accentColour) !important;
}
.btn-outline-primary svg path {
  color: var(--accentColour) !important;
  border-color: var(--accentColour) !important;
  fill: var(--accentColour) !important;
}

.btn-outline-primary:hover {
  background-color: var(--accentColour) !important;
  color: var(--accentInvert) !important;
}
.btn-outline-primary:hover path {
  color: var(--accentInvert) !important;
  border-color: var(--accentInvert) !important;
  fill: var(--accentInvert) !important;
}

.btn-link {
  color: var(--loginLinksColour) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--accentInvert) !important;
  text-decoration: none;
  background-color: var(--accentColour) !important;
}

.primaryColoured {
  background-color: var(--accentColour) !important;
  color: var(--accentInvert) !important;
}

.__react_component_tooltip.primaryColoured {
  background-color: var(--tooltipPrimaryColour) !important;
  color: var(--tooltipPrimartInvert) !important;
}

.__react_component_tooltip {
  z-index: 1051 !important;
}

.__react_component_tooltip.type-info.place-bottom:after {
  border-bottom-color: var(--accentColour) !important;
}

.__react_component_tooltip.type-info.place-right:after {
  border-right-color: var(--accentColour) !important;
}

.__react_component_tooltip.type-info.place-top:after {
  border-top-color: var(--accentColour) !important;
}

.__react_component_tooltip.type-info.place-left:after {
  border-left-color: var(--accentColour) !important;
}

.expander {
  clear: both;
}
.expander .expander-collider {
  overflow: hidden;
  max-height: 0;
  z-index: 1048;
}
.expander .expander-collider.expander-open {
  max-height: none;
  overflow-x: visible;
}
.expander .expander-body {
  background-color: #e9ecef;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}
.custom-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-checkbox .custom-checkbox-state {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e2e2;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.2rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
}
.custom-checkbox input:checked + .custom-checkbox-state {
  background-color: #495057;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
}
.custom-checkbox input:indeterminate + .custom-checkbox-state {
  background-color: #1c67d8;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#fff' d='M0 2h4'/></svg>");
}
.custom-checkbox input:disabled + .custom-checkbox-state {
  background-color: #e9ecef;
}

.custom-checkbox.switch {
  width: 2rem;
}
.custom-checkbox.switch .custom-checkbox-state {
  background-image: none;
  border-radius: 20rem;
  box-shadow: 0 0 0 2px #e2e2e2;
}
.custom-checkbox.switch .custom-checkbox-state:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0;
  bottom: 0;
  transition: left 0.15s linear, background-color 0.15s ease-in-out;
  border-radius: 50%;
  background-color: #495057;
}
.custom-checkbox.switch input:checked + .custom-checkbox-state {
  background-color: #1c67d8;
  border-color: #1c67d8;
  box-shadow: 0 0 0 2px #1c67d8;
  background-image: none;
}
.custom-checkbox.switch input:checked + .custom-checkbox-state:before {
  left: 1rem;
  background-color: white;
}
.custom-checkbox.switch input:disabled + .custom-checkbox-state {
  background-color: #e9ecef;
}

.message-dialog-item .modal-header {
  border-bottom: 0;
}
.message-dialog-item .modal-body {
  padding-top: 0;
}
.message-dialog-item.deleteConfirm {
  padding: 0.9rem;
}
@media (max-width: 575.98px) {
  .message-dialog-item .modal-footer {
    flex: none !important;
    display: block !important;
    padding: 0;
  }
  .message-dialog-item .modal-footer .btn {
    display: block !important;
    width: 100%;
    margin: 0 !important;
    border-radius: 0 !important;
  }
  .message-dialog-item .modal-footer .btn:last-child {
    border-bottom-left-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }
}

.library-selection-icon {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  margin: 0.45rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075), 0 1px 0 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.4rem;
  transition: box-shadow 0.2s linear;
  cursor: pointer;
}
.library-selection-icon.active {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075), 0 1px 0 0 rgba(0, 0, 0, 0.5), 0 0 0 4px rgba(255, 255, 255, 0.5);
}

.loading-modal-context {
  position: relative !important;
}

.loading-modal-context {
  position: relative !important;
}

.loading-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1043;
  background-color: var(--loadingModalBgColour);
  color: var(--loadingModalColour);
  text-align: center;
}
.loading-modal .loading-modal-spinner {
  display: block;
  margin-bottom: 0.9rem;
  margin-left: auto;
  margin-right: auto;
}
.loading-modal .loading-modal-spinner > svg {
  stroke: var(--loadingModalColour);
}
.loading-modal.loading-modal-opaque {
  background-color: var(--loadingModalOpaqueBgColour);
  color: var(--loadingModalOpaqueColour);
  z-index: 1043;
}
.loading-modal.loading-modal-opaque .loading-modal-spinner > svg {
  stroke: var(--loadingModalOpaqueColour);
}

.static-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.static-modal-container .modal {
  display: block;
  position: absolute;
}
.static-modal-container .modal-backdrop {
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.card-img-left {
  flex-direction: row;
}
.card-img-left .card-img {
  width: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.progress {
  background-color: var(--loadingBarBackPrimaryColour);
}
.progress .progress-bar {
  background-color: var(--loadingBarFillPrimaryColour);
}

.progress.progress-indeterminate .progress-bar {
  min-width: 20%;
  max-width: 20%;
  width: 20% !important;
  animation: progress-indeterminate 5s linear infinite;
}
@keyframes progress-indeterminate {
  0% {
    margin-left: 0%;
  }
  50% {
    margin-left: 80%;
  }
  100% {
    margin-left: 0%;
  }
}

.drawer-container {
  position: relative !important;
  overflow: hidden;
}
.drawer-container .vertical-navbar {
  padding-top: 0.3rem;
}
.drawer-container .drawer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1049;
  transform: translate(-100%, 0);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  pointer-events: none;
}
.drawer-container .drawer .drawer-content {
  pointer-events: all;
  height: 100%;
  overflow: auto;
  min-width: 300px;
  background-color: #f8f9fa;
  border-radius: 0.9rem 0.9rem 0 0;
  padding: 0.9rem;
}
.drawer-container .drawer + .drawer-backdrop {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.drawer-container .drawer.drawer-show {
  transform: translate(0, 0);
}
.drawer-container .drawer.drawer-show + .drawer-backdrop {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 1048;
  background: #000;
  opacity: 0.5;
}
.drawer-container.drawer-container-to-top .drawer {
  transform: translate(0, 100%);
  height: 80%;
  bottom: 0;
  top: auto;
  width: 100%;
  max-width: none;
  min-width: none;
}
.drawer-container.drawer-container-to-top .drawer.drawer-show {
  transform: translate(0, 0);
}
.drawer-container.drawer-container-to-top .drawer .drawer-content {
  margin: 0 auto;
}

.dismissOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 90;
}
.dismissOverlay.hidden {
  display: none;
}

.markdownContainer table {
  width: 100%;
}
.markdownContainer td,
.markdownContainer th {
  border: 1px solid #ddd;
  padding: 8px;
}
.markdownContainer tr:nth-child(even) {
  background-color: #f2f2f2;
}
.markdownContainer tr:hover {
  background-color: #ddd;
}
.markdownContainer th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--accentColour);
  color: var(--accentInvert);
}

.cogniflow-loading {
  position: absolute;
  height: 5px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}
.cogniflow-loading.cogniflow-loading-top {
  top: 0;
}
.cogniflow-loading.cogniflow-loading-bottom {
  bottom: 0;
}
.cogniflow-loading > div {
  background-color: #1c67d8;
  height: 5px;
  width: 0%;
  margin: 0 auto;
  animation: cogniflow-loading-animation 1s linear infinite;
}
@keyframes cogniflow-loading-animation {
  0% {
    width: 5%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 5%;
  }
}

.cogniflow .cogniflow--repository {
  z-index: -100000;
  pointer-events: none;
  opacity: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
}
.cogniflow .cogniflow--transition {
  display: none;
}

.separator {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.cogniflow-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.cogniflow-container::-webkit-scrollbar {
  display: none;
}

.splash-page-context {
  position: relative;
}
.splash-page-context .splash-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1045;
  background-origin: content-box;
}
.splash-page-context .splash-page-status {
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  left: 0;
  right: 0;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.scrubber {
  width: 20px;
  top: 0px;
  right: 0px;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: solid 1px lightgray;
  border-top: solid 1px lightgray;
  background-color: whitesmoke;
}
.scrubber .relativeInnerblock {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.scrubber .relativeInnerblock .scrubber-handle {
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
  position: absolute;
  transition: background-color 0.1s ease;
}
.scrubber .relativeInnerblock .scrubber-handle:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.scrubber .relativeInnerblock .scrubber-handle.active {
  background-color: rgba(0, 0, 0, 0.5);
}
.scrubber .relativeInnerblock .scrubber-handle-tick {
  height: 3px;
  background-color: black;
  opacity: 0.3;
}
.scrubber .relativeInnerblock .scrubber-search-hit {
  height: 1px;
  background-color: red;
  position: absolute;
}

.scroll-navigation-container {
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.scroll-navigation-modal {
  width: 800px;
  max-width: 90%;
  overflow: auto;
}

.scroll-navigation-modal .scroll-navigation-header:nth-child(even) {
  background-color: lightgrey;
}

.scroll-navigation-header:hover {
  background-color: darkgray !important;
}

.annotation-type .colour-token {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  display: inline-block;
  vertical-align: middle;
  border-top: 1px solid;
  border-color: #ced4da;
  margin-right: 5px;
  flex-shrink: 0;
}
.annotation-type .colour-token.larger-token {
  height: 20px;
  width: 20px;
}

.scrollingFilterContainer {
  overflow-y: scroll;
  max-height: 200px;
}

.form-check {
  margin-left: 2.5rem;
}
.form-check input {
  margin-left: 0px;
}

.formContainer .formButtons {
  margin-top: 0.9rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.formContainer .formButtons button {
  margin-top: 0.9rem;
  max-width: 45rem;
}

.formGroup {
  margin-right: 10px;
}

.book-tile-wrapper {
  min-width: 100px;
}
.book-tile-wrapper.faded {
  opacity: 0.45;
}
.book-tile-wrapper .book-tile {
  position: relative;
  z-index: 1;
}
.book-tile-wrapper .book-tile .hitCount {
  background-color: red;
  position: absolute;
  right: 0px;
  border-radius: 5px;
  bottom: 0px;
  color: white;
  padding: 0.05rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  border: 1px solid #ced4da;
  margin: 2px;
}
@keyframes selectedTile {
  100% {
    box-shadow: 0 0 15px var(--box-shadow-color);
  }
  0% {
    box-shadow: 0 0 0px var(--box-shadow-color);
  }
}
.book-tile-wrapper .book-tile.searchMode {
  --box-shadow-color: transparent;
}
.book-tile-wrapper .book-tile.searchMode.selected {
  animation: selectedTile 500ms forwards;
  --box-shadow-color: var(--multiTitleSearchSelectedTitleColour);
}
.book-tile-wrapper .book-tile .card-body {
  height: 9rem;
}
.book-tile-wrapper .book-tile .book-tile-info {
  overflow: hidden;
  position: relative;
}
.book-tile-wrapper .book-tile .book-tile-info .fadeout {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 82%, #f8f9fa 100%);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.book-tile-wrapper .book-tile .book-tile-info .card-text {
  font-size: 0.85rem;
  word-break: break-all;
}
.book-tile-wrapper .book-tile .span-header {
  vertical-align: middle;
  padding-left: 5px;
}
.book-tile-wrapper .book-tile .book-tile-actions .book-tile-status {
  font-size: 0.75em;
}
.book-tile-wrapper .book-tile .book-tile-actions .progress {
  height: 0.5rem;
}
.book-tile-wrapper .book-tile .cover {
  height: auto !important;
  width: 7rem;
}
.book-tile-wrapper .book-tile .conditionalFlag {
  position: absolute;
  right: -5px;
  top: -5px;
  transform: rotate(20deg);
  z-index: 2;
}
.book-tile-wrapper .book-tile .conditionalFlag svg {
  width: 25px;
}

nav .searchBar {
  animation: fadein 0.3s;
}
nav .searchButton {
  animation: fadein 0.3s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.emptyLib {
  margin: 0.9rem;
}

.listContainer {
  position: relative;
}

.dropdownContainer {
  margin-bottom: 5px;
  width: 100%;
}
.dropdownContainer .dropdown button {
  padding-left: 0px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.dropdownContainer .dropdown button .annotation-type {
  padding-left: 5px;
  overflow: hidden;
  width: 100%;
}
.dropdownContainer .dropdown .dropdown-item {
  padding: 0px;
}
.dropdownContainer .dropdown .dropdown-item div {
  padding-left: 5px;
  padding-right: 5px;
}
.dropdownContainer .dropdown .dropdown-item span {
  white-space: pre-wrap;
}
.dropdownContainer .dropdown .dropdown-item :hover {
  background-color: var(--accentColour);
  color: var(--accentInvert);
}
.dropdownContainer .dropdown .dropdown-menu {
  width: 100%;
  padding: 0px;
}

.colourPickerContainer {
  margin-bottom: 10px;
  max-width: 300px;
}

.cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #6c757d;
  width: 4.9rem;
  height: 6.5333333333rem;
}
@media (min-width: 768px) {
  .cover {
    width: 7rem;
    height: 9.3333333333rem;
  }
}

.font-smallest,
.font-smallest > * {
  font-size: 80% !important;
}

.font-smaller,
.font-smaller > * {
  font-size: 90% !important;
}

.font-medium,
.font-medium > * {
  font-size: 100% !important;
}

.font-larger,
.font-larger > * {
  font-size: 110% !important;
}

.font-largest,
.font-largest > * {
  font-size: 120% !important;
}

.details-view .drawer-content {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  width: 90%;
}
.details-view .drawer-content .details-view-body a.list-group-item {
  color: var(--infoHeaderColour);
}
@media (min-width: 768px) {
  .details-view .drawer-content {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .details-view .drawer-content {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .details-view .drawer-content {
    width: 80%;
    max-width: 1000px;
  }
}

.hide {
  visibility: hidden;
  display: none !important;
}

.noWidth {
  width: 0;
}

.library-name-container {
  text-overflow: ellipsis;
  overflow: hidden;
}

.unbound-form-modal {
  width: 100%;
  height: 100%;
  top: 0;
  background: black;
  position: absolute;
  opacity: 0.3;
}

.unbound-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0.9rem;
  width: 75%;
  display: flex;
  flex-flow: column;
  border: 1px solid #ced4da;
  max-height: 500px;
  overflow: visible;
}
@media (min-width: 768px) {
  .unbound-form-container {
    width: 75%;
    max-width: 700px;
    max-height: 800px;
  }
}
@media (min-width: 576px) {
  .unbound-form-container {
    max-height: 90%;
  }
}
.unbound-form-container .ucContainer {
  overflow: auto;
}
.unbound-form-container .colour-token {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  border-top: 1px solid;
  border-color: #ced4da;
}
.unbound-form-container .type-name {
  vertical-align: middle;
  margin-left: 5px;
}
.unbound-form-container .dropdownContainer .dropdown-menu {
  max-height: 200px;
  overflow: scroll;
}

.modalHide {
  visibility: hidden;
  display: none;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.invalidQuery {
  border: solid red !important;
  border-width: 1px 3px !important;
}

.section {
  background-color: #f8f9fa;
  border-radius: 0.9rem;
  padding: 0.9rem;
  margin-top: 0.45rem;
  border-top: 2px solid #0975bb;
  border-left: 2px solid #dee2e6;
  border-right: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.dataTable {
  width: 95%;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.dataTable .dataHeaderRow {
  display: flex;
  border-radius: 0.9rem 0.9rem 0 0;
  overflow: hidden;
}
.dataTable .dataHeaderRow .dataHeader {
  text-align: center;
  border: 1px solid #dee2e6;
  flex: 1;
  background-color: #ced4da;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataTable .dataHeaderRow .dataHeader * {
  margin-left: 0.45rem;
  margin-right: 0.45rem;
}
.dataTable .scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
}
.dataTable .data {
  height: 100%;
  border: 1px solid #dee2e6;
}
.dataTable .data .dataRow {
  display: flex;
}
.dataTable .data .dataRow .dataItem {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
.dataTable .data .dataRow .dataItem.centerText {
  text-align: center;
}
.dataTable .data .dataRow .dataItem.bolded {
  font-weight: 800;
}
.dataTable .data .dataRow .dataItem.rightBorder {
  border-right: 1px solid #ced4da;
}
.dataTable .data .dataRow .dataItem.leftBorder {
  border-left: 1px solid #ced4da;
}
.dataTable .data .dataRow .dataItem.checkBox {
  text-align: center;
}
.dataTable .data .dataRow .dataItem.checkBox .form-check-input {
  position: relative;
  margin: 0 auto;
}
.dataTable .data .dataRow .dataItem * {
  margin-left: 0.45rem;
  margin-right: 0.45rem;
}
.dataTable .data .dataRow:nth-child(even) {
  background-color: #f8f9fa;
}
.dataTable .data .dataRow:nth-child(odd) {
  background-color: #dee2e6;
}
.dataTable .data .dataRow:hover {
  background-color: #adb5bd;
}
.dataTable .data .dataRow:hover.selected {
  background-color: #4183e6;
}
.dataTable .data .dataRow.selected {
  background-color: #1c67d8;
  color: white;
}
.dataTable .data .cogniflow-segments {
  margin-right: -5px;
}
.dataTable .addRowButton {
  position: absolute;
  bottom: 15px;
  right: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #1c67d8;
  border: 3px solid #adb5bd;
  vertical-align: middle;
}
.dataTable .addRowButton svg {
  fill: white;
  height: 75%;
  width: 75%;
  margin: 5px;
}
.dataTable .deleteRowButton {
  position: absolute;
  bottom: 15px;
  right: 55px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: red;
  border: 3px solid #adb5bd;
  vertical-align: middle;
}
.dataTable .deleteRowButton svg {
  fill: white;
  height: 75%;
  width: 75%;
  margin: 4px;
}
.dataTable .deleteRowButton svg path {
  fill: white;
}
.dataTable .deleteRowButton.disabled {
  background-color: #adb5bd;
}
.dataTable .searchButtonContainer {
  display: flex;
  flex-direction: row;
}
.dataTable .searchButtonContainer .searchBarContainer {
  position: absolute;
  right: 45px;
  bottom: 0px;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.dataTable .searchButtonContainer .searchBarContainer.visible {
  display: block;
  opacity: 1;
}
.dataTable .searchButtonContainer .searchBarContainer .searchBar {
  position: absolute;
  bottom: 16px;
  right: 120px;
  width: 250px;
}
.dataTable .searchButtonContainer .searchButton {
  position: absolute;
  bottom: 15px;
  right: 100px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #1c67d8;
  border: 3px solid #adb5bd;
  vertical-align: middle;
}
.dataTable .searchButtonContainer .searchButton svg {
  fill: white;
  height: 75%;
  width: 75%;
  margin: 5px;
}
.dataTable .searchButtonContainer .searchButton svg path {
  fill: white;
}
.dataTable .searchButtonContainer .searchButton.cancellable {
  background-color: red;
}
.dataTable .addRowButton:hover {
  box-shadow: 0 0 6px 3px #1c67d8;
}
.dataTable .searchButton:hover {
  box-shadow: 0 0 6px 3px #1c67d8;
}
.dataTable .searchButton:hover.cancellable {
  box-shadow: 0 0 6px 3px red;
}
.dataTable .deleteRowButton:hover {
  box-shadow: 0 0 6px 3px red;
}
.dataTable .deleteRowButton:hover.disabled {
  box-shadow: 0 0 6px 3px #adb5bd;
}

.subscriptionForm {
  z-index: 2000;
}
.subscriptionForm .form-container {
  display: flex;
  flex-direction: column;
}
.subscriptionForm .form-container #subscriptionTabs-tabpane-2 {
  height: 100%;
}
.subscriptionForm .form-container .subscription-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.subscriptionForm .form-container .subscription-tabs a {
  flex: 1;
  text-align: center;
}
.subscriptionForm .form-container .subscription-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionProperties .thumbnailTitles {
  flex: 1;
  display: flex;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionProperties .thumbnailTitles span {
  flex: 1;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionProperties .thumbnails {
  flex: 1;
  display: flex;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionProperties .thumbnails div {
  flex: 1;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionProperties .thumbnails div img {
  max-width: 150px;
  height: auto;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionLicenses {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.9rem;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionLicenses .licenceTable {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.subscriptionForm .form-container .subscription-tabs .subscriptionLicenses .licenceTable > div {
  flex: 1;
}

.mainView {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.mainView .pubSelect {
  width: 250px;
  margin-bottom: 0.9rem;
}
.mainView .homeView {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .accountManagementView .mb-3 {
  margin-left: 5px;
  margin-right: 5px;
}
.mainView .accountManagementView .accountManagementViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .accountManagementView .drawer {
  height: 90%;
}
.mainView .accountManagementView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .accountManagementView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs .accountProperties .thumbnailTitles {
  flex: 1;
  display: flex;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs .accountProperties .thumbnailTitles span {
  flex: 1;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs .accountProperties .thumbnails {
  flex: 1;
  display: flex;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs .accountProperties .thumbnails div {
  flex: 1;
}
.mainView .accountManagementView .drawer-content .form-container .account-tabs .accountProperties .thumbnails div img {
  max-width: 150px;
  height: auto;
}
.mainView .reportingView .reportingViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .reportingView .reportingViewInner .statsHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mainView .reportingView .reportingViewInner .statsHolder span {
  padding: 5px;
  border: 1px solid #ced4da;
  flex: 1;
  margin-top: 5px;
}
.mainView .reportingView .reportingViewInner .innerGraphSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mainView .reportingView .reportingViewInner .innerGraphSection .graphTitles {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.mainView .reportingView .reportingViewInner .innerGraphSection .graphTitles .titleItem {
  flex: 1;
}
.mainView .reportingView .reportingViewInner .innerGraphSection .graphDescription {
  flex: 1;
}
.mainView .reportingView .reportingViewInner .innerGraphSection .graphDescription p {
  font-style: italic;
}
.mainView .reportingView .reportingViewInner .graphHolder {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.mainView .reportingView .reportingViewInner .graphHolder .graphBox {
  flex: 1;
  min-height: 300px;
}
.mainView .licensingView .licencingViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .licensingView .drawer {
  height: 90%;
}
.mainView .licensingView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .customerView .customerViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .customerView .drawer {
  height: 90%;
}
.mainView .customerView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .customerView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .customerView .drawer-content .form-container #customerTabs-tabpane-1 {
  height: 100%;
}
.mainView .customerView .drawer-content .form-container .customer-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .customerView .drawer-content .form-container .customer-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .customerView .drawer-content .form-container .customer-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
  padding: 0.3rem;
}
.mainView .customerView .drawer-content .form-container .customer-tabs .customerSubscriptions {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.9rem;
}
.mainView .customerView .drawer-content .form-container .customer-tabs .customerSubscriptions .subscriptionsTable {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.mainView .customerView .drawer-content .form-container .customer-tabs .customerSubscriptions .subscriptionsTable > div {
  flex: 1;
}
.mainView .customerView .drawer-content .form-container .customer-tabs .customerSubscriptions .dataTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mainView .customerView .drawer-content .form-container .customer-tabs .customerSubscriptions .dataTable .data {
  flex: 1;
  height: initial;
}
.mainView .productView .productViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .productView .drawer {
  height: 90%;
}
.mainView .productView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .productView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .productView .drawer-content .form-container #productTabs-tabpane-2 {
  height: 100%;
}
.mainView .productView .drawer-content .form-container .product-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .productView .drawer-content .form-container .product-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .productView .drawer-content .form-container .product-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.mainView .productView .drawer-content .form-container .product-tabs .productProperties .thumbnailTitles {
  flex: 1;
  display: flex;
}
.mainView .productView .drawer-content .form-container .product-tabs .productProperties .thumbnailTitles span {
  flex: 1;
}
.mainView .productView .drawer-content .form-container .product-tabs .productProperties .thumbnails {
  flex: 1;
  display: flex;
}
.mainView .productView .drawer-content .form-container .product-tabs .productProperties .thumbnails div {
  flex: 1;
}
.mainView .productView .drawer-content .form-container .product-tabs .productProperties .thumbnails div img {
  max-width: 150px;
  height: auto;
}
.mainView .productView .drawer-content .form-container .product-tabs .productTitles {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.9rem;
}
.mainView .productView .drawer-content .form-container .product-tabs .productTitles .tableTitle {
  padding-left: 0.9rem;
  font-weight: 800;
}
.mainView .productView .drawer-content .form-container .promoProperties {
  display: flex;
  flex-direction: column;
}
.mainView .productView .drawer-content .form-container .promoProperties .dataTable {
  flex: 1;
  width: initial;
}
.mainView .tipView .tipsViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .tipView .drawer {
  height: 90%;
}
.mainView .tipView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .tipView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .tipView .drawer-content .form-container .tip-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .tipView .drawer-content .form-container .tip-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .tipView .drawer-content .form-container .tip-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.mainView .billingView .billingViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .billingView .drawer {
  height: 90%;
}
.mainView .billingView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .billingView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .billingView .drawer-content .form-container .billing-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .billingView .drawer-content .form-container .billing-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .billingView .drawer-content .form-container .billing-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.mainView .emailTemplateView {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .subscriptionView .subscriptionViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .subscriptionView .drawer {
  height: 90%;
}
.mainView .subscriptionView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .feedbacksView .feedbacksViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .feedbacksView .drawer {
  height: 90%;
}
.mainView .feedbacksView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .feedbacksView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .feedbacksView .drawer-content .form-container .feedback-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .feedbacksView .drawer-content .form-container .feedback-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .feedbacksView .drawer-content .form-container .feedback-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.mainView .systemView .systemViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .systemView .drawer {
  height: 90%;
}
.mainView .systemView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .bulletinsView .bulletinsViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .bulletinsView .drawer {
  height: 90%;
}
.mainView .bulletinsView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .bulletinsView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .bulletinsView .drawer-content .form-container .bulletin-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .bulletinsView .drawer-content .form-container .bulletin-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .bulletinsView .drawer-content .form-container .bulletin-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
}
.mainView .panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 0.9rem;
  border-radius: 0.9rem;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.mainView .panel.disabled {
  opacity: 0.5;
}
.mainView .panel .item {
  flex-direction: column;
  align-items: center;
  display: flex;
}
.mainView .panel .item:first-child {
  padding-top: 0.9rem;
  border-radius: 0.9rem 0.9rem 0 0;
}
.mainView .panel .item:last-child {
  padding-top: 0.9rem;
  border-radius: 0 0 0.9rem 0.9rem;
}
.mainView .panel .item:first-child:last-child {
  padding-top: 0.9rem;
  border-radius: 0.9rem;
}
.mainView .panel .item.selected {
  background-color: #ced4da;
}
.mainView .panel .item:hover {
  background-color: #dee2e6;
}
.mainView .panel .item .icon {
  width: 65.68px;
  height: 65.05px;
  margin: 4px;
  left: 11px;
  border-radius: 32px;
  border: 2px solid #ffffff;
  background-color: inherit;
}
.mainView .panel .item .publisher-name {
  text-align: center;
  width: 95px;
  font-weight: 400;
  padding: 4px;
  font-size: 15px;
}
.mainView .panel .addDiv {
  width: 65.68px;
  height: 65.05px;
  margin: 4px;
  left: 11px;
  border-radius: 32px;
  border: 2px solid #ffffff;
  background-color: inherit;
  padding: 0.9rem;
}
.mainView .publisherView {
  display: flex;
  padding: 0.45rem;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.mainView .publisherView .tab-content {
  margin-top: 0.9rem;
}
.mainView .publisherView .section {
  background-color: #f8f9fa;
  border-radius: 0.9rem;
  padding: 0.9rem;
  margin-top: 0.45rem;
  width: 100%;
  border-top: 2px solid #0975bb;
  border-left: 2px solid #dee2e6;
  border-right: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.mainView .publisherView .content-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: rgba(255, 255, 255, 0.9294117647);
  border-top: 2px solid #0975bb;
  border-radius: 0.9rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.45rem;
  overflow-y: scroll;
}
.mainView .publisherView .content-box .header-box {
  display: flex;
  flex-direction: row;
  padding: 0.9rem;
}
.mainView .publisherView .content-box .content-publisher-logo {
  border-radius: 40px;
  border: 2px solid #ffffff;
}
.mainView .publisherView .content-box .content-publisher-name {
  padding: 20px;
  color: #093353;
  font-weight: 400;
}
.mainView .publisherView .content-box .content-publisher-detail-header {
  color: #0975bb;
  font-weight: 600;
  padding: 2px;
}
.mainView .publisherView .content-box .content-publisher-detail {
  color: #0975bb;
  font-weight: 400;
  padding: 1px;
  margin-left: 40px;
  width: 80%;
}
.mainView .publisherView .content-box .content-button {
  margin: 25px;
  width: 183.42px;
  padding: 5px;
  height: 39.51px;
  left: 391.57px;
  top: 510.03px;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  background: #38c6d9;
  border-radius: 7px;
}
.mainView .publisherView #file-input,
.mainView .publisherView #json-input,
.mainView .publisherView #html-input,
.mainView .publisherView #library-input,
.mainView .publisherView #theme-input {
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  filter: alpha(opacity=0);
  /* IE < 9 */
  opacity: 0;
}
.mainView .publisherView #logo-input {
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  filter: alpha(opacity=0);
  /* IE < 9 */
  opacity: 0;
}
.mainView .publisherView .input-label {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.mainView .version-tabs-root {
  display: flex;
  flex-direction: column;
}
.mainView .titleView .titleViewInner {
  padding: 0.45rem;
  overflow: scroll;
}
.mainView .titleView .drawer {
  height: 90%;
}
.mainView .titleView .drawer-content {
  max-width: 1500px;
  width: 80%;
}
.mainView .titleView .drawer-content .form-container {
  display: flex;
  flex-direction: column;
}
.mainView .titleView .drawer-content .form-container .version-tabs {
  flex: 1;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.mainView .titleView .drawer-content .form-container .version-tabs a {
  flex: 1;
  text-align: center;
}
.mainView .titleView .drawer-content .form-container .version-tabs .tab-content {
  overflow-y: scroll;
  flex: 1;
  position: relative;
  margin-top: 10px;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

.sectionArea {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
}

.section {
  background-color: #f8f9fa;
  border-radius: 0.9rem;
  padding: 0.9rem;
  margin-top: 0.45rem;
  width: 100%;
  border-top: 2px solid #0975bb;
  border-left: 2px solid #dee2e6;
  border-right: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.content-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: rgba(255, 255, 255, 0.9294117647);
  border-top: 2px solid #0975bb;
  border-radius: 0.9rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.45rem;
  overflow-y: scroll;
}

.content-publisher-logo {
  border-radius: 40px;
  border: 2px solid #ffffff;
}

.header-box {
  display: flex;
  flex-direction: row;
  padding: 0.9rem;
}

.content-publisher-name {
  padding: 6px;
  color: #093353;
  font-weight: 400;
}

.productTitles {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.9rem;
}

.drawer {
  height: 90%;
}

.drawer-content {
  max-width: 1500px;
  width: 80%;
}

.titlesTab {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.profileView {
  display: flex;
  padding: 0.45rem;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.profileView .sectionArea {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
}
.profileView .section {
  background-color: #f8f9fa;
  border-radius: 0.9rem;
  padding: 0.9rem;
  margin-top: 0.45rem;
  width: 100%;
  border-top: 2px solid #0975bb;
  border-left: 2px solid #dee2e6;
  border-right: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.profileView .drawer {
  height: 90%;
}
.profileView .drawer-content {
  max-width: 1500px;
  width: 80%;
}

.masterPage-container {
  display: grid;
  grid-template-areas: "header header header" "sidebar content content" "footer footer footer";
  grid-template-columns: 225px 5fr;
  grid-template-rows: auto 12fr 1fr;
  grid-gap: 2px;
  height: 100%;
  width: 100%;
}
.masterPage-container .bookview-tabs {
  grid-area: content;
  overflow: auto;
}
.masterPage-container .header {
  position: relative;
  grid-area: header;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 25px;
  box-sizing: border-box;
}
.masterPage-container .header .header-logo {
  border: 1px solid rgba(124, 172, 213, 0.12);
}
.masterPage-container .header .userBox {
  position: absolute;
  right: 0;
  display: flex;
  padding-right: 0.9rem;
  align-items: center;
  justify-content: space-evenly;
  min-width: 300px;
}
.masterPage-container .header .userBox .avatar {
  background-color: #f8f9fa;
  box-sizing: border-box;
  max-height: 80%;
}
.masterPage-container .header .userBox .logoutBox {
  font-size: large;
  cursor: pointer;
}
.masterPage-container .footer {
  grid-area: footer;
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid rgba(124, 172, 213, 0.12);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.masterPage-container .footer .footer-logo {
  grid-area: footer;
  background-color: #f8f9fa;
}
.masterPage-container .sidebar {
  grid-area: sidebar;
}
.masterPage-container .sidebar .header {
  font-weight: 700;
  background-color: #c5d9ea;
}
.masterPage-container .sidebar .menu {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  box-sizing: border-box;
  margin-top: 0.9rem;
  margin-left: 0.45rem;
  margin-right: 0.45rem;
  border-radius: 0.9rem;
}
.masterPage-container .sidebar .menu .menuItem {
  width: 100%;
  height: 35px;
  display: flex;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  align-items: center;
}
.masterPage-container .sidebar .menu .menuItem.selectedItem {
  background-color: #ced4da;
}
.masterPage-container .sidebar .menu .menuItem .itemIcon {
  margin-right: 1.8rem;
}
.masterPage-container .sidebar .menu .menuItem:last-child {
  border-radius: 0 0 0.9rem 0.9rem;
}
.masterPage-container .sidebar .menu .menuItem:first-child {
  border-radius: 0.9rem 0.9rem 0 0;
}
.masterPage-container .sidebar .menu .menuItem:hover {
  background-color: #dee2e6;
}

.darkOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(108, 117, 125, 0.3);
  z-index: 1047;
}

.loginView {
  overflow-y: scroll;
}
.loginView .ssoLoginButton {
  margin: 0.9rem auto;
  height: 3rem;
  width: auto;
}
.loginView .ssoLoginButton .ssoLoginButtonImage svg {
  width: 100%;
  height: 100%;
}

.inviteView {
  background-color: white;
  z-index: 1048;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  height: 35%;
  max-width: 1050px;
  max-height: 670px;
  min-height: 465px;
}
.inviteView .productImage {
  max-width: 200px;
  height: auto;
  margin: 7px;
  border: 1px solid;
}
.inviteView .loading-modal-context {
  width: 100%;
  height: 100%;
}
.inviteView .permissionInviteContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #ced4da;
  width: 100%;
  height: 100%;
}
.inviteView .permissionInviteContainer .permissionDescription {
  flex: 1;
  padding: 0.9rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.inviteView .permissionInviteContainer .loginInfo {
  flex: 1;
}
.inviteView .permissionInviteContainer .loginInfo .loggedIn {
  padding: 0.9rem;
}
.inviteView .permissionInviteContainer .loginInfo .loggedIn button {
  flex-grow: 1;
  margin: 0.45rem;
}

.accountConfirmView {
  background-color: white;
  z-index: 1048;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 525px;
  max-height: 225px;
  height: 30%;
}
.accountConfirmView .loading-modal-context {
  width: 100%;
  height: 100%;
}
.accountConfirmView .confirmContainer {
  display: flex;
  flex-direction: column;
  padding: 0.9rem;
  height: 100%;
  width: 100%;
}
.accountConfirmView .confirmContainer .confirmText {
  flex: 1;
}

.confirmPasswordResetView {
  background-color: white;
  z-index: 1048;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 525px;
  max-height: 225px;
  height: 30%;
}
.confirmPasswordResetView .loading-modal-context {
  width: 100%;
  height: 100%;
}
.confirmPasswordResetView .resetContainer {
  display: flex;
  flex-direction: column;
  padding: 0.9rem;
  height: 100%;
  width: 100%;
}
.confirmPasswordResetView .resetContainer .confirmText {
  flex: 1;
}

.resetView {
  margin: 0.9rem;
}

.htmlEditor {
  width: 100%;
  min-height: 100pt;
  padding: 0.9rem;
}

.w-md-editor-toolbar ul:last-of-type {
  margin-top: 0.9rem;
}
.w-md-editor-toolbar button {
  height: 25px;
  width: 25px;
}
.w-md-editor-toolbar svg {
  height: 15px;
  width: 15px;
}

.aboutInfoForm .formButtons {
  width: 100%;
}
.aboutInfoForm .formButtons button {
  margin: 0.45rem;
  width: 100%;
}
.aboutInfoForm .htmlInputs label {
  margin-right: 0.9rem;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.aboutInfoForm .htmlInputs button {
  margin-left: 0.9rem;
}

.headerAboutInfo {
  margin: 0.9rem;
}

.titleInput {
  margin: 0;
}

.htmlInput {
  margin: 0;
}

.internationalizationsDrawer .drawer-content {
  padding: 0.3rem !important;
}

.context-menu {
  display: none;
  max-width: 30rem;
  background-color: #343a40;
  color: #fff;
  z-index: 1042;
  border-radius: 0.2rem;
}
.context-menu .icon svg {
  color: #fff;
}
.context-menu .icon svg rect[stroke], .context-menu .icon svg circle[stroke], .context-menu .icon svg polyline[stroke], .context-menu .icon svg polygon[stroke], .context-menu .icon svg line[stroke], .context-menu .icon svg ellipse[stroke], .context-menu .icon svg path[stroke], .context-menu .icon svg g[stroke] {
  stroke: #fff;
}
.context-menu .icon svg rect, .context-menu .icon svg circle, .context-menu .icon svg polyline, .context-menu .icon svg polygon, .context-menu .icon svg line, .context-menu .icon svg ellipse, .context-menu .icon svg path, .context-menu .icon svg g {
  fill: #fff;
}
.context-menu .icon svg rect[stroke=none], .context-menu .icon svg circle[stroke=none], .context-menu .icon svg polyline[stroke=none], .context-menu .icon svg polygon[stroke=none], .context-menu .icon svg line[stroke=none], .context-menu .icon svg ellipse[stroke=none], .context-menu .icon svg path[stroke=none], .context-menu .icon svg g[stroke=none] {
  stroke: none;
}
.context-menu .icon svg rect[fill=none], .context-menu .icon svg circle[fill=none], .context-menu .icon svg polyline[fill=none], .context-menu .icon svg polygon[fill=none], .context-menu .icon svg line[fill=none], .context-menu .icon svg ellipse[fill=none], .context-menu .icon svg path[fill=none], .context-menu .icon svg g[fill=none] {
  fill: none;
}
.context-menu .icon svg g[fill=none] rect, .context-menu .icon svg g[fill=none] circle, .context-menu .icon svg g[fill=none] polyline, .context-menu .icon svg g[fill=none] polygon, .context-menu .icon svg g[fill=none] line, .context-menu .icon svg g[fill=none] ellipse, .context-menu .icon svg g[fill=none] path {
  fill: none;
}
.context-menu .context-annotationType-menu-item {
  border-top: 1px solid #1d2124;
  transition: background-color 200ms linear;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}
.context-menu .context-annotationType-menu-item:hover, .context-menu .context-annotationType-menu-item:focus {
  background-color: var(--accentColour);
}
.context-menu .context-annotationType-menu-item:last-child {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.context-menu .context-annotationType-menu-item .colour-token {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.context-menu .context-annotationType-menu-item .type-name {
  margin-right: 5px;
}
.context-menu .context-menu-item {
  border-top: 1px solid #1d2124;
  transition: background-color 200ms linear;
}
.context-menu .context-menu-item:hover, .context-menu .context-menu-item:focus {
  background-color: var(--accentColour);
}
.context-menu .context-menu-item:last-child {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.blackText {
  color: black;
}

.purchaseSummary {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
  position: relative;
}
.purchaseSummary .orderDetails {
  width: 60%;
  display: flex;
  flex-flow: row wrap;
  padding: 15px;
  height: 100%;
  overflow: scroll;
}
.purchaseSummary .orderDetails .cover {
  margin: 15px;
  min-width: 7rem;
  min-height: 9.5rem;
}
.purchaseSummary .orderDetails .prodName {
  margin-top: 25px;
  word-wrap: break-word;
}
.purchaseSummary .orderDetails .prodSub {
  word-wrap: break-word;
}
.purchaseSummary .orderDetails .headers {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.purchaseSummary .orderDetails .floatingWidget {
  width: 100%;
}
.purchaseSummary .payBox {
  width: 40%;
  height: 100%;
  margin-left: 15px;
  margin-top: 40px;
}
.purchaseSummary .closeBtn {
  right: 5px;
  position: absolute;
}

@media (max-width: 1199.98px) {
  .purchaseSummary {
    display: flex;
    flex-flow: column-reverse nowrap;
    width: 100%;
    height: initial;
  }
  .purchaseSummary .orderDetails {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 15px;
    flex: 1;
    overflow: visible;
    height: initial;
  }
  .purchaseSummary .orderDetails .cover {
    margin: 15px;
    min-width: 7rem;
    min-height: 9.5rem;
  }
  .purchaseSummary .orderDetails .prodName {
    margin-top: 25px;
    word-wrap: break-word;
  }
  .purchaseSummary .orderDetails .prodSub {
    word-wrap: break-word;
  }
  .purchaseSummary .orderDetails .headers {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  .purchaseSummary .orderDetails .floatingWidget {
    width: 100%;
  }
  .purchaseSummary .payBox {
    width: 100%;
    height: 100%;
    margin: 0px;
  }
  .purchaseSummary .closeBtn {
    transform: translateX(10px);
    top: 0;
  }
}

.payBox * {
  box-sizing: border-box;
}
.payBox body,
.payBox html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
}
.payBox label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
.payBox button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}
.payBox button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.payBox input,
.payBox .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.payBox input::placeholder {
  color: #aab7c4;
}
.payBox input:focus,
.payBox .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.payBox .StripeElement.IdealBankElement,
.payBox .StripeElement.FpxBankElement,
.payBox .StripeElement.PaymentRequestButton {
  padding: 0;
}
.payBox .StripeElement.PaymentRequestButton {
  height: 40px;
}
.payBox .cvcNumber {
  max-width: 100px;
}
.payBox .cancel {
  background-color: red;
  margin-left: 15px;
}
.payBox .cancel:hover {
  color: #fff;
  cursor: pointer;
  background-color: #e60000;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.payBox .expiry {
  max-width: 100px;
}

.transactionSuccess svg {
  width: 200px;
}
.transactionSuccess label {
  margin-top: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px green;
  }
  40% {
    box-shadow: 0 0 20px green;
  }
  60% {
    box-shadow: 0 0 20px green;
  }
  100% {
    box-shadow: 0 0 -10px green;
  }
}
.transactionSuccess .glow {
  animation: glowing 3000ms infinite;
  border-radius: 100px;
}
.transactionSuccess .glowBox {
  width: fit-content;
  margin: auto;
  display: block;
}

.transactionError svg {
  width: 200px;
  width: fit-content;
  margin: auto;
  display: block;
  animation: glowingError 3000ms infinite;
  border-radius: 10px;
}
.transactionError label {
  margin-top: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
@keyframes glowingError {
  0% {
    box-shadow: 0 0 -10px yellow;
  }
  40% {
    box-shadow: 0 0 20px yellow;
  }
  60% {
    box-shadow: 0 0 20px yellow;
  }
  100% {
    box-shadow: 0 0 -10px yellow;
  }
}

.community-view .loading-modal-context {
  padding: 0.3rem;
}
.community-view .loading-modal-context .form-container {
  padding: 0.3rem;
}
.community-view .loading-modal-context .MuiGrid-item {
  margin-top: 0.45rem;
}
.community-view .loading-modal-context .MuiGrid-item input {
  color: initial;
}
.community-view .loading-modal-context .formFrame iframe {
  width: 100%;
}
.community-view .loading-modal-context .submissionInfo {
  display: flex;
  flex-direction: column;
}
.community-view .communityFormContainer {
  background-color: white;
  margin-top: 0.45rem;
}
.community-view h6, .community-view .h6 {
  font-weight: 800;
  font-style: italic;
}
.community-view .card-subtitle {
  font-style: italic;
  word-break: break-all;
}
.community-view .attachmentContainer {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  flex-wrap: wrap;
}
.community-view .attachmentContainer .attachment {
  display: flex;
  flex-direction: row;
  min-height: 15pt;
  margin: 0.3rem;
}
.community-view .attachmentContainer .attachment .attachmentIcon {
  height: auto;
  width: 15pt;
  margin-right: 0.3rem;
}

.MuiGrid-root {
  max-width: 100%;
}

.MuiFormControl-fullWidth,
.MuiGrid-container {
  max-width: 100%;
}

.formStateFilter {
  display: flex;
  flex-direction: row;
}
.formStateFilter button {
  margin-left: 0.9rem;
  margin-bottom: 0.9rem;
}

.userInfoBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.45rem;
}
.userInfoBlock span {
  margin-left: 0.9rem;
}

.submissionInfoHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.45rem;
}
.submissionInfoHeader span {
  margin-right: 0.9rem;
}

.attachmentsControl {
  margin-top: 0.9rem;
}

