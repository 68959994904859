/* Colors */
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$accent: #1c67d8 !default;
$input-bg: white !default;
/* z-index */
$zindex-baseline: 1036 !default;
$zindex-baseline2: 1037 !default;
$zindex-dropdown: 1038 !default;
$zindex-sticky: 1039 !default;
$zindex-fixed: 1040 !default;
$zindex-popover: 1042 !default;
$zindex-loading: 1043 !default;
$zindex-overEverything: 1044 !default;
$zindex-splashpage: 1045 !default;
$zindex-firstNav: 1046 !default;
$zindex-modal-backdrop: 1047 !default;
$zindex-modal: 1048 !default; // 1230
$zindex-drawerBack: 1048 !default;
$zindex-drawer: 1049 !default;
$zindex-options: 1049 !default;
$zindex-dialog: 1050 !default;
$zindex-notification: 1050 !default;
$zindex-tooltip: 1051 !default;
$zindex-initialLoading: 10000 !default;
/* This loading is for App setup. Trumps all other z-indexes */
/* Body */
$body-bg: $gray-200 !default;

/* Colors */
$bg-void: $gray-600;

/* Context menu */
$context-menu-bg-color: $gray-800;
$context-menu-color: $white;

/* -------- Bootstrap overloads */
$primary: $accent;
$success: #3eaf64;
$danger: #e23d4b;
$secondary: #e2e2e2;
$warning: rgb(235, 173, 26);

$spacer: 0.9rem;

$border-radius: 0.2rem;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.6 !default;
$h2-font-size: $font-size-base * 1.4 !default;
$h3-font-size: $font-size-base * 1.3 !default;
$h4-font-size: $font-size-base * 1.2 !default;
$h5-font-size: $font-size-base * 1.1 !default;
$h6-font-size: $font-size-base !default;

$font-size-smallest: 80%;
$font-size-smaller: 90%;
$font-size-medium: 100%;
$font-size-larger: 110%;
$font-size-largest: 120%;

$alert-bg-level: 1;
$alert-border-level: 1;
$alert-color-level: -12;
$alert-border-radius: 0 rem;

$badge-font-weight: normal;

$close-text-shadow: none;

$card-spacer-y: 0.5rem !default;
$card-spacer-x: 1rem !default;
$modal-backdrop-opacity: 0.3 !default;

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
) !default;

$border-color: $gray-400;
$nav-tabs-border-color: $border-color;
$card-border-color: $gray-400;
$navbar-light-color: rgba($black, 0.6) !default;

$custom-control-indicator-bg: $secondary;
$custom-checkbox-bg-color-checked: $gray-700;
$custom-checkbox-bg-color-indeterminate: $gray-700;
$custom-switch-bg-color-checked: $primary;