.community-view {
  .loading-modal-context {
    padding: calc($spacer/3);

    .form-container {
      padding: calc($spacer/3);
    }

    .MuiGrid-item {
      margin-top: calc($spacer/2);

      input {
        color: initial;
      }
    }

    .formFrame {
      iframe {
        width: 100%;
      }
    }

    .submissionInfo {
      display: flex;
      flex-direction: column;
    }
  }

  .communityFormContainer {
    background-color: white;
    margin-top: calc($spacer/2);
  }

  h6 {
    font-weight: 800;
    font-style: italic;
  }

  .card-subtitle {
    font-style: italic;
    word-break: break-all;
  }

  .attachmentContainer {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;

    .attachment {
      display: flex;
      flex-direction: row;
      min-height: 15pt;
      margin: calc($spacer/3);

      .attachmentIcon {
        height: auto;
        width: 15pt;
        margin-right: calc($spacer/3);
      }
    }
  }
}

.MuiGrid-root {
  max-width: 100%;
}

.MuiFormControl-fullWidth,
.MuiGrid-container {
  max-width: 100%;
}

.formStateFilter {
  display: flex;
  flex-direction: row;

  button {
    margin-left: $spacer;
    margin-bottom: $spacer;
  }
}

.userInfoBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: calc($spacer/2);

  span {
    margin-left: $spacer;
  }
}

.submissionInfoHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: calc($spacer/2);

  span {
    margin-right: $spacer;
  }
}

.attachmentsControl {
  margin-top: $spacer;
}