.nav-item .action-icon {
  @extend .nav-link;
}

.vertical-navbar {
  @extend .d-flex;
  @extend .flex-column;
  font-size: 1.4rem;
  &.first-navbar {
    background-color: var(--firstNavbarBg);
    color: var(--firstNavbarColour);
    svg {
      @include color-svg(var(--firstNavbarColour));
    }
    z-index: $zindex-firstNav;
  }

  &.second-navbar {
    background-color: var(--secondNavbarBg);
    color: var(--secondNavbarColour);

    display: table !important;
    @media (max-height: 600px) {
      display: inherit;
      position: relative;
    }
    height: 100%;
    .icon svg {
      @include color-svg($navbar-light-color);
    }
    @extend .border-secondary;
    @extend .border-right;
    .innerNav {
      @media (max-height: 600px) {
        height: calc(100% - 50px);
        width: 100%;
        overflow-y: scroll;
        display: block;
        margin-top: 50px;
      }
      display: table-cell;
      vertical-align: middle;
    }
  }

  .nav-item {
    margin: 0 auto;
  }

  .nav-item .action-icon {
    padding: $spacer * 1.2;
    &.active {
      position: relative;
      &:after {
        width: 0;
        height: 0;
        content: " ";
        border: 3px solid var(--navItemActiveColour);
        background-color: var(--navItemActiveColour);
        position: absolute;
        border-radius: 300px;
        margin-left: 0.4rem;
        top: 50%;
      }
    }
  }
}

.nav-item .action-icon.active {
  @include color-svg(var(--navItemActiveColour));
}

.nav-compact-tabs {
  font-size: $small-font-size;

  .nav-link {
    border-top-width: 0;
    border-radius: 0;
    max-width: 9rem;
    @extend .text-ellipsis;
    border-width: 0;
    border-right-width: $nav-tabs-border-width;
    border-right-color: $nav-tabs-border-color;
    color: var(--accentTextColour) !important;
    &.active {
      color: var(--selectedTabTextColor) !important;
      background-color: transparent;
      border-bottom-color: var(--selectedTabTextColor);
      border-bottom-width: $nav-tabs-border-width * 2;
    }
  }
}

.nav-pills {
  .nav-link:hover {
    color: var(--infoHeaderColour);
    .nav-link.active svg path {
      fill: var(--infoHeaderColour);
    }
  }
  .nav-link.active:hover {
    color: var(--infoHeaderInvert);
    .nav-link.active svg path {
      fill: var(--infoHeaderInvert);
    }
  }
  .nav-link.active svg path {
    fill: var(--infoHeaderInvert);
  }
  .nav-link.active span {
    color: var(--infoHeaderInvert);
  }
  .nav-link.active,
  .nav-link.active {
    background-color: var(--infoHeaderColour);
  }
  .header-span {
    margin-left: 5px;
  }

  .nav-link:not(.disabled):not(.active) {
    cursor: pointer;
    color: var(--infoHeaderColour);
    svg path {
      fill: var(--infoHeaderColour);
    }
  }
}

.tab-content > .tab-pane.d-flex {
  display: none !important;
}

.tab-content > .tab-pane.d-flex.active {
  display: flex !important;
}
